import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Upload_Product_Image } from "../../../store/productsSlice";
import { SwToast } from "../../../lib/SwAlert";
import BGup from "../../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image
import config from "../../../lib/config";

function ImageUpload(props) {
  const { NewProduct } = props;
  const { DefaultImage, SuccessHandling } = useSelector(
    (state) => state.product
  );

  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(
    DefaultImage.length >= 1
      ? config.get("URLs.product_ImgThumbnails") + DefaultImage
      : false
  );
  const AcceptFileType = ".gif,.jpg,.jpeg,.png";
  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      const ImgType = event.target.files[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        const formData = new FormData();
        setSelectedImg(URL.createObjectURL(event.target.files[0]));
        formData.append(
          "File_data",
          event.target.files[0],
          event.target.files[0].name
        );
        dispatch(Upload_Product_Image(formData));
        // console.log(event.target.files[0].type.split('/',2)[1])
        SwToast("success", `❤️ Image .${ImgType} Uploading Successfully .`);
      } else {
        // SwToast('success',`❤️ ${HandlingSuccessMsg}`)
        SwToast("error", `😡 file type not include in accept `);
      }
    }
  };
  useEffect(() => {
    setSelectedImg(BGup);
  }, [SuccessHandling]);
  return (
    <div
      className={`upload-btn-wrapper Transition ${
        NewProduct === false ? "hide" : ""
      }`}
      style={{ backgroundImage: `url('${selectedImg ? selectedImg : BGup}')` }}
    >
      <input
        type="file"
        onChange={(event) => onFileChange(event)}
        title=""
        accept=".gif,.jpg,.jpeg,.png"
      />
    </div>
  );
}

export default ImageUpload;
