import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
 
const lodash = require('lodash');
export const AnalysisWallet_ = createAsyncThunk(
  "Wallet/AnalysisWallet_",
  async (post, thunkAPI) => {
    const { getState } = thunkAPI;
    const invoicesList=getState().Invoices.invoicesList;
      return  invoicesList;
  }
);

export const SyncWallet_ = createAsyncThunk(
  "Wallet/SyncWallet_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const invoices = endpoint
        .get("wallet/default")
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initState = {
  isLoading: false,
  wallets: [],
  DefaultWallet:0,
  OrderList:[],
  error: null,
};

const WalletSlice = createSlice({
  name: "Wallet",
  initialState: initState,
  reducers: {
    SwitchDefaultWallet: (state, action) => { 
      state.DefaultWallet=action.payload;
    },
/*    setSelectedOrder: (state, action) => { 
      state.OrderList=action.payload;
    },
 */
    
  },
  extraReducers: {
    [AnalysisWallet_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [AnalysisWallet_.fulfilled]: (state, action) => {
      state.isLoading = false;
      if(state.wallets.length >= 1) {
       state.wallets.map((Item, index) => {
        const Res = action.payload.length >=1 && action.payload.filter((result) => { return result.Wallet_ID === Item.Wallet_ID; });
        if (Res) {  
          Item.Wallet_value=lodash.sum(Res.map((It_) => {  return parseInt(It_.Total_Invoice)         }));       
        };
        return null;
      })
      }
    },
    [AnalysisWallet_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncWallet_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncWallet_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.wallets = action.payload;
    
    },
    [SyncWallet_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {  SwitchDefaultWallet /* ,setSelectedOrder */} = WalletSlice.actions;
export default WalletSlice.reducer;
