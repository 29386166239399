import React, { useEffect, useState } from "react";
import {
  SelectToEditClint,
  DeleteClint_,
  FindByClintName
} from "../../store/clintSlice";
import Table from "../table/Table";
import "../../assets/css/Audience/Audience.css";
import Swal from "sweetalert2";

const ClintListTable = (props) => {
  const {isLoading, ClintList,SyncClint_Data,dispatch} = props;
  const [NamePhone, setNamePhone] = useState("");
  const customerTableHead = [  "#",  "الاسم",  "المسوقـ / ـة",  "هاتف",  "##",  "العنوان",  "اوردرات",];
  const renderHead = (item, index) => <th key={index}>{item}</th>;
 
  useEffect(() => {
    if (NamePhone.length>=3){
      const st_ = setTimeout(() => {
          dispatch(FindByClintName(NamePhone));
      }, 350);
      return () => {
        clearTimeout(st_);
    }
    }else{
     if( NamePhone.length<=1)dispatch(SyncClint_Data());
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NamePhone, dispatch]);

  const edit = (i) => {    dispatch(SelectToEditClint(i));  };
  const RemoveClint = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف العميل !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteClint_(Item));
        Swal.fire("Deleted!", "تم حذف العميل نهائياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف العميل", "", "info");
      }
    });
  };

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{item.Client_Name}</td>
      <td>{item.M_Name}</td>
      <td>{item.ClientPhone}</td>
      <td>{item.OtherPhone}</td>
      <td>{item.Address.City}</td>
      <td align="center" className="editTool">
        <div onClick={() => edit(item)}>
          <i className="fa  fa-pencil-alt EditPencil"></i>{" "}
        </div>
        <div onClick={() => RemoveClint(item)}>
          <i className="fa  fa-trash"></i>{" "}
        </div>
      </td>
    </tr>
  );
  return (
 <div className="card LastClint">
        <div className="headClint">
          <h2 className="page-header"> قائـمة العمـلاء </h2>
          <input
            type="text"
            onChange={(e) => setNamePhone(e.target.value)}
            placeholder="بـحـث عن .. "
            className="SearcheClint"
            value={NamePhone}
          />
        </div>

        <div className="card__body">
          {isLoading ? (
            <div className="IsLoading"></div>
          ) : (
            <>
              <Table
                limit="20"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={ClintList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </>
          )}
        </div>
      </div>
  );
};

export default ClintListTable;
