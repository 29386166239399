import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncByCity,
  filterClintByCity,
  SyncClint_Data,
} from "../../store/clintSlice";
import NewClintForm from "./NewClintForm";
import "../../assets/css/Audience/Audience.css";
import ClintListTable from "./ClintListTable";
import ClintCityItem from "./ClintCityItem";
const AudienceIndex = () => {
  const { isLoading, ClintCity, ClintList } = useSelector(
    (state) => state.clint
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncClint_Data());
    dispatch(SyncByCity());
  }, [dispatch]);

   useEffect(() => {
    setTimeout(() => {
       document.title =(`   العملاء - (Audience) `);
     }, 100);
    });

  return (
    <div className="row">
      <div className="left-saidbar">
        <NewClintForm />
        <ClintCityItem
          dispatch={dispatch}
          ClintCity={ClintCity}
          isLoading={isLoading}
          filterClintByCity={filterClintByCity}
        />
      </div>
      <ClintListTable
        dispatch={dispatch}
        ClintList={ClintList}
        isLoading={isLoading}
        SyncClint_Data={SyncClint_Data}
      />
    </div>
  );
};
export default AudienceIndex;
