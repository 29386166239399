import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
//analysis/Chart_analysis
export const Chart_analysis = createAsyncThunk(
  "analysis/Chart_analysis",
  async (_, thunkAPI) => {
    const { rejectWithValue,getState } = thunkAPI;
    try {
      const Result_analysis = endpoint
        .post("analysis/chart_analysis", JSON.stringify({UsId: parseInt(getState().auth.UserId)}))  
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_analysis;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const GetTotal_analysis = createAsyncThunk(
  "analysis/GetTotal_analysis",
  async (_, thunkAPI) => {
    const { rejectWithValue,getState } = thunkAPI;
    try {
      const Result_analysis = endpoint
        .post("analysis/total_analysis", JSON.stringify({UsId: parseInt(getState().auth.UserId)}))   
        .then((response) => {
          return response.data;
        })
         
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_analysis;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initState = { Data_analysis: [],ChartDataAnalysis:[], isLoading: false,isLoadingChart:false };
const analysisSlice = createSlice({
  name: "analysis",
  initialState: initState,
  reducers: {
    analysisHash: (state, action) => {
       state.Data_analysis[action.payload[1]].hash=!state.Data_analysis[action.payload[1]].hash;
    },
  },
  extraReducers: {
    [Chart_analysis.pending]: (state, action) => {
      state.isLoadingChart = true;
      state.error = null;
    },
    [Chart_analysis.fulfilled]: (state, action) => {
      state.isLoadingChart = false;
      state.ChartDataAnalysis = action.payload;
      // console.log(state.ChartDataAnalysis);
    },
    [Chart_analysis.rejected]: (state, action) => {
      state.isLoadingChart = false;
      state.error = action.payload;
    },
    [GetTotal_analysis.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [GetTotal_analysis.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.Data_analysis = action.payload;
    },
    [GetTotal_analysis.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  }



});

export const { analysisHash, logout } = analysisSlice.actions;
export default analysisSlice.reducer;
