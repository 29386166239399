import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {SyncSuppliers,DeleteSupplier_,SelectToEditSupplier} from "../../store/supplierSlice";
import Table from "../table/Table";
import Swal from "sweetalert2";

const  SupplierTable = (props) => {
  const { isLoading,     SuppliersList   } = useSelector((state) => state.supplier);
   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncSuppliers());
  }, [dispatch]);

  const SupplierLang = {
    number: "#",
    supplier_name: "اسم المورد",
    contact_name: "اسم جهة الاتصال",
    key_code: "كود المورد",
    address: "العنوان",
    city: "المدينة",
    postal_code: "الرمز البريدي",
    country: "البلد",
    phone: "رقم الهاتف",
    actions: "###"
  };
  const supplierTableHead = [
    SupplierLang.number,
    SupplierLang.supplier_name,
    SupplierLang.key_code,
    SupplierLang.contact_name,
    SupplierLang.address,
    SupplierLang.city,
    SupplierLang.postal_code,
    SupplierLang.country,
    SupplierLang.phone,
    SupplierLang.actions
  ];
  const renderHead = (item, index) => <th key={index}>{item}</th>;
  const edit = (i) =>   dispatch(SelectToEditSupplier(i)); 
  const RemoveSupplier = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف المورد !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteSupplier_(Item));
        Swal.fire("Deleted!", "تم حذف المورد نهائياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف المورد", "", "info");
      }
    });
  };

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.supplier_id}</td>
      <td>{item.supplier_name}</td>
      <td>{item.key_Code}</td>
      <td>{item.contact_name}</td>
      <td>{item.address}</td>
      <td>{item.city}</td>
      <td>{item.postal_code}</td>
      <td>{item.country}</td>
      <td>{item.phone}</td>
      <td align="center" className="editTool">
        <div onClick={() => edit(item)}>
          <i className="fa  fa-pencil-alt EditPencil"></i>{" "}
        </div> - 
        <div onClick={() =>  RemoveSupplier(item)}>
          <i className="fa  fa-trash"></i> 
        </div>
      </td>
    </tr>
  );
  
  return (
 <div className="card LastClint">
        <div className="headClint">
          <h2 className="page-header"> قائـمة الموردين </h2>
         
        </div>

        <div className="card__body">
          {isLoading ? (
            <div className="IsLoading"></div>
          ) : (
            <>
              <Table
                limit="20"
                headData={supplierTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={SuppliersList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </>
          )}
        </div>
      </div>
  );
};

export default  SupplierTable;
