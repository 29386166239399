import { useEffect,useState } from "react";
import {  SwToast } from "./SwAlert";
import { setHttpConfigurations } from "@mongez/http";
import user from "./user";
import config from "./config";

setHttpConfigurations({
  baseUrl: config.get("endpoint.baseUrl"),
  setAuthorizationHeader: () => {
    if (user.isLoggedIn()) {
      return `Bearer ${user.accessToken()}`;
    } else {
      return `key ${config.get("endpoint.apiKey")}`;
    }
    
  },
  
});
function CheckInternet() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
          setIsOnline(navigator.onLine);
        };
    
        // Listen to the online status
        window.addEventListener('online', handleStatusChange);
    
        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);
    
        // Specify how to clean up after this effect for performance improvment
        return () => {
          window.removeEventListener('online', handleStatusChange);
          window.removeEventListener('offline', handleStatusChange);
        };
      }, [isOnline]);
   
   
      !isOnline  &&   SwToast("success", "You Are Offline ");
   
      return null;
 
}

export default CheckInternet