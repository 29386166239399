import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const SyncDebt = createAsyncThunk(
  "debt/SyncClint_Data",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("debt/default", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncClint_ = createAsyncThunk(
  "debt/SyncClint_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/sync_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const SaveDebt_ = createAsyncThunk(
  "debt/SaveDebt_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("debt/save_debt_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          debt: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteDebt_ = createAsyncThunk(
  "debt/DeleteDebt_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const debtDelete = endpoint
        .post("debt/cldebt_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          debt: JSON.stringify(_, null, 2),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return debtDelete;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncByCity = createAsyncThunk(
  "debt/SyncByCity",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/syncbycity_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const isInt = (n) => {
  return n % 1 === 0;
};
const initState = {
  isLoading: false,
  DebtList: [],
  ClintCity: [],
  CloneDebtList: [],
  SelectedClint: [],
  ClintList: [],
  showList: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
  selectedEdit: [],
};
const debtSlice = createSlice({
  name: "debt",
  initialState: initState,
  reducers: {
    selectClint: (state, action) => {
      state.SelectedClint = isInt(action.payload[0].ClientID)
        ? action.payload[0].ClientID
        : null;
      state.DebtList.forEach((e) => {
        e.checked = false;
      }); //remove selected clint
      state.DebtList[action.payload[1]].checked =
        !state.DebtList[action.payload[1]].checked; // select clint
    },

    switchDebtList: (state, action) => {
      state.showList = action.payload; //!state.showList;
    },
    ONUpdate: (state) => {
      state.isEdit = false;
      state.SelectedClint = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },

    SelectToEditClint: (state, action) => {
      state.isEdit = true;
      state.selectedEdit = action.payload;
    },
  },

  extraReducers: {
    [SaveDebt_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveDebt_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveDebt_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [DeleteDebt_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [DeleteDebt_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DeleteDebt_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncClint_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncClint_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ClintList = action.payload;
    },
    [SyncClint_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncByCity.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncByCity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ClintCity = action.payload;
    },
    [SyncByCity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncDebt.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncDebt.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.DebtList = action.payload;
      state.CloneDebtList = action.payload;
    },
    [SyncDebt.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // [CreateNewOrder_.fulfilled]: (state, action) => {
    //   console.log(action.payload);
    // }
  },
});

export const { selectClint, switchDebtList, ONUpdate, SelectToEditClint } =
  debtSlice.actions;
export default debtSlice.reducer;
