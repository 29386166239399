import React  from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Increase, Decrement,SetQuantity } from '../../../store/QuantitySlice'
  function QuantityButtons() {
    const { QuantityVal } = useSelector((state) => state.Quantity);
    const dispatch = useDispatch();
    const handelQuantity =(e) => {
      if(e>=1){
        dispatch(SetQuantity(e));
      }else{
        dispatch(SetQuantity(1));

      }
    }

    return (
    <>
        <button type="button"  onClick={()=>dispatch(Decrement(1))} className="btn-success" >  <i className="fa fa-arrow-down "></i>  </button>
        <input name='Quantity' type="text"  value={QuantityVal} onChange={(e)=> handelQuantity(e.target.value) }  />
        <button type="button"   onClick={()=>dispatch(Increase(1))} className="btn-success" >  <i className="fa fa-arrow-up "></i>  </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(2))}   className="btn-success BtNum" > 2 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(3))}   className="btn-success BtNum" > 3 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(4))}   className="btn-success BtNum" > 4 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(5))}   className="btn-success BtNum" > 5 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(6))}   className="btn-success BtNum" > 6 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(8))}   className="btn-success BtNum" > 8 </button>
        <button type="button"  onClick={()=>dispatch(SetQuantity(10))}  className="btn-success BtNum" > 10 </button>    
    </>
  )
}
export default   QuantityButtons;