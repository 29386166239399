import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {SyncBySupplier,ONUpdate, Sync_ItemByClint ,SubmitINorderByClintCheck,checkedSupplier,
  PushBySupplierToGroup_,PushBySupplierToCheckFound_,PushToAllSupplier_} from "../../../store/NotInOrdersSlice";
import config from "../../../lib/config";
import { SwToast } from "../../../lib/SwAlert";

const LeftInOrderList = () => {
  const { ItemByClint, ids, isLoading ,ItemProduct,BySupplier,    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg} = useSelector((state) => state.NotInOrders);
  const [supplierSelect, setSupplierSelect] = useState(null);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));

  const dispatch = useDispatch();
  const handleCheck = (e) =>     dispatch(SubmitINorderByClintCheck(e))
  const Pip= (e) =>  {
    audio.pause();
    audio.play().onended = function () {
      this.currentSrc = null;
      this.src = "";
      this.srcObject = null;
      this.remove();
    };
  }
    useEffect(() => {
      if (ItemProduct.length >= 1) {
        dispatch(SyncBySupplier());
      }
    }, [ItemProduct, dispatch]);
  
    useEffect(() => {
    if(ids.length)  dispatch(Sync_ItemByClint(ids));
    }, [dispatch, ids]);

  const handleSelect = (supplierId) => {
    if (supplierId === supplierSelect) {
      setSupplierSelect(null);
      dispatch(checkedSupplier(null));
    }else {
      setSupplierSelect(supplierId);
    dispatch(checkedSupplier(supplierId));
    };
  }

const handelSendToGroup = (Item) => {
   dispatch(PushBySupplierToGroup_(Item));
   Pip();
}

const handelSendToSupplier = (Item) => {
  if (ItemProduct.length >= 1) {
    dispatch(PushToAllSupplier_());
  }else{
    SwToast("error", `😡 Not available products`);
  }
   Pip();
}


const handelSendToCheck_is_Found = (Item) => {
  dispatch(PushBySupplierToCheckFound_(Item));
  Pip();
}



useEffect(() => {
  if (SuccessHandling === true) {
    SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
    dispatch(ONUpdate());
  }
  if (ErrorHandling === true) {
    SwToast("error", `😡 ${ErrorHandlingMsg}`);
    dispatch(ONUpdate()); //  setShippingPolicy_No("");
  }
}, [
  ErrorHandling,
  SuccessHandling,
  ErrorHandlingMsg,
  HandlingSuccessMsg,
  dispatch,
]);

  const List = ItemByClint.length >= 1 ? (
    ItemByClint.map((Item,ItemIndex) => (<div className="header-item" key={Item.id}>
      <div className="item" style={{ backgroundImage: `url('${config.get("URLs.product_ImgThumbnails") + Item.Product_Img}')`, }}   ></div>
      <div className="clintOrder">
        <h3>{Item.Product_Name}</h3>
        {Item.Clint.map((cl,ClintIndex) => (
          <div className="clint" key={cl.id + 5221}>
            <span className="QV"> {cl.Quantity} </span>
            <div onClick={(e) => handleCheck([ItemIndex,ClintIndex,cl.Invoice_ID,cl.Product_ID])} className={`Check-inOrder ${cl.checked ? "Checked-true" : ""} `}></div>
            <i className="fa fa-folder "></i> <strong> {cl.Client_Name} </strong>
          </div>      
        ))}
      </div>
    </div>  
    ))
  ) : (
    <div className="Notfound"></div>            
  );  


  const supplier =
  BySupplier.length >= 1 ? (
    BySupplier.map((Item, index) => (
      <div key={index} className="Supplier">
     <div className="op-btn">
      <div className="pushAction Transition" onClick={() => handelSendToGroup(Item)}>
        <i className="fab fa-telegram"></i>
      </div>
      <div className="pushAction sh Transition" onClick={() => handelSendToCheck_is_Found(Item)}>
        <i className="fas fa-truck-loading"></i>
      </div>
      </div>

      <div className="details">
        <strong className="title">المورد / {Item.supplier_name} </strong>
        <div className="info"> <span>مطلوب <strong>{Item.count_item}</strong>  صنف اجمالى <strong>{Item.T_}</strong> وحدة  </span>
        <p><span> ما قيمته / <strong>{Item.amount }</strong> ج.م</span></p>
        </div>
      </div>
      <div onClick={() => handleSelect(  Item.supplier_id)} className={supplierSelect===Item.supplier_id ? "checkAction Transition Checked-true" : "checkAction Transition"}>
        <i className="fa fa-check"></i>
      </div>
    </div>
    ))
  ) : (<div className="Notfound" style={{ height: "250px", backgroundSize: "75%" }}></div>);

  return (
    <>
      <div className="wrapper-listOrder-item">
        <div className="Opt-bar">
 <h2> قائمة الموردين </h2>

 <div className="pushAction Transition" onClick={() => handelSendToSupplier()}>
        <i className="fab fa-telegram"></i>
      </div>
        </div>
       
        <hr />
        <div className="SuppliersList" id="transparent-scroll">
          {supplier}
        </div>
        <h2> قائمة طلبات العملاء </h2>
        <hr />
        <div className="containers" id="transparent-scroll">
          {isLoading ? <div className="IsLoading"></div> : <>{List}</>}
        </div>
      </div>
    </>
  );
};

export default LeftInOrderList;
