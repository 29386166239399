export const words = {
  headerTitle: "YBe3 ",
  headerTitlew: "YBe3 ",
  footerTitle: "© 2025 All rights reserved. Developer  ADham Allam",
  content: "main Container",
  lang: {
    ar: {
      LeftSidebar: {
        chartArea: `الاحصائيات`,
        cashier: `كاشير`,
        NotInOrders: `نواقص`,
        invoices: `فواتير`,
        debt: `الديون`,
        Category: `التصنيفات`,
        Clients: `العملاء`,
        TelegramAud_: `جمهور تلجرام`,
        tebot: `بوتات تلجرام`,
        Robot: `المجيب الالي`,
        users: `المستخدمين`,
        Pages: `الصفحات`,
        Revel: `الصلاحيات`,
      },
      cashier: {
        HToolBar: {},
      },
      NotInOrders: {
        title_page: "Not In Orders",
        section_left: "نواقص الاوردرات",

        HToolBar: {
          alertMessage: {
            title: "حـدد منتج",
            text: "يجب تحديد منتجات اولاً  !",
          },
          SwalMsg: {
            title: "حذف منتجات من الاوردرات?",
            text: "هل انت متاكد من طلب الحذف للاصناف المحددة !",
            cancelButtonText: "الغاء",
            confirmButtonText: "نعم, احذفها !",
          },
          SwalMsghide: {
            title: "حذف واخفاء من العرض?",
            text: "هل انت متاكد من طلب الحذف والاخفاء من العرض !",
            cancelButtonText: "الغاء",
            confirmButtonText: "نعم, احذفها !",
          },
          Swal_fire: {
            title: "تم الحــذف !",
            text: "تم حذف المنتجات المحددة  من الاوردرات.",
          },
          button_1: "كاشير",
          button_2: "تحديد الكل",
          button_3: "منتج محدد",
          button_4: "الاصناف",
          button_5: "طباعة كتالوج",
          button_6: "اخفاء مؤقت",
          button_7: "تاكيد الاستلام",
          button_8: "حذف من الكل",
          button_9: "حذف وايقاف العرض",
          button_10: "مزامنة النواقص",
          button_11: "قائمة قنوات المخازن",
          button_12: "ارسال للقناة",
        },
      },
      home: {
        top_clint_title: `الاعلى سحب هذا العام`,
        last_order_title: `اخر 7 اوردرات`,
      },
      tableUi: {
        call_1: `العميل`,
        call_2: `الوحدات`,
        call_3: `الاجمالى`,
        call_4: `تحرير`,
      },

      Questions: {
        title: "اسئلة واجابات",
        QuestPlaceholder: `اكتب سؤالك هنا او حدد سؤال من صندوق الوارد ...  `,
        answerPlaceholder: `اكتب جواب لسؤلك هنا ...  `,
        boxQuestion_title:"سـؤال لم يتم إجابتة",
        boxAnswer_title:"اسئلة مجاب عليها .  ",
      },
    },
    en: {},
  },
};
