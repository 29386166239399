import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const SyncClint_Data = createAsyncThunk(
  "clint/SyncClint_Data",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("clients/default", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncMarketers = createAsyncThunk(
  "clint/SyncMarketers",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/marketers_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const SaveClient_ = createAsyncThunk(
  "clint/SaveClient_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/saveclient_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          clint: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteClint_ = createAsyncThunk(
  "clint/DeleteClint_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/remove_clint_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          clint: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncByCity = createAsyncThunk(
  "clint/SyncByCity",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("clients/syncbycity_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const filterClintByCity = createAsyncThunk(
  "clint/filterClintByCity",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("clients/filter_by_city_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          City: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FindByClintName = createAsyncThunk(
  "clint/FindByClintName",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("clients/find_by_name_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          ClintName: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const isInt = (n) => {
  return n % 1 === 0;
};
const initState = {
  isLoading: false,
  ClintList: [],
  ClintCity: [],
  CloneClintList: [],
  SelectedClint: [],
  Marketers: [],
  showList: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
  ClintModal: false,
  selectedEdit: [],
};
const clintSlice = createSlice({
  name: "clint",
  initialState: initState,
  reducers: {
    ActiveClintModal: (state, action) => {
      state.ClintModal = action.payload;
    },
    selectClint: (state, action) => {
      state.SelectedClint = isInt(action.payload[0].ClientID)
        ? action.payload[0].ClientID
        : null;
      state.ClintList.forEach((e) => {
        e.checked = false;
      }); //remove selected clint
      state.ClintList[action.payload[1]].checked =
        !state.ClintList[action.payload[1]].checked; // select clint
    },
    findClint: (state, action) => {
      state.ClintList = state.CloneClintList;
      if (action.payload[1] === "ByName") {
        state.ClintList = state.ClintList.filter((result) =>
          result.Client_Name.includes(action.payload[0])
        );
      } else {
        state.ClintList = state.ClintList.filter((result) =>
          result.ClientPhone.includes(action.payload[0])
        );
      }
    },
    switchClintList: (state, action) => {
      state.showList = action.payload; //!state.showList;
    },
    ONUpdate: (state) => {
      state.isEdit = false;
      state.SelectedClint = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },

    SelectToEditClint: (state, action) => {
      state.isEdit = true;
      state.selectedEdit = action.payload;
    },
  },

  extraReducers: {
    [FindByClintName.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [FindByClintName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.ClintList = action.payload;
    },
    [FindByClintName.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [filterClintByCity.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [filterClintByCity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.ClintList = action.payload;
    },
    [filterClintByCity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SaveClient_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveClient_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveClient_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [DeleteClint_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [DeleteClint_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DeleteClint_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncMarketers.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncMarketers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.Marketers = action.payload;
    },
    [SyncMarketers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncByCity.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncByCity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ClintCity = action.payload;
    },
    [SyncByCity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncClint_Data.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncClint_Data.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ClintList = action.payload;
      state.CloneClintList = action.payload;
    },
    [SyncClint_Data.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // [CreateNewOrder_.fulfilled]: (state, action) => {
    //   console.log(action.payload);
    // }
  },
});

export const {
  selectClint,
  switchClintList,
  findClint,
  ONUpdate,
  SelectToEditClint,
  ActiveClintModal,
} = clintSlice.actions;
export default clintSlice.reducer;
