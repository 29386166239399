import React from "react";
import { useDispatch } from "react-redux";
import { FilterOrder } from "../../../store/productsSlice";
import "../../../assets/css/PointCashier/Filter/Filter.css";
function Filter(props) {
  const dispatch = useDispatch();
  return (
    <>
{/*       <select
        className="round"
        value={props.FilterBy}
        onChange={(e) => dispatch(FilterBySize(e.target.value))}
      >
        <option value="ALL">ALL</option>
        <option value="M">M</option>
        <option value="L">L</option>
        <option value="XL">XL</option>
        <option value="XXL">XXL</option>
        <option value="XXXL">XXXL</option>
        <option value="XXXXL">XXXXL</option>
        <option value="XXXXXL">XXXXXL</option>
        <option value="XXXXXXL">XXXXXXL</option>
      </select>
 */}
      <select name="SortBy"
        className="minimal"
        value={props.OrderBy}
        onChange={(e) => dispatch(FilterOrder(e.target.value))}
      >
        <option value="latest">Latest</option>
        <option value="lowest">Lowest</option>
        <option value="highest">Highest</option>
      </select>
    </>
  );
}
export default Filter;
