import React, { useState }  from "react";
 import "./../../assets/css/View_Catalog/ViewCatalogIndex.css";
import Item from "./Components/Item_";
import ToolBar from "./Components/ToolBar";
function ViewCatalogIndex() {
  const [Zoom,setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!Zoom);
  }
   return (
    <>
      <ToolBar  handleZoom={handleZoom} />
      <div className="wrapper-catalog">
        <div className="products-wrapper">
          <Item Zoom={Zoom} />
        </div>
      </div>
    </>
  );
}

export default ViewCatalogIndex;
