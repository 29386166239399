import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncSuppliers_by_products, SelectAll, SelectedItem} from "../../../store/supplierSlice";
import { SupplierProducts } from "../../../store/productsSlice";

import config from "../../../lib/config";
import "../../../assets/css/PointCashier/PublishCampaign/ListSuppliers.css";

function ListSuppliers() {
  const { SuppliersList_Count } = useSelector(    (state) => state.supplier  );
  const [IsSelected, setIsSelected] = useState(false);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));
  const dispatch = useDispatch();

  const Pip = (e) => {
    audio.pause();
    audio.play().onended = function () {
      this.currentSrc = null;
      this.src = "";
      this.srcObject = null;
      this.remove();
    };
  };
  useEffect(() => {
    // Placeholder action to sync suppliers list
    dispatch(SyncSuppliers_by_products());
  }, [dispatch]);
  const handleSelect_by_supplier = (supplierId) => {
    dispatch(SelectedItem(supplierId));
    Pip();
  };
  const handelSelectAll = () => {
    setIsSelected(!IsSelected);
    if (IsSelected) {
      dispatch(SelectAll(!IsSelected));
    } else {
      dispatch(SelectAll(!IsSelected));
    }
    Pip();
  };
  const handleSyncProdcutBySupplier = (supplierId) => {
    // console.log(supplierId);
    dispatch(SupplierProducts(supplierId));
    Pip();
  }
  const supplierFound =
    SuppliersList_Count.length >= 1 ? (
      SuppliersList_Count.map((Item, index) => (
        <div key={index} className="Supplier">
          <div className="details">
            <div>
              <strong className="title">المورد / {Item.supplier_name} </strong>
              <div className="info">
                 
                <span>
                  منتجات نشطة <strong>{Item.product_Active}</strong> منتج / من 
                </span> 
                <strong>{Item.total_products}</strong> <span>منتج</span> 
              </div>
            </div>
            <div className="SyncProducts_btn Transition" onClick={() => handleSyncProdcutBySupplier(Item.SupplierID)}>
              <i className="fa fa-sync"></i> 
            </div>
          </div>
          <div
            onClick={() => handleSelect_by_supplier(Item.SupplierID)}
            className={
              Item.checked
                ? "checkAction Transition Checked-true"
                : "checkAction Transition"
            }
          >
            <i className="fa fa-check"></i>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "250px", backgroundSize: "75%" }}
      ></div>
    );

  return (
    <>
      <div className="OBjCmp">
        <div  
              className={`Check-select Transition Check-select ${
                IsSelected ? "Selected" : ""
              }`}
        onClick={() => handelSelectAll()}> </div>
        <div className="title">قائمة الموردين داخل الحملة </div>
        <i className="fas fa-folder-minus"></i>
      </div>
      <div className="SuppliersList" id="transparent-scroll">
        {supplierFound}
      </div>
    </>
  );
}

export default ListSuppliers