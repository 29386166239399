import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FindItemByCode } from "../../../store/NotInOrdersSlice";

export default function FindItem() {
  const [ProductCode, setProductCode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const str_ = setTimeout(() => {
      dispatch(FindItemByCode(ProductCode));
    }, 1000);
    return () => {
      clearTimeout(str_);
    };
  }, [ProductCode, dispatch]);

  const setFind = (e) => {
    // dispatch(ActiveSearch());
    setProductCode(e);
  };

  return (
    <>
      <input
        style={{ width: "150px" }}
        type="text"
        name="search"
        id="search"
        value={ProductCode}
        onChange={(e) => setFind(e.target.value)}
        placeholder="Search..."
      />
    </>
  );
}
