import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const SYNC_Question = createAsyncThunk(
  "Question/SYNC_Question",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Question = endpoint
        .post(
          "questions/sync_quest_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Question > ${error} ----`);
        });
      return Question;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SYNC_Answer = createAsyncThunk(
  "Question/SYNC_Answer",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Question = endpoint
        .post(
          "questions/sync_answer_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Question > ${error} ----`);
        });
      return Question;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveNewCategory = createAsyncThunk(
  "category/SaveNewCategory",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
      const upload_img = endpoint
        .post("categories/save_up_cat_", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return upload_img;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeQuestions = createAsyncThunk(
  "Question/removeQuestions",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("questions/rm_question_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          QuestIDs: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeAnswer = createAsyncThunk(
  "Question/removeAnswer",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("questions/rqu_answer_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          QuestIDs: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveAnsQuestion = createAsyncThunk(
  "Question/SaveAnsQuestion",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const rm_question_ = endpoint
        .post("questions/add_quest_", {
          UserId: parseInt(getState().auth.UserInfo["UserId"]),
          _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return rm_question_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initState = {
  isLoading: false,
  QuestionList: [],
  AnswerList: [],
  SelCount: 0,
  SelAnswerCount: 0,
  isEdit: false,
  ItemIds: [],
  ItemIdsAnswer: [],
  selectedQuestion: [],
  IsUploaded: false,
  error: null,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  CloneQuestion: [],
};

const AQuestionSlice = createSlice({
  name: "Question",
  initialState: initState,
  reducers: {
    EditQuestion: (state, action) => {
      state.selectedQuestion = [];
      state.selectedQuestion = action.payload;
      state.isEdit = true;
    },
    RemoveItem: (state, action) => {
      //remove the item from the cart list
      state.QuestionList = state.QuestionList.filter(
        (el) => el.Questions_Id !== action.payload.Questions_Id
      );
      state.update = true;
    },
    SelectedItem: (state, action) => {
      //selected item in cart list
      state.ItemIds = [];
      let obj = state.QuestionList.find(
        (o) => o.Questions_Id === action.payload.Questions_Id
      );
      if (obj) {
        obj.checked = !obj.checked;
        // state.isEdit = true;
      }
      state.SelCount = state.QuestionList.filter((o) => o.checked === true);
      state.isEdit = state.SelCount.length >= 1 ? true : false;
      state.SelCount.forEach((Ques) => {
        state.ItemIds.push(Ques.Questions_Id);
      });
    },

    Select_AnswerItem: (state, action) => {
      //selected item in cart list
      state.ItemIdsAnswer = [];
      let obj = state.AnswerList.find(
        (o) => o.Questions_Id === action.payload.Questions_Id
      );
      if (obj) {
        obj.checked = !obj.checked;
        // state.isEdit = true;
      }
      state.SelAnswerCount = state.AnswerList.filter((o) => o.checked === true);
      state.isEdit = state.SelAnswerCount.length >= 1 ? true : false;
      state.SelAnswerCount.forEach((Ques) => {
        state.ItemIdsAnswer.push(Ques.Questions_Id);
      });
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.ItemIds = [];
      state.QuestionList.map((Item) => (Item.checked = !action.payload));
      !action.payload ? (state.isEdit = true) : (state.isEdit = false);
      state.SelCount = state.QuestionList.filter((o) => o.checked === true);
      state.isEdit = state.SelCount.length >= 1 ? true : false;
      state.SelCount.forEach((Ques) => {
        state.ItemIds.push(Ques.Questions_Id);
      });
    },
    SelectAllAnswer: (state, action) => {
      state.ItemIdsAnswer = [];
      state.AnswerList.map((Item) => (Item.checked = !action.payload));
      !action.payload ? (state.isEdit = true) : (state.isEdit = false);
      state.SelAnswerCount = state.AnswerList.filter((o) => o.checked === true);
      state.isEdit = state.SelAnswerCount.length >= 1 ? true : false;
      state.SelAnswerCount.forEach((Ques) => {
        state.ItemIdsAnswer.push(Ques.Questions_Id);
      });
    },
    ONUpdate: (state) => {
      state.isEdit = false;
      state.selectedQuestion = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },
  },
  extraReducers: {
    [SaveAnsQuestion.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveAnsQuestion.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveAnsQuestion.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [removeAnswer.pending]: (state, action) => {
      // state.isLoading = true;
      state.IsUploaded = false;
    },
    [removeAnswer.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.length === 1) {
        state.AnswerList = state.AnswerList.filter(
          (el) => el.Questions_Id !== action.payload[0]
        );
        state.SelAnswerCount = state.AnswerList.filter(
          (o) => o.checked === true
        );
        state.isEdit = state.SelAnswerCount.length >= 1 ? true : false;
      } else {
        let obj = state.AnswerList.filter((o) => o.checked === true);
        obj.map((Item, index) => {
          state.AnswerList = state.AnswerList.filter(
            (el) => el.Questions_Id !== Item.Questions_Id
          );
          return null;
        });
        state.SelAnswerCount = [];
      }
      state.isEdit = false;
    },
    [removeAnswer.rejected]: (state, action) => {
      state.isLoading = false;
      state.IsUploaded = false;
    },
    [removeQuestions.pending]: (state, action) => {
      // state.isLoading = true;
      state.IsUploaded = false;
    },
    [removeQuestions.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.length === 1) {
        state.QuestionList = state.QuestionList.filter(
          (el) => el.Questions_Id !== action.payload[0]
        );
        state.SelCount = state.QuestionList.filter((o) => o.checked === true);
        state.isEdit = state.SelCount.length >= 1 ? true : false;
      } else {
        let obj = state.QuestionList.filter((o) => o.checked === true);
        obj.map((Item, index) => {
          state.QuestionList = state.QuestionList.filter(
            (el) => el.Questions_Id !== Item.Questions_Id
          );
          return null;
        });
        state.SelCount = [];
      }
      state.isEdit = false;
    },
    [removeQuestions.rejected]: (state, action) => {
      state.isLoading = false;
      state.IsUploaded = false;
    },
    [SYNC_Answer.pending]: (state, action) => {},
    [SYNC_Answer.fulfilled]: (state, action) => {
      state.AnswerList = action.payload;
      state.isLoading = false;
    },
    [SYNC_Answer.rejected]: (state, action) => {
      state.isLoading = false;
      state.IsUploaded = false;
    },
    [SYNC_Question.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SYNC_Question.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.QuestionList = action.payload;
      state.isLoading = false;
    },
    [SYNC_Question.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  SelectedItem,
  RemoveItem,
  SelectAll,
  EditQuestion,
  ONUpdate,
  Select_AnswerItem,
  SelectAllAnswer,
} = AQuestionSlice.actions;
export default AQuestionSlice.reducer;
