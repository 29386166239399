import React from "react";
import { useDispatch } from "react-redux";
import { FilterOrder } from "../../../store/CatalogSlice";
import "../../../assets/css/PointCashier/Filter/Filter.css";
function Filter(props) {
  const dispatch = useDispatch();
  return (
    <>
      <select name="OrderBy"
        className=" minimal"
        value={props.OrderBy}
        onChange={(e) => dispatch(FilterOrder(e.target.value))}
      >
        <option value="latest">Latest</option>
        <option value="lowest">Lowest</option>
        <option value="highest">Highest</option>
      </select>
    </>
  );
}
export default Filter;
