import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/productsSlice";
import Cards from "./components/Cards";
import ChartUi from "../Chart_Ui/Chart_Ui";
import TableUi from "../Table_Ui/Table_Ui";
import TopClint from "./components/TopClint";
import LastProducts from "./components/LastProducts";
import { Chart_analysis,GetTotal_analysis } from "../../store/analysisSlice";
import { SyncOrders_ } from "../../store/OrderSlice";
import "../../assets/css/analytics/Analytics.css";
import { words } from "../../words";

function Analytics() {
  const { ChartDataAnalysis, isLoadingChart } = useSelector(
    (state) => state.analysis
  );
   const LNG=words.lang.ar.home

  const { products, isLoading } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Chart_analysis());
    dispatch(SyncOrders_());
    dispatch(getProducts());
    dispatch(GetTotal_analysis());

  }, [dispatch]);

 
  return (
    <>
      <div className="main-home">
        <LastProducts ItemProduct={products} isLoading={isLoading} />

        <div className="analysis">
          <div className="cards">
            <Cards />
          </div>
          <div className="charts">
            {isLoadingChart ? (
              <div className="IsLoading"></div>
            ) : (
              <>
                <div className="card full-height">
                  <ChartUi ChartDataAnalysis={ChartDataAnalysis} />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="orders">
          <TopClint />

          <div className="card full-height lastOrders">
            <h3 >  {LNG.last_order_title} </h3>
            <hr />
            <TableUi rowscount={7} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Analytics;
