import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncBotList,
  SyncTelegramChanel,
  SyncTelegramGroups,
} from "../../store/TelegramSlice";
import NewBotForm from "./NewBotForm";
import "../../assets/css/Bot/BotIndex.css";
import BotListTable from "./BotListTable";
import ChListTable from "./ChListTable";
import GroupListTable from "./GroupListTable";
 import EditChanelForm from "./EditChanelForm";
const BotIndex = () => {
  const { isLoading, BotList, ChanelList, GroupsList,isEditChanel } = useSelector(
    (state) => state.Telegram
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncBotList());
    dispatch(SyncTelegramChanel());
    dispatch(SyncTelegramGroups());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      document.title = ` Telegram- (Bot) `;
    }, 100);
  });

  const botTitle = "قائمة البوتات";
  const chTitle = "قائمة القنوات ";
  const grTitle = "قائمة المجموعات ";
  return (
    <div className="row">
      <div className="left-saidbar">
        <NewBotForm />
    {isEditChanel&&    <EditChanelForm />}
      </div>
      <div className="right-sidebar">
        <BotListTable
          Htitle={botTitle}
          search={false}
          dispatch={dispatch}
          BotList={BotList}
          isLoading={isLoading}
          SyncBotList={SyncBotList}
        />

        <div className="chanelGroups">
          <ChListTable
            Htitle={chTitle}
            dispatch={dispatch}
            BotList={ChanelList}
            isLoading={isLoading}
            SyncBotList={SyncBotList}
            search={false}
          />
          <GroupListTable
            Htitle={grTitle}
            search={false}
            dispatch={dispatch}
            BotList={GroupsList}
            isLoading={isLoading}
            SyncBotList={SyncBotList}
          />
        </div>
      </div>
    </div>
  );
};
export default BotIndex;
