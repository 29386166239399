import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const Sync_NotInOrders = createAsyncThunk(
  "catalog/Sync_NotInOrders",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const OrderDetailers_ = endpoint
        .post(`/orders/un_checked_in_orders_`, {
          UserId: parseInt(getState().auth.UserId),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {});
      return OrderDetailers_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Sync_ItemByClint = createAsyncThunk(
  "NotInOrders/Sync_ItemByClint",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post("orders/product_in_orders_", JSON.stringify(_))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SubmitINorder = createAsyncThunk(
  "NotInOrders/SubmitINorder",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post("orders/submit_in_orders_", JSON.stringify(_))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


///orders/submit_checked_






export const DeleteSelectedItem = createAsyncThunk(
  "NotInOrders/DeleteSelectedItem",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        endpoint
        .post("orders/rem_in_orders_", JSON.stringify(_))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DelSelectedItem_with_hide = createAsyncThunk(
  "NotInOrders/DelSelectedItem_with_hide",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "orders/remove_w_hide_",
          JSON.stringify({ _, UserId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SubmitINorderByClintCheck = createAsyncThunk(
  "NotInOrders/SubmitINorderByClintCheck",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post("orders/submit_by_clint_orders_", JSON.stringify(_))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      if (Result_update_order) {
      }
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//submit_only_won_in_orders_

export const PushBySupplierToGroup_ = createAsyncThunk(
  "NotInOrders/PushBySupplierToGroup_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/push_to_group_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Item: _.ids,
            group: _.GroupID,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const PushBySupplierToCheckFound_ = createAsyncThunk(
  "NotInOrders/PushBySupplierToCheckFound_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/push_check_found_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Item: _.ids.map(Number),
            group: Number(_.GroupID),
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const SubmitINorderCheck = createAsyncThunk(
  "NotInOrders/SubmitINorderCheck",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
   endpoint.post("orders/submit_checked_", JSON.stringify({_, UserId: parseInt(getState().auth.UserId) }))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        }); 
       return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const PushToAllSupplier_ = createAsyncThunk(
  "NotInOrders/PushToAllSupplier_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/push_to_all_supplier_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId)          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const initState = {
  isLoading: false,
  ItemProduct: [],
  CloneItemProduct: [],
  ItemByClint: [],
  BySupplier: [],
  TotalSelect: 0,
  supplier_select: null,
  ids: [],
  error: false,
  isEdit: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
};

const NotInOrdersSlice = createSlice({
  name: "NotInOrders",
  initialState: initState,
  reducers: {
    ONUpdate: (state) => {
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },
    SyncBySupplier: (state, action) => {

      const info = {};
      state.ItemProduct.forEach(product => {
    const supplierId = product.supplier_id;
    const tValue = parseInt(product.T_);
    const AMount_ = parseInt(product.T_)*parseInt(product.BuyPrice);
    if (info[supplierId]) {
      info[supplierId].count_item++;
      info[supplierId].T_ = tValue + parseInt(info[supplierId].T_);
      info[supplierId].amount = AMount_ + parseInt(info[supplierId].amount);
      info[supplierId].ids.push(product.Product_ID);
    } else {
      info[supplierId] = {
        supplier_id: supplierId,
        supplier_name: product.supplier_name,
        T_: product.T_,
        amount: AMount_,
        count_item: 1,
        checked: false,
        ids: [product.Product_ID],
        GroupID: product.GroupID
      };
    }
  });
      state.BySupplier = Object.values(info);
    },
    checkedSupplier: (state, action) => {
      const AC = action.payload;
      state.TotalSelect=0;
      state.ids=[];
      state.supplier_select=AC;
      state.ItemProduct.forEach(item =>  item.checked = false);
      state.ItemProduct.filter(item => item.supplier_id === AC).map(item => {
        item.checked = true;
        state.ids.push(item.Product_ID);
        state.TotalSelect+=1;
        return item;
      });
  },
    FilterVisible: (state,action) => {
      state.isLoading = true;
      state.ItemProduct = state.CloneItemProduct;
       const Res = state.ItemProduct.filter((result) => {
        return result.Active.includes(action.payload);
      });
      state.ItemProduct = Res;
      state.isLoading = false;
      
    },
    FindItemByCode: (state, action) => {
      state.isLoading = true;
      state.ItemProduct = state.CloneItemProduct;
      const Res = state.ItemProduct.filter((result) => {
        return result.Product_Code.includes(action.payload);
      });
      Res.length !== 0
        ? (state.ItemProduct = Res)
        : (state.ItemProduct = state.CloneItemProduct);
      state.isLoading = false;
    },
    RemoveItem: (state, action) => {
      //remove the item from the cart list
      state.ItemProduct = state.ItemProduct.filter(
        (el) => el.id !== action.payload.id
      );
      state.update = true;
    },
    unChecked: (state, action) => {
      //remove the item from the cart list
      let obj = state.ItemProduct.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        return null;
      });
    },

    HideSelectItem: (state) => {
      //delete multiple items from cart list
      let obj = state.ItemProduct.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        state.ItemProduct = state.ItemProduct.filter((el) => el.id !== Item.id);
        return null;
      });
      state.update = true;
      state.isEdit = false;
    },
    SelectedItem: (state, action) => {
      state.ids = [];
      let obj = state.ItemProduct.find((o) => o.id === action.payload.id);
      if (obj) {
        obj.checked = !obj.checked;
        state.isEdit = true;
      }
      let obItem = state.ItemProduct.filter((o) => o.checked === true);
      state.TotalSelect = obItem.length;
      obItem.forEach((element) => {
        state.ids.push(element.Product_ID);
      });
      obItem.length >= 1 ? (state.isEdit = true) : (state.isEdit = false);
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.isEdit = false;
      state.ids = [];
      state.ItemProduct.map((Item) => (Item.checked = !action.payload));
      let Total = state.ItemProduct.filter((o) => o.checked === true);
      state.TotalSelect = Total.length;
      let obItem = state.ItemProduct.filter((o) => o.checked === true);
      state.TotalSelect = obItem.length;
      obItem.forEach((element) => {
        state.ids.push(element.Product_ID);
      });
      obItem.length >= 1 ? (state.isEdit = true) : (state.isEdit = false);
    },
  },

  extraReducers: {
    [PushToAllSupplier_.pending]: (state, action) => {},
    [PushToAllSupplier_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
    
      }
    },
    [PushToAllSupplier_.rejected]: (state, action) => {},
    [SubmitINorderCheck.pending]: (state, action) => {},
    [SubmitINorderCheck.fulfilled]: (state, action) => {
      state.isLoading = false;
      const AC=action.payload;   
      let obj = state.ItemProduct.find((o) => o.Product_ID === AC.Product_ID);
        if(AC.INorder){
        obj.checked = false;
        obj.INorder = false;
        }else{
        obj.checked = false;
        obj.INorder = true;
        }
      
    },
    [SubmitINorderCheck.rejected]: (state, action) => {},
    [PushBySupplierToGroup_.pending]: (state, action) => {},
    [PushBySupplierToGroup_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
    
      }
    },
    [PushBySupplierToGroup_.rejected]: (state, action) => {},
    [PushBySupplierToCheckFound_.pending]: (state, action) => {},
    [PushBySupplierToCheckFound_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
    
      }
    },
    [PushBySupplierToCheckFound_.rejected]: (state, action) => {},
    [SubmitINorderByClintCheck.fulfilled]: (state, action) => {
      let obj = state.ItemByClint.find((o) => o.id === action.payload[0]);
      if (obj) {
        obj.Clint[action.payload[1]].checked =
          !obj.Clint[action.payload[1]].checked;
      }
    },
    [DelSelectedItem_with_hide.pending]: (state, action) => {},
    [DelSelectedItem_with_hide.fulfilled]: (state, action) => {
      let obj = state.ItemProduct.filter(
        (o) => o.checked === true  
      );  
      obj.map((Item, index) => {
        state.ItemProduct = state.ItemProduct.filter((el) => el.id !== Item.id);
        state.CloneItemProduct = state.CloneItemProduct.filter((el) => el.id !== Item.id);
        return null;
      });
      state.ids=[];
      state.ItemByClint = [];
      state.TotalSelect = 0;
      state.update = true;
      state.isEdit = false;
    },
    [DelSelectedItem_with_hide.rejected]: (state, action) => {},
    [DeleteSelectedItem.fulfilled]: (state, action) => {
      let obj = state.ItemProduct.filter(
        (o) => o.checked === true  
      );  
      obj.map((Item, index) => {
        state.ItemProduct = state.ItemProduct.filter((el) => el.id !== Item.id);
        state.CloneItemProduct = state.CloneItemProduct.filter((el) => el.id !== Item.id);
        return null;
      });
      state.ids=[];
      state.ItemByClint = [];
      state.TotalSelect = 0;
      state.update = true;
      state.isEdit = false;
    },
    [SubmitINorder.pending]: (state, action) => {},
    [SubmitINorder.fulfilled]: (state, action) => {
      let obj = state.ItemProduct.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        Item.INorder = true; // !Item.INorder;
        return null;
      });
      state.update = true;
      state.isEdit = false;
      state.TotalSelect = null;
      state.ItemByClint = [];
    },
    [SubmitINorder.rejected]: (state, action) => {},
    [Sync_ItemByClint.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
      state.ItemByClint = [];
    },
    [Sync_ItemByClint.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.error = null;
      state.ItemByClint = action.payload;
    },
    [Sync_ItemByClint.rejected]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [Sync_NotInOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.TotalSelect = 0;
    },
    [Sync_NotInOrders.fulfilled]: (state, action) => {
      state.ids = [];
      state.ItemByClint = [];
      state.isLoading = false;
      state.update = false;
      state.ItemProduct = action.payload;
      state.CloneItemProduct = state.ItemProduct;
    },
    [Sync_NotInOrders.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
  },
});

export const {
  unChecked,SyncBySupplier,checkedSupplier,
  SelectedItem,
  FindItemByCode,
  HideSelectItem,
  RemoveItem,
  ClintCheck,ONUpdate,
  SelectAll,FilterVisible
} = NotInOrdersSlice.actions;
export default NotInOrdersSlice.reducer;
