import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  SyncInvoicesByMonth_,
  SwitchCase,
  setEdit,
  FiltersByWallet,
  setSelectedClint,
  setSelectedOrder,
  SelectInvoice
} from "../../../store/InvoicesSlice";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "Invoice_ID", numeric: true, disablePadding: false, label: "#",  },
  { id: "name", numeric: false, disablePadding: false, label: "العميل",  },
  { id: "Pcs", numeric: true, disablePadding: false, label: "وحدة",  },
  { id: "Total_Invoice", numeric: true, disablePadding: false, label: "إجمالى",  },
  { id: "ShippingPolicy_No", numeric: true, disablePadding: false, label: "بوليصة",  },
  { id: "Inv_Create", numeric: false, disablePadding: false, label: "بتاريخ",  },
  { id: "Inv_PaymentDate", numeric: true, disablePadding: false, label: "تاريخ التحصيل",  },
  { id: "Type_", numeric: false, disablePadding: false, label: "نوع الاوردر",  },
  { id: "Payment_Status", numeric: true, disablePadding: false, label: "حالة الدفع",  },
  { id: "send_catalog", numeric: false, disablePadding: false, label: "*",  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell   key={headCell.id}   align={headCell.numeric ? "center" : "left"}   padding={headCell.disablePadding ? "none" : "normal"}   sortDirection={orderBy === headCell.id ? order : false} > 
          <TableSortLabel     active={orderBy === headCell.id}     direction={orderBy === headCell.id ? order : "asc"}     onClick={createSortHandler(headCell.id)} >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Table_Ui(props) {
  const { RowCount } = props;
  const [selected, setSelected] = useState([]);
  const [SelectedByClint, setSelectedByClint] = useState([]);
  const { invoicesList, IsEdit, SelectAll, SelectedInv_, SelectedMonth } =  useSelector((state) => state.Invoices);
  const { DefaultWallet } = useSelector((state) => state.Wallet);
  const dispatch = useDispatch();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Invoice_ID");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowCount);
  const rows = [];

  useEffect(() => {
    if (invoicesList.length <= 0) {
      dispatch(SyncInvoicesByMonth_(SelectedMonth)); //sync invoices list by selected month
    }
  }, [dispatch, invoicesList.length, SelectedMonth]);

  useEffect(() => {
    if (selected.length >= 1) {
      //add selected item to slice Invoices
      dispatch(setEdit(true));
      dispatch(setSelectedOrder(selected));
      dispatch(setSelectedClint(SelectedByClint));
    } else {
      dispatch(setSelectedOrder(selected)); //update selected invoice in slice SelectedInv_
      dispatch(setEdit(false)); //set edit is false in slice can`t update selected invoice
    }
  }, [dispatch, selected, SelectedInv_, SelectedByClint]);
  // }, [dispatch, selected, SelectedInv_])

  useEffect(() => {
    if (!IsEdit) {
      setSelected([]);
      setSelectedByClint([]);
    }
  }, [IsEdit]);

  useEffect(() => {
    handleSelectAllClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectAll]);

  useEffect(() => {
    if (!IsEdit && !SelectAll) {
      dispatch(FiltersByWallet(DefaultWallet));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DefaultWallet, IsEdit, !SelectAll, dispatch]);

  const handleSwitchCase = (e) => {
    dispatch(SwitchCase(e));
  };

 
 

if(invoicesList.length > 0) {
  invoicesList.map((INv_) => {
    return rows.push({
      Invoice_ID: INv_.Invoice_ID,
      name: INv_.Client_Name,
      PCs: INv_.Pcs,
      Total_Invoice: INv_.Total_Invoice,
      ShippingPolicy_No: INv_.ShippingPolicy_No,
      Inv_Create: INv_.Inv_Create,
      Inv_PaymentDate: INv_.PaymentDate,
      Type_: INv_.Type,
      Payment_Status: INv_.Payment_Status,
      Wallet_ID: INv_.Wallet_ID,
      Client_ID: INv_.Client_ID,
    });
  });
} 
  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (SelectAll) {
      const newSelected_ = rows.map((n) => n.Invoice_ID);
      setSelected(newSelected_);
      return;
    }
    setSelectedByClint([]);
    setSelected([]);
  };

  const handleClick = (event, Invoice_ID, Client_ID) => {
    const selectedIndex = selected.indexOf(Invoice_ID);
    let newSelected = [];
    dispatch(SelectInvoice(Invoice_ID));
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Invoice_ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectedByClint(Client_ID);
  };

  const isSelected = (Invoice_ID) => selected.indexOf(Invoice_ID) !== -1;

  /*   const isSelected = (Invoice_ID) => selected.indexOf(name) !== -1; */

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  let Count = 0;
  let img_;
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "98%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 400 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const isItemSelected = isSelected(row.Invoice_ID);

                  const labelId = `enhanced-table-checkbox-${index}`;
                  switch (parseInt(row.Wallet_ID)) {
                    case 1:
                      img_ = "/images/Bmisr.png";
                      break;
                    case 2:
                      img_ = "/images/alex.jpg";
                      break;
                    case 3:
                      img_ = "/images/Egypost.png";
                      break;
                    case 4:
                      img_ = "/images/Bmisr.png";
                      break;
                    case 5:
                      img_ = "/images/Vcash.png";
                      break;
                    case 6:
                      img_ = "/images/Vcash.png";
                      break;
                    case 7:
                      img_ = "/images/orange_wallet.png";
                      break;
                    default:
                  }

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.Invoice_ID}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">{++Count}</TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.name}{" "}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        align="right"
                      >
                        {row.PCs}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        align="right"
                      >
                        {row.Total_Invoice}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        align="center"
                      >
                        {row.ShippingPolicy_No}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        align="center"
                      >
                        {row.Inv_Create}
                      </TableCell>
                      <TableCell   onClick={(event) =>handleClick(event, row.Invoice_ID, row.Client_ID)}   align="center">
                        {row.Inv_PaymentDate}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          handleClick(event, row.Invoice_ID, row.Client_ID)
                        }
                        align="center"
                      >
                        {row.Type_}
                      </TableCell>
<TableCell align="center"> {row.Payment_Status >= 1 ? (
<img onClick={(e) => handleSwitchCase([row, index])} height="20" src={"/images/SW-ON.png"} alt=""/>
) : (
<img onClick={(e) => handleSwitchCase([row, index])} height="20" src={"/images/SW-OFF.png"} alt="Off" />
)}  </TableCell> 
  <TableCell align="center" >
<img  onClick={(e) => handleSwitchCase([row, index])} className={"Wallet_img"} src={`${img_}`} alt=""       />
</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (false ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 17, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
