import React from 'react'
function TableRow(props) {
    var {row} = props;
    let x=0;
    return (
        <tr  key={row[0]}>
            {row.map(val => <td  key={++x}>{val}</td>)}
        </tr>
    )
}
export default TableRow