import React from 'react'
 function ClintCityItem(props) {
    const {isLoading, ClintCity,filterClintByCity,dispatch} = props;
    const ListCity =
    ClintCity.length > 0 && (
      ClintCity.map((Item, index) => (
        <div
          className="city"
          key={index}
          onClick={() => dispatch(filterClintByCity(Item.City))}
        >{Item.City} <span> {Item.Total}</span>
        </div>
      ))
    );
  return (

    <div className="card full-height">
    <h2>احصائيات المحافظات </h2>
    <div className="box-body ">
      <div className="AnalyticsCity">
        {isLoading && ClintCity.length<=0 ? <div className="IsLoading"></div> : <>{ListCity}</>}
      </div>
    </div>
  </div>
    )
}

export default   ClintCityItem;