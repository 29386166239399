import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SaveNewCategory, ONUpdate } from "./../../store/CategoriesSlice";
import { SwToast } from "./../../lib/SwAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../lib/config";
import BGup from "../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image

export default function FormCat() {
  const {CategoryImgName,CategoryToEdit,isEdit,ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg } = useSelector((state) => state.category);
  const [selectedImg, setSelectedImg] = useState(    CategoryImgName.length ? CategoryImgName : BGup  );
  const [SelectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();
  const AcceptFileType = ".gif,.jpg,.jpeg,.png";

  const validationSchema1 = Yup.object({
  active: Yup.number().typeError("The value must be a number").required("Required"),
  CatName: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
  CatDescription: Yup.string().max(150, "Must be 150 characters or less").required("Required"),
  file: Yup.mixed().required("select  cover img"),
  });

  const validationSchema2 = Yup.object({
    IndexId: Yup.number().typeError("The value must be a number"),
    _id: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
    active: Yup.number().typeError("The value must be a number").integer("The value must be a number").required("Required"),
    CatImage: Yup.string().max(150, "Must be 50 characters or less"),
    CatName: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
    CatDescription: Yup.string().max(150, "Must be 150 characters or less").required("Required"),
    file: Yup.mixed("select  cover img"),
  });

  const formik = useFormik({
    initialValues: {CatName: "",CatDescription: "",active: 1,file: {},IndexId: "",id: "",  CatImage: ""  },
    validationSchema: isEdit === false ? validationSchema1 : validationSchema2,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit) {
        handelSave(JSON.stringify(values, null, 2));
      } else {
        handelSave(JSON.stringify(values, null, 2));
      }
    },
  });

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm();
      setSelectedImg(BGup);
      setSelectedFile();
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg,dispatch  ]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;

  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.CatName) SwToast("warning", `Cat Name  ${FMKEr.CatName}`);
      if (FMKEr.CatDescription) SwToast("warning", `Cat Description   ${FMKEr.CatDescription}`);
      if (FMKEr.CatImage) SwToast("warning", `Cat Image   ${FMKEr.CatImage}`);
      if (FMKEr.active) SwToast("warning", `Cat active   ${FMKEr.active}`);
    }, 150);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("file", "");
      setSelectedFile("");
      // formik.setFieldValue("IndexId", CategoryToEdit.IndexId);
      async function setInitialValues() {
        await formik.setValues(CategoryToEdit, false);
      }
      setInitialValues();
      setSelectedImg(config.get("URLs.CatCover") + CategoryToEdit.CatImage);
    }else{
      formik.resetForm();
      setSelectedImg(BGup);
      setSelectedFile();
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CategoryToEdit, isEdit]);

  const onFileSelect = (event) => {
    if(event.target.files[0]){
    const ImgType = event.target.files[0].type.split("/", 2)[1];
    if (AcceptFileType.includes(ImgType)) {
      formik.setFieldValue("file", event.currentTarget.files[0]);
      setSelectedImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
      if (isEdit) {
        formik.setFieldValue("CatImage", event.target.files[0].name);
      }
    } else {
      SwToast("error", `😡 file type not include in accept `);
    }
  }
  };

  const handelSave = (values) => {
    const formData = new FormData();
    formData.append("CatInfo", values);

    if (SelectedFile) {
      const ImgType = SelectedFile.type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formData.append("File_data", SelectedFile, SelectedFile.name);
      } else {
        SwToast("error", `😡 file type not include in accept `);
        formik.setFieldValue("file", "");
        setSelectedFile("");
      }
    }

    if (isEdit && !SelectedFile) {
      dispatch(SaveNewCategory(formData));
      formData.delete(formData);
    } else {
      if (SelectedFile) {
        dispatch(SaveNewCategory(formData));
        formData.delete(formData);
      } else {
        SwToast("error", `image file is not selected`);
      }
    }
  };

  return (
    <div className="addCat">
      <div className="Cont_cat ">
        <form onSubmit={formik.handleSubmit}>
          <div className="cHbody TRnsion">
            <div
              className="Cont_cat_Cover TRnsion"
              style={{ backgroundImage: `url('${selectedImg}')` }}
            >
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => onFileSelect(event)}
                accept={AcceptFileType}
                title=""
              />
            </div>
            <div className="Cont_cat_input ">
              <input
                autoComplete="off"
                id="CatName"
                name="CatName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.CatName}
                className={`Gallery_title TRnsion  ${
                  formik.touched.CatName && formik.errors.CatName
                    ? "inputError"
                    : null
                }`}
                maxLength="50"
                placeholder="اكتب اسم الفئة ... "
              />
              <div className="desc">
                <textarea
                  className={`Gallery_subtitle TRnsion  ${
                    formik.touched.CatDescription &&
                    formik.errors.CatDescription
                      ? "inputError"
                      : null
                  }`}
                  id="CatDescription"
                  name="CatDescription"
                  maxLength="150"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.CatDescription}
                  placeholder="وصف مختصر للفئة ... "
                ></textarea>
                <div className="counterC">0</div>
              </div>
            </div>
          </div>

          <div className="Cont_cat_Button">
            <select
              name="active"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.active}
              className={`list TRnsion  `}
            >
              <option value={1}>الفئة نشطة </option>
              <option value={0}>الفئة غير نشطة </option>
            </select>

            <div id="ActionButon_" className="btn TRnsion ">
              <i className="fas fa-atom  btnAction TRnsion "></i>
              <span style={{ border: "transparent" }} title="عنوان الزر">
                عنوان الزر
              </span>
              <i className="fas fa-times btnAction TRnsion "></i>
            </div>
            <div id="ActionButon_" className="btn TRnsion ">
              <i className="fas fa-atom  btnAction TRnsion "></i>
              <span style={{ border: "transparent" }} title="عنوان الزر">
                عنوان الزر
              </span>
              <i className="fas fa-times btnAction TRnsion "></i>
            </div>
            {/* <div id="ActionCat_" onClick={() => handelSave()}   className="btn TRnsion "  > <i className="fas fa-save"></i> حفظ / وتحديث </div> */}
            <button type="submit" className="btn TRnsion ">
              <i className="fas fa-save"></i> حفظ / وتحديث
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
