import React from 'react'
import '../../../assets/css/Layout/Footer/Footer.css';
import { words } from '../../../words';//this word for app
export default function Footer() {
    return (
        <footer>
           {words.footerTitle}
        </footer>
    )
}
