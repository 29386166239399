import React from "react";
import Modal from "react-modal";
import "../../../assets/css/PointCashier/HToolBar/ClintCard.css";

function ClintCard(props) {
  const { ClintInfo, closeModal } = props;
  return (
    <Modal isOpen={!!ClintInfo} onRequestClose={closeModal} ariaHideApp={false}>
      <span className="CloseModal" onClick={closeModal}>
        {" "}
        &times;
      </span>
      <div className="modal-content-clint">
        {ClintInfo && (
          <>
            <h1> {ClintInfo.Client_Name}</h1>
            <div>
              <h2>
                {" "}
                {ClintInfo.Address.City} - {ClintInfo.Address.First_Address}{" "}
              </h2>{" "}
            </div>
            <div>
              <h2>
                {" "}
                {ClintInfo.Client_phone} - {ClintInfo.Client_phone_2}{" "}
              </h2>{" "}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
export default ClintCard;
