import React from "react";
import "../../assets/css/ASQuestions/ASQuestions.css";
import { words } from "../../words";
import QuestForm from "./Components/QuestForm";
import Answer from "./Components/ASQ_answer";
import Quest from "./Components/ASQ_Quest";
const QLang = words.lang.ar.Questions;
function ASQuestionsIndex() {


  return (
    <div className="wrapper-container">
      <div className="cat_nav_Owne">
        <div className="row">
          <div className="p-title">
            <h1>
           الرد الآلى   <i className="fas fa-brain"></i> {QLang.title}
            </h1>
          </div>
        </div>
        <hr className="style16"></hr>
      </div>
      <div className="Container">
        <QuestForm />
        <hr></hr>

        {/* <h1> start hear </h1> */}
        <div className="ASQuest_container">
          <Answer QLang={QLang}  />
          <Quest QLang={QLang} 
          />
        </div>
      </div>
    </div>
  );
}

export default ASQuestionsIndex;
