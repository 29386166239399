import React ,{useEffect} from "react";
 import BotIndex from "../components/Bot/BotIndex";
import LayoutPages from "./LayoutPages";
function Bot() {
  useEffect(() => {
    setTimeout(() => {
       document.title =(`تحليلات النظام`);
     }, 100);
    });

  return (
    <LayoutPages>
      <BotIndex />
    </LayoutPages>
  );
}

export default Bot;
