import React from 'react'
import LayoutPages from './LayoutPages'
import DebtIndex from '../components/Debt/DebtIndex';
function Debt() {
  return ( 
    <LayoutPages>
     <DebtIndex/>
    </LayoutPages>
  
    )
}

export default Debt