import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTotal_analysis,analysisHash } from "../../../store/analysisSlice";
import "../../../assets/css/analytics/components/Cards.css";
function Cards() {
  const { Data_analysis,isLoading } = useSelector((state) => state.analysis);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Data_analysis.length <= 0) {
      dispatch(GetTotal_analysis());
    }
  }, [dispatch, Data_analysis.length]);

  const Cards =
    Data_analysis.length >= 1 ? (
      Data_analysis.map((Item, index) => (
        <div key={index} className="status-card">
          <div className="status-card__icon">
            <i className={Item.icon}></i>
          </div>
          <div className="status-card__info">
            <h4 onClick={()=>dispatch(analysisHash([Item,index]))}>{Item.hash?"▓▒▒░░▒":Item.count}</h4>
             <span>{Item.title}</span>
          </div>
        </div>
      ))
    ) : (
     
    <></>
    );

  return (
    <>
     {isLoading ? <div className="IsLoading"  ></div> : <>{Cards}</>}
    </>
  );
}

export default Cards;
