import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncPagesList,
  SyncBotList,
  SaveBot_,
  ONUpdate,
} from "../../store/TelegramSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../lib/SwAlert";

function NewBotForm() {
  const {
    PagesList,
    ChanelList,
    selectedEdit,
    isEdit,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Telegram);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncPagesList());
    // dispatch(SyncPagesList());
  }, [dispatch]);

  const List =
    PagesList.length >= 1 ? (
      PagesList.map((Item, index) => (
        <option key={index} value={Item.PageID}>
          {Item.Page_Name}
        </option>
      ))
    ) : (
      <></>
    );

  const validationSchema = Yup.object({
    botId: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    PageID: Yup.number()
      .typeError("The value characters")
      .integer("The value must characters")
      .required("Required"),
    botName: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    UserName: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    BotHock: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    BotUid: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    botToken: Yup.string()
      .max(250, "Must be 150 characters or less")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      botId: 0,
      botName: "",
      PageID: "",
      UserName: "",
      BotHock: "",
      BotUid: "",
      botToken: "",
      DefaultChanelId: "",
      Def_Ch_UserName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit) {
        dispatch(SaveBot_(JSON.stringify(values, null, 2)));
      } else {
        dispatch(SaveBot_(JSON.stringify(values, null, 2)));
      }
    },
  });

  useEffect(() => {
    if (isEdit && selectedEdit) {
      formik.setFieldValue("botId", selectedEdit.botId);
      formik.setFieldValue("PageID", selectedEdit.PageID);
      formik.setFieldValue("botName", selectedEdit.botName);
      formik.setFieldValue("UserName", selectedEdit.UserName);
      formik.setFieldValue("BotHock", selectedEdit.botHock);
      formik.setFieldValue("BotUid", selectedEdit.botUid);
      formik.setFieldValue("botToken", selectedEdit.botToken);
      formik.setFieldValue("DefaultChanelId", selectedEdit.DefaultChanelId);
      formik.setFieldValue("Def_Ch_UserName", selectedEdit.Def_Ch_UserName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedEdit]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.PageID) SwToast("warning", `Selected Page   ${FMKEr.PageID}`);
      if (FMKEr.botName) SwToast("warning", `bot Name  ${FMKEr.botName}`);
      if (FMKEr.UserName) SwToast("warning", `User Name  ${FMKEr.UserName}`);
      if (FMKEr.BotUid) SwToast("warning", `Bot Uid  ${FMKEr.BotUid}`);
      if (FMKEr.BotHock) SwToast("warning", `Bot Hock  ${FMKEr.BotHock}`);
      if (FMKEr.botToken) SwToast("warning", `bot Token  ${FMKEr.botToken}`);
      if (FMKEr.DefaultChanelId)
        SwToast("warning", `bot Default Chanel   ${FMKEr.DefaultChanelId}`);
      if (FMKEr.Def_Ch_UserName)
        SwToast(
          "warning",
          `bot Default Chanel Name   ${FMKEr.Def_Ch_UserName}`
        );
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
      dispatch(SyncBotList());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);
  const select_nameCh = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    const username = selectedOption.getAttribute('username');
    formik.setFieldValue("DefaultChanelId",  e.target.value);
    formik.setFieldValue("Def_Ch_UserName",  username);
  };

  const Ch_List =
    ChanelList.length >= 1 ? (
      ChanelList.filter(item => item.username !== "").map((Item, index) => (
        <option
          key={index}
          value={Item.memberId}
          username={Item.username}
         >
          {Item.first_name}
        </option>
      ))
    ) : (
      <></>
    );

  return (
    <div className="NewClint card full-height">
      <h2>اضافة بوت تلجرام </h2>
      <div className="box-body ">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group col-12 col-md-6 col-lg-12">
            <label htmlFor="PageID">الصفحة</label>
            <select
            id={"PageID"}
              name="PageID"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.PageID}
              className={`list TRnsion  ${
                FMKEr.PageID ? "inputError" : "inputSuccess"
              }`}
            >
              <option value={0}> حدد الصفحـة </option>
              {List}
            </select>
          </div>
          {isEdit && (
            <div className="form-group col-12 col-md-6 col-lg-12">
              <label htmlFor="DefaultChanelId" >القناة </label>
                <select
                id={"DefaultChanelId"}
                name="DefaultChanelId"
                type="text"
                 onChange={(e)=>select_nameCh(e)}
                onBlur={formik.handleBlur}
                value={FMkv.DefaultChanelId}
                className={`list TRnsion  ${
                FMKEr.DefaultChanelId ? "inputError" : "inputSuccess"
                }`}
                >
                <option value={0}> حدد قناة لطلب اشتراك </option>
                {Ch_List}
              </select>
            </div>
          )}
          <div className="form-group col-md-12">
            <label htmlFor="botName">اسم البوت :</label>
            <input 
              id={"botName"}
              autoComplete="off"
              name="botName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.botName}
              className={`Gallery_title TRnsion  ${
                FMKEr.botName ? "inputError" : "inputSuccess"
              }`}
              placeholder="Bot Shop Store"
            />
          </div>
          <div className="Rowcc">
            <div className="">
              <label htmlFor="UserName">المستخدم : </label>
              <input
                id={"UserName"}
                autoComplete="off"
                name="UserName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.UserName}
                className={`Gallery_title TRnsion  ${
                  FMKEr.UserName ? "inputError" : "inputSuccess"
                }`}
                placeholder="User telegram_bot"
                maxLength="100"
              />
            </div>
            <div className="">
              <label htmlFor="BotHock">سريل الخطاف : </label>
              <input
                id={"BotHock"}
                autoComplete="off"
                name="BotHock"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.BotHock}
                className={`Gallery_title TRnsion  ${
                  FMKEr.BotHock ? "inputError" : "inputSuccess"
                }`}
                placeholder="SN# GRwd5tLp1GNe  "
                maxLength="50"
              />
            </div>
          </div>
          <div className="Rowc">
            <div className="form-group col-md-12">
              <label htmlFor="BotUid"> سريل البوت : </label>
              <input
                id={"BotUid"}
                name="BotUid"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.BotUid}
                className={`Gallery_title TRnsion  ${
                  FMKEr.BotUid ? "inputError" : "inputSuccess"
                }`}
                placeholder="B_ID 12123564556: "
                maxLength="100"
              />
            </div>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="botToken">التوكين :</label>
            <input
              id={"botToken"}
              name="botToken"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.botToken}
              className={`Gallery_title TRnsion  ${
                FMKEr.botToken ? "inputError" : "inputSuccess"
              }`}
              style={{ width: "100%" }}
              placeholder="Bot Token"
            />
          </div>
          <div className="box-footer sButon  ">
            {/* <input type="reset" className="btn-success" value="إلغاء" /> */}
            <input

              className="btn-success"
              type="submit"
              name="SaveClient_"
              value="حفظ / تحديث"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewBotForm;
