import React from 'react'
import LayoutPages from './LayoutPages'
import ViewCatalogIndex from '../components/View_Catalog/ViewCatalogIndex';
function View_Catalog() {
  return ( 
    <LayoutPages LeftSidebarHide={true} >
     <ViewCatalogIndex/>
    </LayoutPages>
  
    )
}

export default View_Catalog