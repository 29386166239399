import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAudience,
  SetActiveAudience,
  SyncTAudience,
} from "../../../store/audienceSlice";
import { slice } from "lodash";

function AduList() {
  const { Audience, isLoading } = useSelector((state) => state.audience);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [index, setIndex] = useState(62);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncTAudience());
  }, [dispatch]);

  const initialPosts = slice(Audience, 0, index);
  const select_ = (Item) => dispatch(SelectAudience(Item));

  const List =
    initialPosts.length > 0 ? (
      initialPosts.map((Item, index) => (
        <div
          className={`audience Transition ${Item.checked ? "Selected" : ""} `}
          key={parseInt(index) + 10000}
          onClick={() => select_(Item)}
        >
          <div className="labelsColor">
            <div className="lab"></div>
            <div className="lab"></div>
            <div className="lab"></div>
            <div className="lab"></div>
          </div>
          <div className="Img Transition"></div>
          <div className="audInfo">
            <div>
              {Item.ClientID && (
                <span
                  style={{ color: "#27a6f5", fontSize: "14px" }}
                  title={`telegram Verified \n ${
                    Item.Au_Id + "-" + Item.memberId
                  }`}
                >
                  <i className="fas fa-certificate"></i>
                </span>
              )}
              <span> {`${Item.first_name} ${Item.last_name}`} </span>
            </div>

            <div className="optionAud">
              {Item.active >= 1 ? (
                <img
                  onClick={(e) => dispatch(SetActiveAudience([Item, index]))}
                  height="20"
                  src={"/images/SW-ON.png"}
                  alt=""
                />
              ) : (
                <img
                  onClick={(e) => dispatch(SetActiveAudience([Item, index]))}
                  height="20"
                  src={"/images/SW-OFF.png"}
                  alt="Off"
                />
              )}
            </div>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "600px", backgroundSize: "50%" }}
      ></div>
    );

  const loadMore = () => {
    setIsLoad(true);
    setTimeout(() => {
      setIndex(index + 100);
      if (index >= Audience.length) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
      setIsLoad(false);
    }, 600);
  };

  return (
    <>
      {isLoading ? (
        <div className="IsLoading"></div>
      ) : (
        <>
          {List}
          <div className="more">
            {!isCompleted &&
            Audience.length >= 1 &&
            Audience.length >= index ? (
              <div className="loadMore Transition" onClick={() => loadMore()}>
                {!isLoad ? (
                  <>
                    <span> load More ....</span>
                  </>
                ) : (
                  <>
                    <img alt="" height="20" src={"/images/WaitBok.gif"} />
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}
export default AduList;
