import React from 'react'
const lodash = require("lodash");

 function ClintCityItem(props) {
    const {isLoading, DebtList} = props;
   
    const total = lodash.sum(
      DebtList.map((item) => parseInt(item.Debt_v))
    );
    
  return (

    <div className="card full-height">
    <h2>اجمالى قيمة الديون  </h2>
    <div className="box-body ">
      <div className="AnalyticsCity">
        {isLoading && DebtList.length<=0 ? <div className="IsLoading"></div> : <>
              <h1 className="mQ">{String(total).replace(/(.)(?=(\d{3})+$)/g, "$1,")} L.E </h1>
        </>}
      </div>
    </div>
  </div>
    )
}

export default   ClintCityItem;