import { createSlice } from "@reduxjs/toolkit";
import { AddToCart,SubmitEditItems } from './cartSlice';
import { updatePrice } from './selectedSlice';

const initState = { QuantityVal: 1, error: null };
const QuantitySlice = createSlice({
  name: "Quantity",
  initialState: initState,
  reducers: {
    Increase: (state) => {
      state.QuantityVal += 1;
    },
    Decrement: (state) => {
      state.QuantityVal>1?  state.QuantityVal -= 1 : state.QuantityVal=1;
    },
    SetQuantity: (state, action) => {
      state.QuantityVal = parseInt(action.payload);
    }
  },
  extraReducers: {
    [AddToCart.fulfilled]: (state, action) => {
      if (action.payload[2].Invoice_ID >= 1) { //if  already selected order reset quantity equal 1
      state.QuantityVal = 1;
      }
      
    },
    [SubmitEditItems.fulfilled]: (state) => {
      state.QuantityVal = 1;
    },
    [updatePrice.fulfilled]: (state) => {
      state.QuantityVal = 1;
    }
  },
});

export const { Increase, Decrement, SetQuantity } = QuantitySlice.actions;
export default QuantitySlice.reducer;

