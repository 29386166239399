import React, { useEffect } from "react";
import ToolBar from "./Components/ToolBar";
import AduList from "./Components/AduList";
import "../../assets/css/TelegramAudience/TelegramAudience.css";
import Labels from "./Components/Lables/Labels";

const TelegramAudienceIndex = () => {
  useEffect(() => {
    setTimeout(() => {
      document.title = `   الجمهور - (Audience) `;
    }, 100);
  });

  return (
    <>
      <div className="wrapper-Audience">
        <ToolBar />
        <div className="MainAudience">
          <div className="LiftBar">
            <Labels/>
          
          </div>
          <div className="RightPage">
            <AduList />
          </div>
        </div>
      </div>
    </>
  );
};
export default TelegramAudienceIndex;
