import React ,{useEffect} from "react";
import HeaderToolBar from "./components/ToolBar/HeaderToolBar";
import TableUi from "./components/Table_Ui";
import AnalysisSidBar from "./components/AnalysisSidBar";
import "../../assets/css/InvoiceOrders/InvoiceOrders.css";
function InvoiceOrdersIndex() {
  const d = new Date();
  useEffect(() => {
    setTimeout(() => {
       document.title =(` إدارة الفواتير - ( ${d.getMonth()+1} )   `);
     }, 100);
    });
  return (
    <>
      <HeaderToolBar />
      <div className="wrapper-invoice">
        <div className="list-orders-wrapper">
          <TableUi RowCount={17} />
        </div>

       <AnalysisSidBar/>
      </div>
    </>
  );
}

export default InvoiceOrdersIndex;
