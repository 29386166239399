import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Paste } from './CatalogSlice';

export const Copy = createAsyncThunk(
  "clipboard/Copy",  async (_, thunkAPI) => {
    const {getState } = thunkAPI;
    return getState().catalog.ProductCatalog;
  }
);

const initState = {
  Clip:[],
};
const ClipboardSlice = createSlice({
  name: "clipboard",
  initialState: initState,
  reducers: {
    Paste_: (state, action) => {
      state.Clip.map((Item) => {
        // console.log(Item);
        return null;
      })
    },

  },
  extraReducers: {
    [Copy.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Copy.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      let obj = action.payload.filter((o) => o.checked === true);
      obj.map((Item) => {
        let FindIn = state.Clip.find((o) => o.id === Item.id);
        !FindIn ? state.Clip.push({ ...Item }) : (FindIn.Quantity = 1);
        return null;
      })
    },
    [Copy.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Paste.fulfilled]: (state, action) => {
      state.Clip=[];
    },


  }
})


export const {
  Paste_
} = ClipboardSlice.actions;
export default ClipboardSlice.reducer;