import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import cache from "../lib/cache"; //local storage library
const lodash = require("lodash");

export const Sync_Order_Info_ = createAsyncThunk(
  "Orders/Sync_Order_Info_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const Order_Info_ = endpoint
        .get(`orders/order_info_/${_}`)
        .then((response) => {
          return response.data;
        })
        .catch((error, Order_Info_) => {
          Order_Info_ = [];

          console.log(`----Error --- > ${error} ----`);
        });
      return Order_Info_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncOrders_ = createAsyncThunk(
  "Orders/SyncOrders_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const OrderList = endpoint
        .post("orders/syncorderlist_", {
          UserId: getState().auth.UserInfo.UserId,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error, OrderList) => {
          OrderList = [];

          console.log(`----Error --- > ${error} ----`);
        });
      return OrderList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CreateNewOrder_ = createAsyncThunk(
  "Orders/CreateNewOrder_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const Clint_id = getState().clint.SelectedClint;
    try {
      const NewOrder = endpoint
        .post("orders/create_", {
          Clint_id: Clint_id,
          UserId: getState().auth.UserInfo.UserId,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return NewOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "Orders/deleteOrder",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // console.log(_.Invoice_ID);
      endpoint
        .delete(`orders/remove_/${_.Invoice_ID}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const update_order_ = createAsyncThunk(
  "Orders/update_order_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post("orders/update_order_", {
          _,
          UserId: parseInt(getState().auth.UserId),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CloseOrder = createAsyncThunk(
  "Orders/CloseOrder",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "orders/close_order_",
          JSON.stringify({ _, UserId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const push_catalog_ = createAsyncThunk(
  "Orders/push_catalog_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Send_Catalog = endpoint
        .post(
          "bot/push_catalog_",
          JSON.stringify({ INvId: _, UserId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Send_Catalog;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const SendInvoice_ = createAsyncThunk(
  "Orders/SendInvoice_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Send_Invoice_ = endpoint
        .post(
          "bot/push_invoice_",
          JSON.stringify({ INvId: _, UserId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Send_Invoice_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//CloseOrder
const initState = {
  isLoading: false,
  OrderList: [],
  CloneOrderList: [],
  IsSelectOrder: cache.get("SelectedOrder") || [],
  order_info: [],
  IsUpdate: false,
  showList: false,
  IsCreateOrder: null,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  Total_Invoice: 0,
  error: null,
};
const OrderSlice = createSlice({
  name: "Orders",
  initialState: initState,
  reducers: {
    GetTotal_OpenOrder: (state, action) => {
      state.Total_Invoice = lodash.sum(
        state.OrderList.map((Item) => {
          return parseInt(Item.Total_Invoice);
        })
      );
    },
    ONUpdate: (state) => {
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },
    setIsUpdate: (state) => {
      state.IsUpdate = true;
    },
    FindByClintId: (state, action) => {
      const Res = state.OrderList.find((result) => {
        // find order by clint id and select
        return result.Client_ID === action.payload;
      });

      if (Res) {
        state.OrderList.map((e) => (e.checked = false));
        Res.checked = true;
        state.IsSelectOrder = Res; // select order by clint id
        state.CloneOrderList = state.OrderList; // cope order list after change
      }
      // console.log();
    },
    selectOrder: (state, action) => {
      var Sel_order_index = action.payload[1];
      var Sel_order = action.payload[0];

      state.OrderList.map((e, index) => {
        if (index === Sel_order_index && e.checked === true) {
          e.checked = false;
          cache.remove("SelectedOrder"); //save to local storage cart item
          state.IsSelectOrder = [];
        } else if (index !== Sel_order_index) {
          e.checked = false;
        } else {
          e.checked = true;
          state.IsSelectOrder = Sel_order; // add selected order to selected
          cache.set("SelectedOrder", Sel_order); //save to local storage cart item
        }
        return null;
      });

      /* 
 var Invoice_ID = action.payload[0].Invoice_ID,
        filtered = state.CloneOrderList.find(function (str) {
          return str.Invoice_ID === Invoice_ID;
        });
      filtered.checked = true;  */
    },
    switchList: (state, action) => {
      state.showList = action.payload; //!state.showList;
    },
    findOrders: (state, action) => {
      state.OrderList = state.CloneOrderList;
      // console.log(action.payload[1]);
      const Res = state.OrderList.filter((result) => {
        if (action.payload[1] === "ByName") {
          return result.Client_Name.includes(action.payload[0]);
        } else {
          return result.Client_phone.includes(action.payload[0]);
        }
      });
      // console.log(Res);
      state.OrderList = Res;
    },
  },

  extraReducers: {
    [CloseOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [CloseOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
        state.OrderList = state.OrderList.filter(
          (el) => el.Invoice_ID !== state.IsSelectOrder.Invoice_ID
        );
        state.IsSelectOrder = []; //delete order info  from selected state
        cache.remove("SelectedOrder"); //save to local storage cart item
      }
      // console.log(action.payload);
    },
    [CloseOrder.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Sync_Order_Info_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Sync_Order_Info_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.order_info = action.payload;
    },
    [Sync_Order_Info_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [update_order_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [update_order_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
      state.IsUpdate = true;
      // console.log(action.payload);
    },
    [update_order_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },

    [deleteOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      // console.log(state.IsSelectOrder.Invoice_ID);
      if (action.payload.Invoice_ID === state.IsSelectOrder.Invoice_ID) {
        state.IsSelectOrder = []; //delete order info  from selected state
        cache.remove("SelectedOrder"); //save to local storage cart item
      }
      state.OrderList = state.OrderList.filter(
        (el) => el.Invoice_ID !== action.payload.Invoice_ID
      );
      state.CloneOrderList = state.OrderList;
    },
    [deleteOrder.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },

    [SyncOrders_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncOrders_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.OrderList = action.payload;
      state.Total_Invoice =
        state.OrderList &&
        lodash.sum(
          state.OrderList.map((Item) => {
            return parseInt(Item.Total_Invoice);
          })
        );

      // state.CloneOrderList = action.payload;
      if (state.IsSelectOrder && state.IsSelectOrder.Invoice_ID >= 1) {
        //if find selected order set to select after sync
        var Invoice_ID = state.IsSelectOrder.Invoice_ID,
          filtered = state.OrderList.find(function (str) {
            return str.Invoice_ID === Invoice_ID;
          });
        if (filtered) {
          filtered.checked = true;
          state.IsSelectOrder = filtered;
          cache.set("SelectedOrder", filtered); //save to local storage cart item
        } else {
          state.IsSelectOrder = [];
        }
      }
      // state.IsSelectOrder = filtered;
      // cache.set('SelectedOrder', filtered);//save to local storage cart item
      //get clone from list order after check selected order
      state.CloneOrderList = action.payload;
    },
    [SyncOrders_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [CreateNewOrder_.pending]: (state, action) => {
      state.isLoading = true;
      state.IsCreateOrder = null;
      state.showList = false;
    },
    [CreateNewOrder_.fulfilled]: (state, action) => {
      //open new order
      state.isLoading = false;
      if (action.payload.errors && action.payload.errors.found) {
        //if fond order
        state.IsCreateOrder = false;
      } else if (action.payload.succuss && action.payload.succuss.create) {
        //if not found and open
        state.IsCreateOrder = true;
        state.showList = true;
      }
    },
    [CreateNewOrder_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [push_catalog_.pending]: (state, action) => {},
    [push_catalog_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;   
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
       
    },
    [push_catalog_.rejected]: (state, action) => {},
  },
});

export const {
  setIsUpdate,
  selectOrder,
  switchList,
  findOrders,
  FindByClintId,
  ONUpdate,
  GetTotal_OpenOrder,
} = OrderSlice.actions;
export default OrderSlice.reducer;
