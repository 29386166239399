import React from "react";
import { useSelector } from "react-redux";
import config from "../../lib/config";

export default function TopCatItem() {
  const { TopCatList } = useSelector((state) => state.category);
  const List =
    TopCatList.length > 0 ? (
      TopCatList.map((Item, index) => (
        <div className="cat_H TRnsion" key={index} title={Item.CatName}>
          <div
            className="Cat_img "
            style={{
              backgroundImage: `url('${
                config.get("URLs.CatCover") + Item.CatImage
              }')`,
            }}
          ></div>
          <h1>{parseInt(Item.Total)}</h1>
          <span>مُنتج</span>
        </div>
      ))
    ) : (
      <></>
    );
  return <div id="MainCat">{List}</div>;
}
