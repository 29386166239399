import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import { AddToCart } from "./cartSlice";

const initState = {
  selectedList: [],
  IsUpList: [],
  error: false,
  ErMessage: null,
  isCheck: false,
  SaveEdit: false,
};


export const SaveEditSelect = createAsyncThunk(
  "select/SaveEditSelect",
  async (post, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const result = post.map((e) => {
      const { title, Product_Code, imgUrl,description,sizes,_id, ...Res} = e; //remove this key from list items
      return Res;
    });
    try {
      const NewOrder = endpoint
        .post("products/set_upp_", JSON.stringify({"UserId":getState().auth.UserId ,"Items":result}))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return NewOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const AddToList = createAsyncThunk(
  "select/AddToList",
  async (Item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      return Item;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePrice = createAsyncThunk(
  "select/updatePrice",
  async (_operator, thunkAPI) => {
    const { rejectWithValue,getState } = thunkAPI;
    try {
      return [_operator,parseInt(getState().Quantity.QuantityVal)];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const RemoveEditSelect = createAsyncThunk(
  "select/RemoveEditSelect",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const DeleteSelectedItem = createAsyncThunk(
  "select/RemoveEditSelect",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const selectedSlice = createSlice({
  name: "select",
  initialState: initState,
  reducers: {
    ADD_SelectAll: (state, action) => {
      // console.log(action.payload);

      action.payload&& action.payload.map((item) => {
        if (item.checked) {
          let obj = state.selectedList.find((o) => o.id === item.id);
          if (!obj) {
            state.selectedList.push({ ...item, Quantity: 1, checked: false });
          }
        } else {
          state.selectedList = state.selectedList.filter(
            (el) => el.id !== item.id
          );
        }
        return null;
      });
    },

    SelectedItem: (state, action) => {
      let obj = state.selectedList.find((o) => o.id === action.payload.id);
      if (obj) {
        obj.checked = !obj.checked;
        state.isCheck = true;
      }

      !state.selectedList.find((o) => o.checked)
        ? (state.isCheck = false)
        : (state.isCheck = true);
    },
    SelectAll: (state, action) => {//select all in cart list item
      state.selectedList.map((Item) =>         Item.checked=!action.payload)
    !action.payload? state.isCheck=true: state.isCheck=false;

  },
  ClearSel:(state) => {
    state.selectedList=[];
  }, 
  },
  extraReducers: {
    [SaveEditSelect.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveEditSelect.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.SaveEdit = false;
    },
    [SaveEditSelect.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [RemoveEditSelect.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [RemoveEditSelect.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedList = state.selectedList.filter(        (el) => el.id !== action.payload[0].id      );
if  (state.selectedList.length <=0) state.isCheck = false;
    },
    [RemoveEditSelect.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updatePrice.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [updatePrice.fulfilled]: (state, action) => {
      state.isLoading = false;

      // console.log(action.payload[0]);
       let obj = state.selectedList.filter((o) => o.checked === true);
if(action.payload[0]==='plus'){
  obj.map((Item, index) => Item.price = parseInt(Item.price) + parseInt(action.payload[1]));
}else{
  obj.map((Item, index) => Item.price = parseInt(Item.price) - parseInt(action.payload[1]));
}
      state.IsUpList=state.selectedList.filter((o) => o.checked === true);
      obj.map((Item, index) => Item.checked = false);  

      state.isCheck = false;
      state.SaveEdit = true;
    },
    [updatePrice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [AddToList.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [AddToList.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (!action.payload[0].checked) {
        let obj = state.selectedList.find((o) => o.id === action.payload[0].id);
        if (!obj) {
          state.selectedList.push({ ...action.payload[0], Quantity: 1 });
        }
      } else {
        state.selectedList = state.selectedList.filter(
          (el) => el.id !== action.payload[0].id
        );
      }
    },
    [AddToList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [AddToCart.fulfilled]: (state, action) => {
      if (action.payload[2].Invoice_ID >= 1) {
        //if  already selected order add item to cart list
        state.selectedList = [];
      } else {
        state.ErMessage =
          "error can`t add item to cart list please select a new order";
        // console.log(state.ErMessage);
      }
    },
    [DeleteSelectedItem.fulfilled]: (state, action) => {
          //delete multiple items from  list
    let obj = state.selectedList.filter((o) => o.checked === true);
    obj.map((Item, index) =>   state.selectedList = state.selectedList.filter((el) => el.id !== Item.id) );
    state.isCheck = false;
    state.isLoading = false;
    },
  },
});
export const { ADD_SelectAll, SelectedItem ,SelectAll,ClearSel} = selectedSlice.actions;
export default selectedSlice.reducer;
