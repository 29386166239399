import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { words } from "../../../words";
import "../../../assets/css/Layout/LeftSidebar/LeftSidebar.css"
import NClintBoxModal from "../../../components/Audience/NClintBoxModal";
import { ActiveClintModal } from "./../../../store/clintSlice";

function LeftSidebar() {
  const [showPopup, setShowPopup] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleLink= (link) => {
    navigate(`/${link}`);
  }
  const Lng=words.lang.ar.LeftSidebar;
  const togglePopup = (index) => {
    if (activePopup === index) {
      setShowPopup(!showPopup);
      setActivePopup(null); // إلغاء العنصر النشط عند الضغط مرة أخرى
    } else {
      setShowPopup(true);
      setActivePopup(index);
    }
  };
  const closeModal = () => {
    dispatch(ActiveClintModal(false));
  };
  const openModal = () => {
    dispatch(ActiveClintModal(true));
  };

  const menuItems = [
    { title: Lng.chartArea, icon: 'fas fa-chart-area', link: 'analytics',display:true,
    ItemsList:[
      { icon: 'fas fa-chart-area', title: 'احصائيات ', link: './analytics' },
      { icon: 'fas fa-hand-holding-usd', title: 'ديون العملاء', link: './debt' },
      
    ] },
    { title: "المبيعات", icon: 'fas fa-cash-register', link: 'NotInOrders' ,display:true,ItemsList:[
      { icon: 'fas fa-cash-register', title: 'شاشة المبيعات', link: './cashier' },
      { icon: 'fas fa-truck-loading', title: 'نواقص الاوردرات', link: './NotInOrders' },
      { icon: 'fas fa-receipt', title: 'إدارة الفواتير', link: './invoices' },
    ]},
    { title: "الجمهور", icon: 'fas fa-user-tag', link: 'TelegramAud_' ,display:true,ItemsList:[
      { icon: 'fas fa-user-tag', title: 'العملاء', link: './clients' },
      { icon: 'fas fa-user', title: 'عميل جديد', link:"",fun: ()=>openModal() },
      { icon: 'fas fa-brain', title: 'الموردين', link: './suppliers' },
    ]},
    { title: Lng.invoices, icon: 'fas fa-receipt', link: 'invoices' ,ItemsList:[],display:false},
    { title: Lng.debt, icon: 'fas fa-hand-holding-usd', link: 'debt',ItemsList:[] ,display:false},
    { title: Lng.tebot, icon: 'fab fa-telegram', link: 'tebot',ItemsList:[
      { icon: 'fas fa-brain', title: 'الرد الآلى', link: './questions' },
      { icon: 'fas fa-fingerprint', title: 'جمهور تلجرام', link: './TelegramAud_' },
      { icon: 'fab fa-telegram-plane fico', title: Lng.tebot, link: './tebot' },

    ],display:true },
    { title: Lng.Category, icon: 'fas fa-clipboard-list', link: 'Category' ,ItemsList:[],display:true},
    { title: Lng.Clients, icon: 'fas fa-user-tag', link: 'clients',ItemsList:[] ,display:false},
    { title: Lng.TelegramAud_, icon: 'fa fa-fingerprint', link: 'TelegramAud_' ,ItemsList:[],display:false},
    { title: Lng.Pages, icon: 'fas fa-flag', link: '' ,ItemsList:[],display:false},
  ];
 const LeftSidebarItems=menuItems.filter(item=>item.display);
  return (   
<>
<div className="LeftSidebar">
      <ul>
        {LeftSidebarItems.map((item, index) => (
          <li key={index}  className={`TRnsion ${activePopup === index ? 'activeItem' : ''}`}   title={item.title} onClick={() =>item.ItemsList.length>0? togglePopup(index):handleLink(`${item.link}`)}>
            <i className={item.icon}></i>
            {showPopup && activePopup === index &&item.ItemsList.length>0&& (
              <div className={`popup-menu  ${showPopup ? 'visible TRnsion' : ''}`}>
                {item.ItemsList.map((popupItem, idx) => (
                  <div key={idx} onClick={() => popupItem.link !== ""?handleLink(`${popupItem.link}`):popupItem.fun()} className="popup-item">
                    <i className={popupItem.icon}></i> <span>{popupItem.title}</span>
                  </div>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
    <NClintBoxModal   closeModal={closeModal} />

</>
 )
}
export default LeftSidebar