import React, { useEffect } from "react";
import CategoriesIndex from "../components/Categories/CategoriesIndex";
import LayoutPages from "./LayoutPages";
function Categories() {
  useEffect(() => {
    setTimeout(() => {
      document.title = `تصنيفات الصفحة`;
    }, 100);
  });

  return (
    <LayoutPages>
      <CategoriesIndex />
    </LayoutPages>
  );
}

export default Categories;