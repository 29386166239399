import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductByCode,
  FindProductByCodeInStore,
  getProducts,
  categoryProducts,
  ActiveSearch,
} from "../../../store/productsSlice";
export default function FindProduct() {
  const { category, products, SearchedProductCode } = useSelector(    (state) => state.product  );
  const [ProductCode, setProductCode] = useState("");
  const dispatch = useDispatch();
  

  
  useEffect(() => {
    if (ProductCode.length && SearchedProductCode === true) {
      const str_ = setTimeout(() => {
        dispatch(FindProductByCodeInStore(ProductCode));
        if (products.length < 1) {
          dispatch(ProductByCode(ProductCode));
        }
      }, 2000);
      return () => {
        clearTimeout(str_);
      };
    }
  }, [ProductCode, dispatch, SearchedProductCode, products,products.length]);










  useEffect(() => {
    if (ProductCode.length < 1 && SearchedProductCode === true) {
      if (category >= 1) {
        dispatch(categoryProducts(category));
      } else {
        dispatch(getProducts());
      }
    }
  }, [ProductCode.length, SearchedProductCode,category, dispatch]);

  
  const setFind = (e) => {
    dispatch(ActiveSearch());
    setProductCode(e);
  };

  return (
    <>
      <input style={{width:"150px"}}
        type="text" name="search"
        value={ProductCode}
        onChange={(e) => setFind(e.target.value)}
        placeholder="Search..."
      />
    </>
  );
}
