import React from "react";
import config from "../../lib/config";
function CatItem(props) {
  const { categories,dispatch,isLoading,  SelectToEdit,SelectToRemove } = props;
  const edit = (i) => {
    dispatch(SelectToEdit(i));
  };
  const RemoveCat = (i) => {
    dispatch(SelectToRemove(i));
  };


  const List =
    categories.length > 0 ? (
      categories.map((Item, index) => (
        <div className={`Cont_cat SEc_ TRnsion ${Item.checkedEdit?'isActiveEditable':''} ${Item.checkRemove?'isActiveDelete':''}`} key={Item._id}>
          <div
            className="Cont_cat_Cover"
            style={{
              backgroundImage: `url('${
                config.get("URLs.CatCover") + Item.CatImage
              }')`,
            }}
          ></div>
          <h1> {Item.CatName} </h1>
          <div className="Cont_cat_desc">
            <span> {Item.CatDescription} </span>
          </div>
          <div className="Cont_cat_Button">
            <div className="btn">
              <span role="img" aria-labelledby="love">
                👀
              </span>
              عرض المحتوى
            </div>
            <div className="btn TRnsion ">
              <span role="img" aria-labelledby="love">
                💝
              </span>
              جديد العروض
            </div>
            <div className="btn TRnsion ">
              <span role="img" aria-labelledby="love">
                💝
              </span>
              عرض التخفيضاتر
            </div>
            <div className="action-btn">
              <div className="btn" onClick={() => RemoveCat([Item,index])}>
                <i className="fas fa-trash-alt" ></i> حـذف
              </div>
              <div className="btn" onClick={() => edit([Item,index])}>
                <i className="fas fa-pencil-alt"></i>
                تحديث
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <></>
    );
  return <>
   {isLoading ? <div className="IsLoading"></div> : <>{List}</>}
  </>;
}

export default CatItem;
