import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
const lodash = require("lodash");

export const SyncInvoices_ = createAsyncThunk(
  "Invoices/this_m_inv_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const invoices = endpoint
        .get("invoices/this_m_inv_")
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncInvoicesByMonth_ = createAsyncThunk(
  "Invoices/SyncInvoicesByMonth_",
  async (SelectedMonth, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const invoices = endpoint
        .post(
          "invoices/by_the_month_inv_",
          JSON.stringify({
            _Month: SelectedMonth,
            UserId: parseInt(getState().auth.UserId),
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncInvoicesByDefDate_ = createAsyncThunk(
  "Invoices/SyncInvoicesByDefDate_",
  async (theStartEnd, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const invoices = endpoint
        .post(
          "invoices/by_def_date_inv_",
          JSON.stringify({
            start_date: theStartEnd[0],
            end_date: theStartEnd[1],
            UserId: parseInt(getState().auth.UserId),
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const findByClintName_ = createAsyncThunk(
  "Invoices/findByClintName_",
  async (SearchValue, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const invoices = endpoint
        .post(
          "invoices/find_clint_inv_",
          JSON.stringify({
            SearchValue,
            UserId: parseInt(getState().auth.UserId),
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrderWallet = createAsyncThunk(
  "Invoices/updateOrderWallet",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      endpoint
        .post("invoices/submit_in_wallet_", JSON.stringify(_))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncTopClintInv_ = createAsyncThunk(
  "Invoices/SyncTopClintInv_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const invoices = endpoint
        .post("invoices/last_max_inv_", {
          UserId: parseInt(getState().auth.UserInfo["UserId"]),
          Month: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncYearTopClintBuy_ = createAsyncThunk(
  "Invoices/SyncYearTopClintBuy_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const invoices = endpoint
        .post(
          "invoices/top_clint_buy_",
          JSON.stringify({ UsId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return invoices;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SwitchCase = createAsyncThunk(
  "Invoices/SwitchCase",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      endpoint
        .post("invoices/pay_status", JSON.stringify(_[0]))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SwitchUnLockCase = createAsyncThunk(
  "Invoices/SwitchUnLockCase",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Unlock = endpoint
        .post(
          "orders/un_lock_order_",
          JSON.stringify({ _, UsId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Unlock;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const d = new Date();

const initState = {
  isLoading: false,
  invoicesList: [],
  selectedInvoiceId: [],
  CloneInvoicesList: [],
  ClintList: [],
  SelectedInv_: [],
  TopClintInv_List: [],
  YearTopClintBuy: [],
  SelectedMonth: d.getMonth() + 1,
  M_totals: 0,
  IsEdit: false,
  error: null,
};

const InvoicesSlice = createSlice({
  name: "Invoices",
  initialState: initState,
  reducers: {
    setSelectedMonth: (state, action) => {
      state.SelectedMonth = action.payload;
    },
    setSelectedOrder: (state, action) => {
      state.SelectedInv_ = action.payload;
     },
     SelectInvoice:(state,action)=>{
      state.selectedInvoiceId=action.payload;
     },
    setSelectedClint: (state, action) => {
      state.ClintList = action.payload;
    },
    findOneClint: (state, action) => {
      state.invoicesList = state.CloneInvoicesList;
      const Res = state.invoicesList.filter((result) => {
        return result.Client_ID === state.ClintList;
      });
      if (Res) {
        state.invoicesList = Res;
      } else {
        state.invoicesList = state.CloneInvoicesList;
      }
    },
    FiltersByWallet: (state, action) => {
      state.invoicesList = state.CloneInvoicesList;
      const Res =
        state.invoicesList.length >= 1 &&
        state.invoicesList.filter((result) => {
          // eslint-disable-next-line
          return result.Wallet_ID == action.payload;
        });
      if (Res) {
        state.invoicesList = Res;
      } else {
        state.invoicesList = state.CloneInvoicesList;
      }
    },
    setSelectAll: (state, action) => {
      state.SelectAll = !state.SelectAll;
    },
    setEdit: (state, action) => {
      state.IsEdit = action.payload;
    },

    GetTotal_analysis: (state, action) => {
      if (state.M_totals.length >= 1) {
        state.M_totals = lodash.sum(
          state.invoicesList.map((Item) => {
            return parseInt(Item.Total_Invoice);
          })
        );
      }
    },
    updateOrderWallet: (state, action) => {
      // console.log(action.payload);
      action.payload[0].map((item) => {
        let obj = state.invoicesList.find((o) => o.Invoice_ID === item);
        if (obj) {
          obj.Wallet_ID = action.payload[1];
        }
        return null;
      });
      state.SelectAll = false;
      state.IsEdit = false;
    },
  },
  extraReducers: {
    [SwitchUnLockCase.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SwitchUnLockCase.fulfilled]: (state, action) => {
      // console.log(action.payload);
    },
    [SwitchUnLockCase.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [findByClintName_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [findByClintName_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload;
      state.CloneInvoicesList = action.payload;
      state.M_totals = lodash.sum(
        state.invoicesList.map((Item) => {
          return parseInt(Item.Total_Invoice);
        })
      );
    },
    [findByClintName_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoicesByDefDate_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoicesByDefDate_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload;
      state.CloneInvoicesList = action.payload;
      if(state.invoicesList.length>=1){
      state.M_totals = lodash.sum(
        state.invoicesList.map((Item) => {
          return parseInt(Item.Total_Invoice);
        })
      );
    }
    },
    [SyncInvoicesByDefDate_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncYearTopClintBuy_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncYearTopClintBuy_.fulfilled]: (state, action) => {
      state.YearTopClintBuy = action.payload;
    },
    [SyncYearTopClintBuy_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoicesByMonth_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoicesByMonth_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload;
      state.CloneInvoicesList = action.payload;
      if (state.invoicesList.length >= 1) {
        state.M_totals = lodash.sum(
          state.invoicesList.map((Item) => {
            return parseInt(Item.Total_Invoice);
          })
        );
      } else {
        state.M_totals = 0;
      }
    },
    [SyncInvoicesByMonth_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SwitchCase.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SwitchCase.fulfilled]: (state, action) => {
      let obj = state.invoicesList.find(
        (o) => o.Invoice_ID === action.payload[0].Invoice_ID
      );
      if (obj) {
        obj.Payment_Status = obj.Payment_Status === 1 ? 0 : 1;
      }
    },
    [SwitchCase.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncTopClintInv_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncTopClintInv_.fulfilled]: (state, action) => {
      state.TopClintInv_List = action.payload;
    },
    [SyncTopClintInv_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateOrderWallet.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateOrderWallet.fulfilled]: (state, action) => {
      action.payload[0].map((item) => {
        let obj = state.invoicesList.find((o) => o.Invoice_ID === item);
        if (obj) {
          obj.Wallet_ID = action.payload[1];
        }
        return null;
      });
      state.SelectAll = false;
      state.IsEdit = false;
    },
    [updateOrderWallet.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoices_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoices_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload;
      state.CloneInvoicesList = action.payload;
      state.invoicesList.forEach((element) => {
        element.Late_payments = parseInt(element.Late_payments);
        element.Payment_Status = parseInt(element.Payment_Status);
      });
      state.M_totals = lodash.sum(
        state.invoicesList.map((Item) => {
          return parseInt(Item.Total_Invoice);
        })
      );
    },
    [SyncInvoices_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  setEdit,
  GetTotal_analysis,
  setSelectedMonth,
  setSelectAll,
  FiltersByWallet,
  setSelectedClint,
  findOneClint,
  setSelectedOrder,SelectInvoice,
} = InvoicesSlice.actions;
export default InvoicesSlice.reducer;
