import React, { useEffect, useState } from "react";
  
export default function FindItem(props) {
  const { FindItemByCode ,dispatch} = props;
  const [ProductCode, setProductCode] = useState("");


  useEffect(() => {
    const str_ = setTimeout(() => {
      dispatch(FindItemByCode(ProductCode));
    }, 1000);
    return () => {
      clearTimeout(str_);
    };
  }, [ProductCode, dispatch]);

  const setFind = (e) => {
    // dispatch(ActiveSearch());
    setProductCode(e);
  };

  return (
    <>
      <input
        style={{ width: "150px" }}
        type="text"
        value={ProductCode}
        onChange={(e) => setFind(e.target.value)}
        placeholder="Search..."
      />
    </>
  );
}
