import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
export const SyncTAudience = createAsyncThunk(
  "audience/SyncTAudience",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const GroupList_ = endpoint
        .post(
          "audience/sync_audience_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Type: "private",
            LIMIT: 0,
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return GroupList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const SetActiveAudience = createAsyncThunk(
  "audience/SetActiveAudience",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("audience/active_audience_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          audience: _[0],
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const removeLabel = createAsyncThunk(
  "audience/removeLabel",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Label_ = endpoint
        .post("audience/remove_label", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Label: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Label_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const EditLabel = createAsyncThunk(
  "audience/EditLabel",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Label_ = endpoint
        .post("audience/edit_label", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Label: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Label_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncAudienceLabel = createAsyncThunk(
  "audience/SyncAudienceLabel",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post(
          "audience/sync_label_audience_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId) })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SetLabelAudience = createAsyncThunk(
  "audience/SetLabelAudience",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const LabelAudience_ = endpoint
        .post("audience/save_label", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Label_: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return LabelAudience_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//audience/find_
export const SearchAudience = createAsyncThunk(
  "audience/SearchAudience",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Audi_ = endpoint
        .post(
          "audience/find_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Type: "private",
            TF_: _,
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return Audi_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SetFlowerToLabelAudience = createAsyncThunk(
  "audience/SetFlowerToLabelAudience",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const LabelFlow_ = endpoint
        .post("audience/save_to_label_flow", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          LabFlow: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return LabelFlow_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncAudienceByLabel = createAsyncThunk(
  "audience/SyncAudienceByLabel",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post(
          "audience/sync_audience_by_label_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            LabelId: parseInt(_),
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeLFlow = createAsyncThunk(
  "audience/removeLFlow",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const LabelFlow_ = endpoint
        .post("audience/remove_flow_audi_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          LabFlowId: _[0],
          Audience: _[1],
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return LabelFlow_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  isEdit: false,
  isLoading: false,
  isEditLabel: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  Audience: [],
  Labels: [],
  LabelsSelect: [],
  ItemIds: [],
  selectedEdit: [],
  TotalAudience: 0,
  TotalSelect: 0,
};

const audienceSlice = createSlice({
  name: "audience",
  initialState: initialState,
  reducers: {
    SelectLabels: (state, action) => {
      if (state.isEditLabel === false) {
        state.LabelsSelect = [];
        let obj = state.Labels.find((o) => o.LID === action.payload.LID);
        if (obj) {
          obj.checked = !obj.checked; /* state.isEdit = !state.isEdit; */
        }
        let iSelect_ = state.Labels.filter((o) => o.checked === true);
        // state.TotalSelect = iSelect_.length;

        iSelect_.forEach((element) => {
          state.LabelsSelect.push(element.LID);
        });
      }
      state.SuccessHandling = false;
    },
    SelectAudience: (state, action) => {
      state.ItemIds = [];
      let obj = state.Audience.find((o) => o.Au_Id === action.payload.Au_Id);
      if (obj) {
        obj.checked = !obj.checked; /* state.isEdit = !state.isEdit; */
      }
      let iSelect_ = state.Audience.filter((o) => o.checked === true);
      state.TotalSelect = iSelect_.length;

      iSelect_.forEach((element) => {
        state.ItemIds.push(element.Au_Id);
      });
      state.SuccessHandling = false;
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.Audience.map((Item) => (Item.checked = !action.payload));
      let iSelect_ = state.Audience.filter((o) => o.checked === true);
      state.TotalSelect = iSelect_.length;
      // isSelect ? (state.isEdit = true) : (state.isEdit = false);
      state.ItemIds = [];
      iSelect_.forEach((element) => {
        state.ItemIds.push(element._id);
      });
      state.SuccessHandling = false;
    },
    FilterAudiItem: (state) => {
      //filter items in order by INorder true
      state.Audience.sort(function (x, y) {
        return x.checked === y.checked ? 0 : x ? -1 : 1;
      });
    },
    ONUpdate: (state) => {
      state.isEdit = false;
      state.ItemIds = [];
      // state.isEditLabel =false;
      state.LabelsSelect = [];
      state.selectedEdit = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
      state.Audience.map((Item) => (Item.checked = false));
    },
    SelectToEditLabel: (state, action) => {
      state.isEditLabel = true;
      state.selectedEdit = action.payload;
    },
  },
  //SyncAudienceByLabel
  extraReducers: {
    [SyncAudienceByLabel.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SyncAudienceByLabel.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ItemIds = [];
      state.Audience = action.payload;
    },
    [SyncAudienceByLabel.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [SetFlowerToLabelAudience.pending]: (state, action) => {
      state.isLoading = false;
    },
    [SetFlowerToLabelAudience.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SetFlowerToLabelAudience.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [removeLFlow.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removeLFlow.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [removeLFlow.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [removeLabel.pending]: (state, action) => {
      state.isLoading = false;
    },
    [removeLabel.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [removeLabel.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [SyncAudienceLabel.pending]: (state, action) => {
      state.isLoading = false;
    },
    [SyncAudienceLabel.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.Labels = action.payload;
    },
    [SyncAudienceLabel.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [SearchAudience.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SearchAudience.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.Audience = action.payload;
      state.TotalAudience = action.payload.length;
      state.ItemIds = [];
    },
    [SearchAudience.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncTAudience.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncTAudience.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.Audience = action.payload;
      state.TotalAudience = action.payload.length;
      state.ItemIds = [];
    },
    [SyncTAudience.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SetActiveAudience.pending]: (state, action) => {
      state.error = null;
    },
    [SetActiveAudience.fulfilled]: (state, action) => {
      state.error = null;
      state.Audience[action.payload[1]].active =
        !state.Audience[action.payload[1]].active;
    },
    [SetActiveAudience.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [SyncTAudience.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SetLabelAudience.pending]: (state, action) => {
      state.error = null;
    },
    [SetLabelAudience.fulfilled]: (state, action) => {
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SetLabelAudience.rejected]: (state, action) => {
      state.error = action.payload;
    },

    [EditLabel.pending]: (state, action) => {
      state.error = null;
    },
    [EditLabel.fulfilled]: (state, action) => {
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.isEditLabel = false;
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [EditLabel.rejected]: (state, action) => {
      state.error = action.payload;
    },

    //EditLabel
  },
});

export const {
  SelectToEditLabel,
  SelectLabels,
  SelectAudience,
  SelectAll,
  FilterAudiItem,
  ONUpdate,
} = audienceSlice.actions;
export default audienceSlice.reducer;
