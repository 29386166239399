import React from 'react'
import LayoutPages from './LayoutPages'
import TelegramAudienceIndex from '../components/TelegramAudience/TelegramAudienceIndex';
 function TelegramAudience() {
  return ( 
    <LayoutPages>
     <TelegramAudienceIndex/>
    </LayoutPages>
  
    )
}

export default TelegramAudience