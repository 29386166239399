import cache from "./cache";
 
class User {
  constructor() {
    this.userData = cache.get("user");
  }
  /**
   * check if user is logged in
   * @returns {boolean}
   */
  isLoggedIn() {
    return this.userData !== null;
  }
  /**
   * log the user in
   * it well store the data in the storage engine  i.e local storage
   * But well not make the ajax request
   *
   * @param {object} userData
   * @returns {void}
   */
  login(userData) {
    if (userData.UserId >= 1) {
      this.userData = userData;
      cache.set("user", userData);
      // redirectTo('/users');
    } 
  }
  logout() {
    cache.remove("user");
    cache.remove("SelectedOrder");
    cache.remove("CartItem");
  }

    /**
     * Get user access token
     * 
     * @returns {string}
     */
    
     accessToken(){
    return this.userData.AccessToken;
    //AccessToken
  }
}
export default new User();
