import React, { useEffect, useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  tension: 0.5,

  plugins: {
    legend: {},
    title: {
      display: false,
      text: "النمو السنوى هذا العام",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
  },
};

const labels = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "ينيو",
  "يليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export default function Chart_Ui(props) {
  const { ChartDataAnalysis } = props;
  const [DataChart, setDataChart] = useState([]);

  const data = {
    labels,
    datasets: DataChart,
  };
 
 useEffect(() => {
    setTimeout(() => {
      if (ChartDataAnalysis.datasets){
        setDataChart(ChartDataAnalysis.datasets.map((x)=>x));    
      }
    },100);
  },[ChartDataAnalysis,ChartDataAnalysis.datasets])

  return <Line options={options} data={data} />;
}
