import React ,{useEffect} from "react";
import Analytics from "../components/analytics/Analytics";
import LayoutPages from "./LayoutPages";
function Home() {
  useEffect(() => {
    setTimeout(() => {
       document.title =(`تحليلات النظام`);
     }, 100);
    });

  return (
    <LayoutPages>
      <Analytics />
    </LayoutPages>
  );
}

export default Home;
