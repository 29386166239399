import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select_AnswerItem,
  SelectAllAnswer,
  SYNC_Answer,
  EditQuestion,
  removeAnswer,
} from "../../../store/AQuestionSlice";

export default function ASQ_answer(props) {
  const { QLang } = props;
  const {
    isLoading,
    AnswerList,
    selectedQuestion,
    ItemIdsAnswer,
    SelAnswerCount,
  } = useSelector((state) => state.Question);
  const [IsSelected, setIsSelected] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SYNC_Answer());
  }, [dispatch]);

  useEffect(() => {
    // eslint-disable-next-line
    ItemIdsAnswer.length == 0
      ? setIsSelected(false)
      : setIsSelected(!IsSelected);
    // eslint-disable-next-line
  }, [ItemIdsAnswer]);

  const List = AnswerList ? (
    AnswerList.map((Item, index) => (
      <div
        key={index}
        className={`Button_ ANSWER ${
          selectedQuestion.Questions_Id === Item.Questions_Id
            ? "AnswerActive"
            : ""
        } `}
      >
        <div
          className="QuestValue"
          onClick={() => dispatch(EditQuestion(Item))}
        >
          <i className="icon far fa-comment"></i>{" "}
          <a href="#payload">
            {" "}
            <span
              className={`privPath ${
                selectedQuestion.Questions_Id === Item.Questions_Id
                  ? "ABtnActive"
                  : ""
              } `}
            >
              #إجابة
            </span>
          </a>{" "}
          {Item.Message}{" "}
        </div>
        <div className="QuestOption">
          <div
            className={`DLMsg_Selected Transition ${
              Item.checked ? "Selected" : ""
            }`}
            onClick={() => dispatch(Select_AnswerItem(Item))}
          ></div>
          <div
            className="clearItem Transition"
            onClick={() => dispatch(removeAnswer([Item.Questions_Id]))}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="Notfound" style={{ height: "300px!important" }}></div>
  );

  const handleSelectAll = () => {
    dispatch(SelectAllAnswer(IsSelected));
  };

  const handleRemoveAll = () => {
    dispatch(removeAnswer(ItemIdsAnswer));
  };

  return (
    <div className="ASQ_Quest">
      <div className="toolbar">
        <div className="optionButton">
          <div
            className={`Button-custom Transition Check-select ${
              IsSelected ? "Selected" : ""
            }`}
            onClick={() => handleSelectAll()}
          ></div>
          <div className="Button-custom">{SelAnswerCount.length}</div>
          {ItemIdsAnswer.length >= 1 && (
            <div
              className="Button-custom   Transition"
              onClick={() => handleRemoveAll()}
              title="Delete Selected"
            >
              <i className="fa  fa-trash"></i>
            </div>
          )}
        </div>
        <div className="SectionTitle">
          {" "}
          <h4>
            {" "}
            {` { ${AnswerList.length >= 1 ? AnswerList.length : 0} } `}{" "}
            {QLang.boxAnswer_title}{" "}
          </h4>{" "}
        </div>
      </div>
      <div className="answer" id="transparent-scroll">
        {isLoading ? <div className="IsLoading"></div> : List}
      </div>
    </div>
  );
}
