import React from "react";
import "../../../../assets/css/Wall/components/Posts/Posts.css";
function Posts(props) {
  const {
    dispatch,
    SelectSize,
    Like,
    Wall_Posts,
    AddToWallCart,
    isLoading,
    navigate,
    URL_,
    URL_profiles,
  } = props;

  const handleProfile = (PID) => {
    navigate(`/${PID}`);
  };
  const handleAddToCart = (Item) => {
    dispatch(AddToWallCart(Item));
  };
  const List =
    Wall_Posts.length >= 1 ? (
      Wall_Posts.map((Item, index) => (
        <div className="container-post  Transition" key={index}>
          <div
            className="imgPost Transition"
            style={{ backgroundImage: `url('${URL_ + Item.imgUrl}')` }}
          >
            <div className="buyAction">
              <h2>{Math.trunc(parseInt(Item.price))}$ </h2>
              <div className="productSize">
                <SelectSize sizes={Item.Size.length >= 1 ? Item.Size : []} />
              </div>
            </div>
            {Item.user.Subscribe_ >= 1 && (
              <div className="buyAction">
                <div
                  className="ADtoCrt Transition"
                  onClick={() => handleAddToCart(Item)}
                  title=""
                >
                  <i className="fa fa-shopping-cart "></i>
                </div>
              </div>
            )}
          </div>
          <div className="ActionPost">
            <div
              className="user_"
              onClick={() => handleProfile(Item.user.PageUser)}
            >
              <div
                className="UserActionButton"
                style={{
                  backgroundImage: `url('${URL_profiles + Item.user.Image}')`,
                }}
              ></div>
              <span className="uN_"> {Item.user.Username} </span>
            </div>
            <div className={`Quantity ${Item.Quantity >= 1 ? "ActivQty" : ""}`}>
              {/* <span>{cartItem?cartItem:''}</span> */}
              <span>{Item.Quantity ? Item.Quantity : ""}</span>

              <i className="fa fa-shopping-bag "></i>
              {/* <span> Cart </span> */}
            </div>

            <Like
              ProductId={Item.id}
              LikeCount={Item.LikeCount}
              IsLike={Item.user.IsLike}
            />
          </div>
          <div className="content">
            <h3> {Item.title} </h3>
            <span className="title"> {Item.description} </span>
          </div>
        </div>
      ))
    ) : (
      <div className="Notfound"></div>
    );
  return <>{isLoading ? <div className="IsLoading"></div> : <>{List}</>}</>;
}

export default Posts;
