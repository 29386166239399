import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchAudience,
  SyncTAudience,
  SelectAll,
  FilterAudiItem,
  removeLFlow,
  SyncAudienceByLabel,
} from "../../../store/audienceSlice";
import Search from "./Search";

function ToolBar(props) {
  const { TotalAudience, ItemIds, isEditLabel, selectedEdit } = useSelector(
    (state) => state.audience
  );
  const [IsSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };
  const handleRemoveFlowers = () => {
    dispatch(removeLFlow([selectedEdit.LID, ItemIds]));
    dispatch(SyncAudienceByLabel(selectedEdit.LID));
  };
  return (
    <>
      <div className="NavAud">
        <div className="row">
          <div className="p-title">
            <h1>
              <i className="fa fa-user-tag"></i> الجمهور{" "}
              {`( ${TotalAudience} ) `}
            </h1>
          </div>
          <Search
            dispatch={dispatch}
            SearchAudience={SearchAudience}
            SyncTAudience={SyncTAudience}
          />

          <div className="NavButton">
            <div
              onClick={() => handleSelectAll()}
              className={`AtoCalc BTN_ Transition Check-Audience ${
                IsSelected ? "Checked-true" : ""
              }`}
            ></div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(SyncTAudience())}
            >
              {" "}
              <i className="fa fa-sync-alt "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(FilterAudiItem())}
            >
              <i className="fa fa-sort "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              style={{ width: "fit-content" }}
            >
              {" "}
              {ItemIds.length}
            </div>
            {isEditLabel && ItemIds.length >= 1 && (
              <>
                <div
                  className="AtoCalc Transition BTN_"
                  onClick={() => handleRemoveFlowers()}
                >
                  {" "}
                  <i className="fa fa-trash "></i>
                </div>
                {/* <div className="AtoCalc Transition BTN_">     <i className="fa fa-anchor "></i></div> */}
              </>
            )}
            {ItemIds.length >= 1 && (
              <>
                <div className="AtoCalc Transition BTN_">
                  {" "}
                  <i className="fa fa-lock "></i>
                </div>
              </>
            )}
            <div className="AtoCalc Transition BTN_">
              {" "}
              <i className="fa fa-bullhorn "></i>
            </div>

            {/*     <div className="AtoCalc Transition BTN_">     <i className="fa fa-clipboard "></i></div>
                  <div className="AtoCalc Transition BTN_">     <i className="fa fa-print "></i></div> */}
          </div>
        </div>
        <hr className="style16" />
      </div>
    </>
  );
}

export default ToolBar;
