import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncByCity,
  SyncDebt,
} from "../../store/debtSlice";
import NewDebtForm from "./NewDebtForm";
import "../../assets/css/Debt/Debt.css";
import DebtListTable from "./DebtListTable";
import ClintCityItem from "./ClintCityItem";
const DebtIndex = () => {
  const { isLoading, DebtList } = useSelector(
    (state) => state.debt
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncDebt());
    dispatch(SyncByCity());
  }, [dispatch]);

   useEffect(() => {
    setTimeout(() => {
       document.title =(`   الديون - (DEbt) `);
     }, 100);
    });

  return (
    <div className="row">
      <div className="left-saidbar">
        <NewDebtForm />
        <ClintCityItem
                 DebtList={DebtList}
        />
      </div>
      <DebtListTable
        dispatch={dispatch}
        DebtList={DebtList}
        isLoading={isLoading}
        SyncDebt={SyncDebt}
      />
    </div>
  );
};
export default DebtIndex;
