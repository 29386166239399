import React, { useEffect } from "react";
import NewSupplierForm from "./NewSupplierForm";
import "../../assets/css/Suppliers/Suppliers.css";
import SupplierTable from "./SupplierTable";

const SupplierIndex = () => {
  useEffect(() => {
    setTimeout(() => {
      document.title = `الموردين - (Supplier) `;
    }, 100);
  });
  return (
    <div className="row">
      <div className="left-saidbar">
        <NewSupplierForm />
      </div>
      <SupplierTable />
    </div>
  );
};
export default SupplierIndex;
