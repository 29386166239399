import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, NTrained,isEditItem,ClearSelectedItem ,
  setDefaultImg,RemoveImg_,ActiveProductModal,isCopyItem} from "../../../store/productsSlice";
import { AddToList } from "../../../store/selectedSlice";
import { AddItemToCart } from "../../../store/cartSlice";
import {  SwAlert, SwToast } from "../../../lib/SwAlert";

import "../../../assets/css/PointCashier/Products/productModal.css";
import ListItem from "./ListItem";
const Products = (props) => {
  const { products, SearchedProductCode, isLoading, PriceInfo } = useSelector((state) => state.product);
  const {    IsSelectOrder  } = useSelector((state) => state.Orders);
  const { Zoom } = props;
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getProducts(24));
  }, [dispatch]);

  useEffect(() => {
    if (products&&products.length <=0 && SearchedProductCode === false) {
      dispatch(getProducts(24));
    }
            // eslint-disable-next-line
  }, [SearchedProductCode===false&&products.length===0,dispatch]);
  

  return (
    <Fragment>
      <div className="products-wrapper">
        <ListItem Zoom={Zoom}
        SwToast={SwToast}
        SwAlert={SwAlert}
        IsSelectOrder={IsSelectOrder}
          AddItemToCart={AddItemToCart}
          ItemProduct={products}
          isLoading={isLoading}
          onChange={AddToList}
          NTrained={NTrained}
          setDefaultImg={setDefaultImg}
          RemoveImg_={RemoveImg_}
          ActiveProductModal={ActiveProductModal}
          dispatch={dispatch}
          PriceInfo={PriceInfo}
          isEditItem={isEditItem}
          isCopyItem={isCopyItem}
          ClearSelectedItem={ClearSelectedItem}
        />
      </div>
    </Fragment>
  );
};
export default Products;
