import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderToolBar from "./HToolBar/HeaderToolBar";
import Products from "./Products/Products";
import FormUpdateProducts from "./FormEdit/FormUpdateProducts";
import EditProduct from "./FormEdit/EditProduct";
import Invoice from "./Invoice/Invoice";
import FillterBySubliers from "./FilterBySuppliers/FilterBySuppliers";
import PublishCampaign from "./PublishCampaign/PublishCampaign";
import ItemCamp from "./PublishCampaign/ItemCampaign";
import { words } from "../../words";
import "../../assets/css/PointCashier/PointCashier.css";

function CashierIndex() {
  const { EditProductList, EditItem, ShowInvoice, NewCampaign, ItemCampaign,FilterSupplier } =    useSelector((state) => state.product);
  const LNG_HToolBar = words.lang.ar.cashier.HToolBar;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [Zoom,setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!Zoom);
  }
  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    // console.log(scrollPosition);

  return (
    <>
      <HeaderToolBar handleZoom={handleZoom} words={LNG_HToolBar} scrollPosition={scrollPosition} />

      <div className="wrapper">
        <Products Zoom={Zoom} />
        <div className="wrapper-right-side" id="transparent-scroll">
          {EditItem && <EditProduct />}
          {EditProductList && <FormUpdateProducts />}
          {ShowInvoice && <Invoice />}
          {NewCampaign && <PublishCampaign />}
          {ItemCampaign && <ItemCamp />}
          {FilterSupplier && <FillterBySubliers />}
        </div>
      </div>
    </>
  );
}

export default CashierIndex;
