import React from 'react'
import LayoutPages from './LayoutPages'
import PrintInvoiceIndex from '../components/PrintInvoice_/PrintInvoiceIndex';
function PrintInvoice_() {
  return ( 
    <LayoutPages LeftSidebarHide={true} HeaderActive ={true}     FooterHide ={true} MainBodyHide={true} >
     <PrintInvoiceIndex/>
    </LayoutPages>
  
    )
}

export default PrintInvoice_