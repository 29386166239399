import React, { useEffect ,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "../../assets/css/PrintInvoice_/PrintInvoiceIndex.css";
import logoinv from "../../../src/assets/images/logoprint.png"; // Tell Webpack this JS file uses this image
import TableRow from "./components/TableRow";
import { SyncInvoice_Item, SetInvoiceId } from "../../store/CatalogSlice";
const itemx=<i className="fas fa-angle-left"></i>;
export default function PrintInvoiceIndex() {
  const { inv_info } = useSelector((state) => state.catalog);
  const dispatch = useDispatch();
  const match = { params: useParams() };
  const [ShowLogo, setShowLogo] = useState(false);
  useEffect(() => {
    let ID = match.params.id;
    if (ID >= 1 ) {
      dispatch(SetInvoiceId(ID));
      dispatch(SyncInvoice_Item(ID));

    }
  }, [dispatch, match.params.id]);


    useEffect(() => {
      var Intv = setInterval(() => {
        if(inv_info.OrderInfo){
          document.title =(`${inv_info.OrderInfo.Client_Name} - ${inv_info.OrderInfo.Total_Invoice}  ج.م ${inv_info.OrderInfo.Inv_Create}`);
        }
        }, 100);
       return () => clearInterval(Intv);
      });

      useEffect(() => {
        setTimeout(() => {
          handlePrint();
        }, 2000);
        
      }, []);
      

    const handlePrint = () => {
      window.print();

   };

  return (
        <div className="main-print-invoice">
       {ShowLogo && <img src={logoinv} className="imgHeader" alt="" />}
        <div className="clerfx"></div>
        {inv_info.OrderInfo ? (<>
        <div className="row">
        <div className="col-lg-5">
        {/* <div className="marketer_s"> {itemx} <span> المسوق :     {inv_info.OrderInfo.Marketer_Name} </span>    </div> */}
        <div className="marketer_s"> {itemx} <span>رقم البوليصة :{inv_info.OrderInfo.ShippingPolicy_No} </span>  </div>
        <div className="marketer_s">        {itemx}<span>  رقم الفاتورة : {inv_info.OrderInfo.Invoice_No} </span>         </div>
        <div className="marketer_s">        {itemx}<span>  تاريخ : {inv_info.OrderInfo.Inv_Create}</span>        </div>

        </div>
        <div className="Client_ col-lg-7">
        <span> اسم العميل :  {inv_info.OrderInfo.Client_Name}  </span>
        <div className="marketer_s"> {itemx} <span>   محافظة :  {inv_info.OrderInfo.City}  </span>  </div>
        <div className="marketer_s"> {itemx} <span>  العنوان :  {inv_info.OrderInfo.First_Address}</span>  </div>
        <div className="marketer_s"> {itemx} <span>    هاتف  :  {inv_info.OrderInfo.Client_phone}: {inv_info.OrderInfo.Client_phone_2}   </span> </div>
        </div>
        </div>
      </>) : (<></>)}
      <div className="clerfx"></div>
      <table border="1" width="93%" className="tbor">
        <thead>
          <tr>
            <th width="5"> ##</th>
            <th width="85"> كود المنتج</th>
            <th width="350"> اسم المنتج</th>
            <th width="55"> كمية</th>
            <th width="55"> السعر</th>
            <th width="55"> اجمالى</th>
          </tr>
        </thead>
        <tbody>
          {inv_info.OrderDetails ? inv_info.OrderDetails.map(row => <TableRow key={row[0]} row={row} />) : <></>}
        </tbody>
      </table>
      {inv_info.OrderInfo ? (<>
      
        <div className="FooterInvoice">
<div className="priv"  >
            <h2  >
                يسمح باستبدال عيوب الصناعة  من البضاعة المباعة بهذه الفاتورة
                بشرط عدم حدوث اي تغيير فى حالتها
                <strong>           خلال 10 ايام  </strong>              من تاريخ الاستلام .
                <br/>    <div > تنبيه هام : فقد قيمة الضمان فى حالة عدم الاستيلام </div>
            </h2>
        </div>
        <div className="total"> 
<h1 >   {inv_info.OrderInfo.Total_}   جم  </h1>  
{parseInt(inv_info.OrderInfo.Total_PCS)>=1? <div className="inv-mony" > <span>   عدد   : {inv_info.OrderInfo.Total_PCS} دستة </span>         </div>:<></>}         
{parseInt(inv_info.OrderInfo.Buy_Deposit)>=1? <div className="inv-mony" > <span> ضمان / عربون   : {inv_info.OrderInfo.Buy_Deposit} ج.م </span>         </div>:<></>}         
{parseInt(inv_info.OrderInfo.ShippingCost)>=1? <div className="inv-mony" >   <span> تكلفة الشحن    : {inv_info.OrderInfo.ShippingCost} ج.م </span>         </div>:<></>}     
{parseInt(inv_info.OrderInfo.Late_payments)>=1? <div className="inv-mony" >   <span> متاخر سداد   : {inv_info.OrderInfo.Late_payments} ج.م </span>         </div>:<></>}     
{parseInt(inv_info.OrderInfo.Reactionary)>=1? <div className="inv-mony" > <span> خصم مرتجع   : {inv_info.OrderInfo.Reactionary} ج.م </span>         </div> :<></>}         
{parseInt(inv_info.OrderInfo.Discount)>=1? <div className="inv-mony" > <span> خصم ع الفاتورة   : {inv_info.OrderInfo.Discount} ج.م </span>         </div>   :<></>}     
{parseInt(inv_info.OrderInfo.Transfer_expenses)>=1? <div className="inv-mony" > <span> مصاريف   فودافون    : {inv_info.OrderInfo.Transfer_expenses} ج.م </span>         </div>   :<></>}     
<h1 > {String(inv_info.OrderInfo.Total_Invoice).replace(/(.)(?=(\d{3})+$)/g, '$1,')}  جم  </h1> </div>
</div>
      </>
      ):(<></>)}
 </div>
  );
}
