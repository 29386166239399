import React from 'react'
import LayoutPages from './LayoutPages'
import SuppliersIndex from '../components/Suppliers/SuppliersIndex';
function Suppliers() {
  return ( 
    <LayoutPages>
     <SuppliersIndex/>
    </LayoutPages>
      )
}

export default Suppliers