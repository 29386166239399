import { configureStore } from "@reduxjs/toolkit";
import auth from "./authSlice";
import product from "./productsSlice";
import select from "./selectedSlice";
import cart from "./cartSlice";
import Quantity from "./QuantitySlice";
import clint from "./clintSlice";
import category from "./CategoriesSlice";
import Orders from "./OrderSlice";
import analysis from "./analysisSlice";
import catalog from "./CatalogSlice";
import clipboard from "./ClipboardSlice";
import NotInOrders from "./NotInOrdersSlice";
import Invoices from "./InvoicesSlice";
import Wallet from "./WalletSlice";
import page from "./PageSlice";
import Telegram from "./TelegramSlice";
import audience from "./audienceSlice";
import debt from "./debtSlice";
import publish_campaign from "./PublishCampaignSlice";
import Question from "./AQuestionSlice";
import supplier from "./supplierSlice";
import bot_sting from "./BotStingsSlice";
const store = configureStore({
  reducer: {
    Question,
    auth,
    page,
    analysis,
    product,
    select,
    cart,
    Quantity,
    category,
    clint,
    Orders,
    catalog,
    clipboard,
    NotInOrders,
    Invoices,
    Wallet,
    Telegram,
    audience,
    publish_campaign,
    debt,
    supplier,bot_sting
  },
});
export default store;
