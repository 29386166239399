import React from "react";
import { useSelector } from "react-redux";
import config from "../../lib/config";

export default function TopCatItem() {

  
  
  return <div className="head-btn">
    
    <div   className="tab-btn TRnsion ">  <span style={{ border: "transparent" }} title="عنوان الزر"> ازرار القوالب   </span> </div>
    <div   className="tab-btn TRnsion ">  <span style={{ border: "transparent" }} title="عنوان الزر"> ازرار القائمة   </span> </div>
    <div   className="tab-btn TRnsion ">  <span style={{ border: "transparent" }} title="عنوان الزر"> قائمة الاسئلة   </span> </div>
       

  </div>;
}
