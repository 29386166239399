import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const SwAlert = async (title, message,timerProgressBar=true,timer=2000) => {
  let timerInterval;
  const result_2 = await Swal.fire({
    title: `${title}`,
    html: `${message}`,
    timer: timer,
    timerProgressBar: timerProgressBar,
    didOpen: () => {
      Swal.showLoading();
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  });
  /* Read more about handling dismissals below */
  if (result_2.dismiss === Swal.DismissReason.timer) {
  }
};

export const SwToast = (icon, message) => {
  return Toast.fire({
    icon: `${icon}`,
    title: `${message}`,
  });
};
