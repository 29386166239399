import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetTotal_analysis,
  SyncTopClintInv_,
  setSelectedClint,
  findOneClint,
} from "../../../store/InvoicesSlice";
import { SyncOrders_, GetTotal_OpenOrder } from "../../../store/OrderSlice";
import { AnalysisWallet_ } from "../../../store/WalletSlice";

function AnalysisSidBar() {
  const { invoicesList, M_totals, TopClintInv_List, SelectedMonth } =
    useSelector((state) => state.Invoices);
  const { Total_Invoice } = useSelector((state) => state.Orders);
  const { wallets } = useSelector((state) => state.Wallet);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTotal_analysis());
    dispatch(GetTotal_OpenOrder());
  }, [dispatch, invoicesList]);

  useEffect(() => {
    dispatch(SyncOrders_());
    dispatch(AnalysisWallet_());
    dispatch(SyncTopClintInv_(SelectedMonth));
  }, [dispatch, SelectedMonth]);
  const handleByClint = (ClintId) => {
    dispatch(setSelectedClint(ClintId));
    dispatch(findOneClint());
    dispatch(AnalysisWallet_());
  };
  const handleImg = (W) => {
    let img_;
    switch (parseInt(W)) {
      case 1:
        img_ = "/images/Bmisr.png";
        break;
      case 2:
        img_ = "/images/alex.jpg";
        break;
      case 3:
        img_ = "/images/Egypost.png";
        break;
      case 4:
        img_ = "/images/Bmisr.png";
        break;
      case 5:
        img_ = "/images/Vcash.png";
        break;
      case 6:
        img_ = "/images/Vcash.png";
        break;
      case 7:
        img_ = "/images/orange_wallet.png";
        break;
      default:
    }
    return img_;
  };
  const wallet_L =
    wallets.length > 1 ? (
      wallets.map((Item, index) =>
        Item.Wallet_value >= 1 ? (
          <div key={index + "E"}>
            <img
              onClick={(e) => false}
              height="20"
              src={handleImg(Item.Wallet_ID)}
              title={Item.Wallet_Name}
              alt={""}
            />
            <span>{Item.Wallet_value}</span>{" "}
          </div>
        ) : (
          ""
        )
      )
    ) : (
      <> </>
    );

  const ClintINv =
    TopClintInv_List.length >= 1 ? (
      TopClintInv_List.map((Item, index) => (
        <div
          key={Item.Client_ID}
          className="TopClint"
          onClick={(e) => handleByClint(Item.Client_ID)}
        >
          <div className={"userImg TRnsion"}></div>
          <span className="QV">{Item.total_order}</span>
          <span className={"City"}>{Item.Address.City}</span>
          <span>{Item.Client_Name}</span>
        </div>
      ))
    ) : (
      <> </>
    );

  return (
    <div className="Inv-wrapper-right-side">
      <div className="Content-Analysis">
        <h3>مبيعات الشهر الحالى </h3>
        <h1>{String(M_totals).replace(/(.)(?=(\d{3})+$)/g, "$1,")} L.E </h1>
        <div className="wallet">{wallet_L}</div>
      </div>
      <div className={"Content-Analysis"} style={{ height: "120px" }}>
        <h3> مبيعات مقيدة </h3>
        <h1 className="mQ">
          {String(Total_Invoice).replace(/(.)(?=(\d{3})+$)/g, "$1,")} L.E{" "}
        </h1>
      </div>

      <div className="Content-Analysis">
        <h3> الاعلى سحب هذا الشهر </h3>
        <hr />
        {ClintINv}
      </div>
    </div>
  );
}

export default AnalysisSidBar;
