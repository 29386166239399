import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {SyncSuppliers,SaveSuppliers_,ONUpdate} from "../../store/supplierSlice";
import {SyncTelegramGroups} from "../../store/TelegramSlice";

import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../lib/SwAlert";

const SupplierLang = {
  supplier_name: "اسم المورد",
  contact_name: "اسم جهة الاتصال",
  key_Code: "كود المورد",
  address: "العنوان",
  city: "المدينة",
  postal_code: "الرمز البريدي",
  country: "البلد",
  phone: "رقم الهاتف",
  Telegram: "تليجرام المورد",
  save_button: "حفظ / تحديث"
};

export default function NewSupplierForm() {
  const {  SelectedSuppler,isEdit,ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg  } = useSelector((state) => state.supplier);
  const { GroupsList } = useSelector((state) => state.Telegram);

  const dispatch = useDispatch();

  useEffect(() => {
    // Placeholder action to sync suppliers list
    dispatch(SyncSuppliers());
  dispatch(SyncTelegramGroups());
  }, [dispatch]);
 

    const validationSchema = Yup.object({
                      SupplierID: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
                      Au_Id: Yup.number().required("Supplier group is required").typeError("The value must be a number").integer("The value must be a number"),
                      supplier_name: Yup.string().required("Supplier name is required").max(100, "Supplier name can't be longer than 100 characters"),
                      key_Code: Yup.string().required("Supplier Code is required").max(6, "Key code can't be longer than 6 characters"),
                      contact_name: Yup.string().required("Supplier contact name is required").max(100, "Contact name can't be longer than 100 characters"),
                      address: Yup.string().max(255, "Address can't be longer than 255 characters"),
                      city: Yup.string().max(100, "City can't be longer than 100 characters"),
                      postal_code: Yup.string().max(20, "Postal code can't be longer than 20 characters"),
                      country: Yup.string().required("Country is required").max(100, "Country can't be longer than 100 characters"),
                      phone: Yup.string().required("Supplier Phone is required").max(50, "Phone can't be longer than 50 characters"),
                       });

  const formik = useFormik({
    initialValues: {Au_Id:"",SupplierID: 0,supplier_name: '',key_Code: '',contact_name: '',address: '',city: '',postal_code: '',country: '',phone: ''    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(SaveSuppliers_(JSON.stringify(values, null, 2)));
    },
  });
  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    if (isEdit && SelectedSuppler) {
      formik.setValues({
        SupplierID: SelectedSuppler.SupplierID,
        Au_Id: SelectedSuppler.Au_Id,
        supplier_name: SelectedSuppler.supplier_name,
        key_Code: SelectedSuppler.key_Code,
        contact_name: SelectedSuppler.contact_name,
        address: SelectedSuppler.address,
        city: SelectedSuppler.city,
        postal_code: SelectedSuppler.postal_code,
        country: SelectedSuppler.country,
        phone: SelectedSuppler.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, SelectedSuppler]);



  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Au_Id) SwToast("warning", `Supplier Group : ${FMKEr.Au_Id}`);
      if (FMKEr.supplier_name) SwToast("warning", `Supplier Name: ${FMKEr.supplier_name}`);
      if (FMKEr.contact_name) SwToast("warning", `Contact Name: ${FMKEr.contact_name}`);
      if (FMKEr.key_Code) SwToast("warning", `Key Code: ${FMKEr.key_Code}`);
      if (FMKEr.address) SwToast("warning", `Address: ${FMKEr.address}`);
      if (FMKEr.city) SwToast("warning", `City: ${FMKEr.city}`);
      if (FMKEr.postal_code) SwToast("warning", `Postal Code: ${FMKEr.postal_code}`);
      if (FMKEr.country) SwToast("warning", `Country: ${FMKEr.country}`);
      if (FMKEr.phone) SwToast("warning", `Phone: ${FMKEr.phone}`);
     }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling) {
      SwToast("success", `💚 ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm();
      dispatch(SyncSuppliers());
    }
    if (ErrorHandling) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      // dispatch(ONUpdate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg, dispatch]);
   const List =
   GroupsList.length > 0 ? (
     GroupsList.map((Item, index) => (
       <option key={parseInt(index) + 10000} value={Item.Au_Id}>
         {`${Item.first_name} ${Item.last_name}`}
       </option>
     ))
   ) : (
     <></>
   );

  return ( <div className="NewSuppliers card full-height">
  <h2>مورد جديد</h2>
  <div className="box-body">
  
  <form onSubmit={formik.handleSubmit}>

  <div className="form-group">
    <label htmlFor="Au_Id">{SupplierLang.Telegram}</label>
    <select
      id="Au_Id"
      name="Au_Id"
      className={`input list ${FMKEr.Au_Id ? "inputError" : "inputSuccess"}`}
      title={SupplierLang.Telegram}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.Au_Id}
    >
      <option key={0} value="0"> حدد مجموعة تلجرام </option>
      {List}
    </select>
  </div>

  <div className="form-group">
    <label htmlFor="supplier_name">{SupplierLang.supplier_name}</label>
    <input autoComplete="off"
      id="supplier_name"
      name="supplier_name"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.supplier_name}
      className={`input ${FMKEr.supplier_name ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.supplier_name}
    />
  </div>

  <div className="form-group">
    <label htmlFor="contact_name">{SupplierLang.contact_name}</label>
    <input autoComplete="off"
      id="contact_name"
      name="contact_name"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.contact_name}
      className={`input ${FMKEr.contact_name ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.contact_name}
    />
  </div>

  <div className="form-group">
    <label htmlFor="key_Code">{SupplierLang.key_Code}</label>
    <input autoComplete="off"
      id="key_Code"
      name="key_Code"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.key_Code}
      className={`input ${FMKEr.key_Code ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.key_Code}
    />
  </div>

  <div className="form-group">
    <label htmlFor="address">{SupplierLang.address}</label>
    <input autoComplete="off"
      id="address"
      name="address"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.address}
      className={`input ${FMKEr.address ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.address}
    />
  </div>

  <div className="form-group">
    <label htmlFor="city">{SupplierLang.city}</label>
    <input autoComplete="off"
      id="city"
      name="city"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.city}
      className={`input ${FMKEr.city ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.city}
    />
  </div>

  <div className="form-group">
    <label htmlFor="postal_code">{SupplierLang.postal_code}</label>
    <input autoComplete="off"
      id="postal_code"
      name="postal_code"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.postal_code}
      className={`input ${FMKEr.postal_code ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.postal_code}
    />
  </div>

  <div className="form-group">
    <label htmlFor="country">{SupplierLang.country}</label>
    <input autoComplete="off"
      id="country"
      name="country"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.country}
      className={`input ${FMKEr.country ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.country}
    />
  </div>

  <div className="form-group">
    <label htmlFor="phone">{SupplierLang.phone}</label>
    <input autoComplete="off"
      id="phone"
      name="phone"
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={FMkv.phone}
      className={`input ${FMKEr.phone ? "inputError" : "inputSuccess"}`}
      placeholder={SupplierLang.phone}
    />
  </div>

  <div className="box-footer sButon">
    <input className="btn-success" type="submit" value={SupplierLang.save_button} />
  </div>
</form>

  </div>
</div>
  );
}
