import React from 'react'
import LayoutPages from './LayoutPages'
import WallIndex from './../components/Wall/WallIndex';
function Products() {
  return ( 
    <LayoutPages>
     <WallIndex/>
    </LayoutPages>
  
    )
}
//Wall_Index
export default Products