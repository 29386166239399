import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RemoveEditSelect,
  SelectedItem,
  updatePrice,
  SaveEditSelect,
  SelectAll,
  DeleteSelectedItem,
} from "../../../store/selectedSlice";
import config from "../../../lib/config";
import { SwToast } from "../../../lib/SwAlert";

import "../../../assets/css/PointCashier/FormEdit/FormUpdateProducts.css";
function FormUpdateProducts() {
  const { isLoading, selectedList, IsUpList, isCheck, SaveEdit } = useSelector(
    (state) => state.select
  );
  const [IsSelected, setIsSelected] = useState(false);
  const [Operator, setOperator] = useState("plus");
  const dispatch = useDispatch();

  useEffect(() => {
    if (SaveEdit === true) {
      dispatch(SaveEditSelect(IsUpList));
      SwToast("success", "successfully update Products ");
      setIsSelected(false);
    }
  }, [SaveEdit, IsUpList, dispatch]);

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };
  const handleDeleteSelectAll = () => {
    setIsSelected(false);
    dispatch(DeleteSelectedItem(selectedList));
  };
  const handleOperator = (o) => {
    setOperator(o);
  };

  const handleUpdateProducts = (o) => {
    dispatch(updatePrice(Operator));
    setOperator("plus");
  };

  return (
    <div className="FormUpdate">
      <div className="FU_tools">
        <h2>
          تحديث المنتجات
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>{" "}
        </h2>
        {selectedList.length >= 1 && (
          <div className="Edit">
            <div
              className={`Button-custom Transition Check-inOrder ${
                IsSelected ? "Checked-true" : ""
              }`}
              onClick={() => handleSelectAll()}
            >
              {" "}
            </div>
            {isCheck && (
              <div
                className="Button-custom   Transition"
                onClick={() => handleDeleteSelectAll()}
                title="Delete Selected"
              >
                {" "}
                <i className="fa  fa-trash"></i>{" "}
              </div>
            )}
            {isCheck && (
              <div
                className={`Button-custom   Transition ${
                  Operator === "minus" ? "Active" : ""
                }`}
                onClick={() => handleOperator("minus")}
                title="Delete Selected"
              >
                {" "}
                <i className="fa  fa-minus"></i>{" "}
              </div>
            )}
            {isCheck && (
              <div
                className={`Button-custom   Transition ${
                  Operator === "plus" ? "Active" : ""
                }`}
                onClick={() => handleOperator("plus")}
                title="Delete Selected"
              >
                {" "}
                <i className="fa  fa-plus"></i>{" "}
              </div>
            )}
          </div>
        )}
      </div>
      <hr className="style16" />
      <div className="cart-items" id="transparent-scroll">
        {isLoading ? (
          <div className="IsLoading"></div>
        ) : (
          Array.isArray(selectedList) &&
          selectedList.map(
            (
              Item,
              index //INorder
            ) => (
              <div
                className={`item  ${Item.INorder ? "INorder" : ""} Transition ${
                  Item.checked ? "item-selected" : ""
                } `}
                key={index}
                onClick={() => dispatch(SelectedItem(Item))}
              >
                <div
                  className="productImg"
                  style={{
                    backgroundImage: `url('${
                      config.get("URLs.product_ImgSmThumbnails") + Item.imgUrl
                    }')`,
                  }}
                ></div>
                <div className="item-description">
                  <div>
                    {" "}
                    <strong> $ </strong>{" "}
                    <span className="span-price"> {parseInt(Item.price)} </span>{" "}
                    -<strong className="span-Q">#{Item.Product_Code} </strong>{" "}
                  </div>
                  <div className="title">{Item.title}</div>
                </div>{" "}
                <div
                  className="clearItem Transition"
                  onClick={() => dispatch(RemoveEditSelect([Item, index]))}
                >
                  <i className="fa fa-edit"></i>
                </div>
                <div
                  className="clearItem Transition"
                  onClick={() => dispatch(RemoveEditSelect([Item, index]))}
                >
                  <i className="fa fa-times"></i>
                </div>
              </div>
            )
          )
        )}
      </div>
      {isCheck && (
        <button
          type="button"
          onClick={() => handleUpdateProducts()}
          className="btn-success btnOpenOrder"
          value="1"
        >
          {" "}
          تحديث المنتجات
        </button>
      )}
    </div>
  );
}
export default FormUpdateProducts;
