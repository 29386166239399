import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Cover from "./components/Cover/Cover";
import ImageUpload from "./components/ImageUpload";
import ProductForm from "./components/ProductForm";
import Categories from "./components/Categories";
import Posts from "./components/Posts/Posts";
import SelectSize from "./components/Posts/SelectSize";
import Like from "./components/Posts/Like";
import "../../assets/css/Wall/WallIndex.css";
import { getCategories, Select } from "../../store/CategoriesSlice";
import {
  SyncWall_Posts,
  AddToWallCart,
  SyncPage,
  subscribePage_,
  Upload_Cover_Image,
  save_Cover_Image,setActive as getActive
} from "../../store/PageSlice";
import config from "../../lib/config";

function WallIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [NewProduct, setNewProduct] = useState(false);
  const { categories, selectedCategory } = useSelector(
    (state) => state.category
  );
  const { Wall_Posts, isLoading, PageInfo, CoverLoading, CoverImgName } =
    useSelector((state) => state.page);
  const { isLoggedIn, UserId ,ActivePage} = useSelector((state) => state.auth);

  const URL_ = config.get("URLs.product_ImgThumbnails");
  const URL_profiles = config.get("URLs.PImage");
  useEffect(() => {
    setTimeout(() => {
      document.title = `🛒 ${PageInfo.pageTitle}`;
    }, 160);
  });

  const match = { params: useParams() };
  let ID = match.params.id;

  useEffect(() => {
    let ID = match.params.id;
    if (!ID) {
      if (isLoggedIn) {
        // dispatch(SyncPage(UserId));
        dispatch(getActive(ActivePage));
      }
    } else {
      dispatch(SyncPage(ID));
    }
  }, [UserId, dispatch,ActivePage, isLoggedIn, match.params.id]);



  useEffect(() => {
    setTimeout(() => {
      if (PageInfo.errors) {
        navigate("/NotFound");
      }
    }, 2000);
  }, [PageInfo, navigate]);

  useEffect(() => {
    if (Wall_Posts.length <= 0 && selectedCategory.length <= 0) {
      // let ID = match.params.id;
      dispatch(SyncWall_Posts([[], ID]));
    }
  }, [dispatch, Wall_Posts.length, selectedCategory, ID]);

  useEffect(() => {
    // let ID = match.params.id;
    if (selectedCategory.length >= 1) {
      dispatch(
        SyncWall_Posts([selectedCategory.map((Item, index) => Item._id), ID])
      );
    } else {
      dispatch(SyncWall_Posts([[], ID]));
    }
  }, [dispatch, selectedCategory, ID]);

  useEffect(() => {
    dispatch(getCategories("active"));
  }, [ID, dispatch]);

  return (
    <>
      <Cover
        isLoggedIn={isLoggedIn}
        UserId={UserId}
        CoverLoading={CoverLoading}
        PageInfo={PageInfo}
        CoverImgName={CoverImgName}
        subscribePage_={subscribePage_}
        Upload_Cover_Image={Upload_Cover_Image}
        save_Cover_Image={save_Cover_Image}
      />
      <div className="wrapper-product">
        <Categories
          categories={categories}
          Select={Select}
          dispatch={dispatch}
        />
        <div className="WallPosts">
          <div
            className={`Box  conte-center  ${
              NewProduct === false ? "custom-container " : ""
            }`}
          >
            <ImageUpload NewProduct={NewProduct} />
            <ProductForm
              selectedCategory={selectedCategory}
              PageID={ID}
              NewProduct={NewProduct}
              setNewProduct={setNewProduct}
            />
          </div>

          <Posts
            Wall_Posts={Wall_Posts}
            Like={Like}
            SelectSize={SelectSize}
            AddToWallCart={AddToWallCart}
            URL_={URL_}
            URL_profiles={URL_profiles}
            isLoading={isLoading}
            navigate={navigate}
            dispatch={dispatch}
          />
        </div>

        <div className="Box  conte-right-side">R-side Bar</div>
      </div>
    </>
  );
}

export default WallIndex;
