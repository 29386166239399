import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QuantityButtons from "./QuantityButtons";
import Categories from "./Categories";
import Filter from "../Filter/Filter";
import Clint from "./Clint";
import Orders from "../Orders/Orders";
import {
  SyncOrders_,
  push_catalog_,
  SendInvoice_,
  ONUpdate,
} from "../../../store/OrderSlice";

import { ADD_SelectAll, ClearSel } from "../../../store/selectedSlice";
import {
  switchPrice,
  NewCampaign,
  SelectAll,
  HideProducts,
  ShowAllHide,
  switchToEdit,
  NTrained,
  getTrained_,
  NewProduct,
  ShowUnUsed,
  FilterVisible,
  RemoveProducts,
  FilterBySupplier,
} from "../../../store/productsSlice";
import FindProduct from "./FindProduct";
import { SwAlert, SwToast } from "../../../lib/SwAlert";
import Swal from "sweetalert2";

import "../../../assets/css/PointCashier/HToolBar/HeaderToolBar.css";
import config from "../../../lib/config";

function HeaderToolBar(props) {
  const { scrollPosition, handleZoom } = props;
  const { selectedList } = useSelector((state) => state.select);
  const { CartList } = useSelector((state) => state.cart);
  const [Visible, setVisible] = useState(true);
  const { total, products, category, EditProductList, unUsed } = useSelector(
    (state) => state.product
  );
  const {
    IsSelectOrder,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Orders);
  const [IsChecked, setIsChecked] = useState(false);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));
  const dispatch = useDispatch();
  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
    dispatch(ONUpdate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg]);

  useEffect(() => {
    dispatch(SyncOrders_());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ADD_SelectAll(products));
  }, [IsChecked, dispatch, products]);

  const handleSelectAll = () => {
    setIsChecked(!IsChecked);
    dispatch(SelectAll(IsChecked));
    if (IsChecked) {
      dispatch(ClearSel());
    }
  };

  const handleHideProducts = (visual) => {
    if (selectedList.length >= 1) {
      dispatch(
        HideProducts({
          item: selectedList.map((Item, index) => Item.id),
          states: visual,
        })
      );
      setTimeout(() => {
        dispatch(ClearSel());
      }, 150);
    } else {
      SwToast(
        "info",
        !visual
          ? `😡 حدد احد المنتجات المخفة   .\n  لكى يتم تفعيل ظهورها `
          : " `😡 حدد احد المنتجات    .\n  ليتم الغاء ظهورها ` "
      );
    }
    setIsChecked(false);
  };
  const handleNewTrained = () => {
    if (selectedList.length >= 1) {
      dispatch(
        NTrained({
          listIDs: selectedList.map((Item, index) => [
            Item.id,
            Item.Trained >= 1 ? 0 : 1,
          ]),
        })
      );
      setTimeout(() => {
        dispatch(ClearSel());
      }, 150);
      setIsChecked(false);
      audio.pause();
      audio.play().onended = function () {
        this.currentSrc = null;
        this.src = "";
        this.srcObject = null;
        this.remove();
      };
    } else {
      SwToast(
        "info",
        `😡 Select Item add new trained .\n try Sync all trained`
      );
      dispatch(getTrained_());
    }
  };

  const handleShowAllHide = () => {
    if (parseInt(category) >= 1) {
      dispatch(ShowAllHide());
    } else {
      SwAlert("تنبيه هام ", "حدد فئة لاظهار المنتجات المخفية  ");
    }
  };

  const handleVisible = () => {
    setVisible(!Visible);
    dispatch(FilterVisible(Visible ? 1 : 0));
  };

  const handleRemoveProducts = (visual) => {
    if (selectedList.length >= 1) {
      Swal.fire({
        title: "تحذير هام ?",
        text: "هل انت متأكد من حذف المنتجات !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "إلغاء",
        confirmButtonText: "نعم حذف !",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            RemoveProducts({ item: selectedList.map((Item, index) => Item.id) })
          );
          setTimeout(() => {
            dispatch(ClearSel());
          }, 150);

          Swal.fire("Deleted!", "تم حذف المنتجات نهئياً.", "success");
        } else {
          Swal.fire("تم الغاء حذف المنتجات", "", "info");
        }
      });
    } else {
      SwToast("info", `😡 حدد احد المنتجات    .\n  لكى يتم حذفها نهائيا `);
    }
    setIsChecked(false);
  };

  return (
    <>
      <div className="fixedContainer">
        <div
          className={`NavControls Transition ${
            scrollPosition >= 15 ? "cus_Container" : ""
          } `}
        >
          <div className="Nav-options">
            <div
              className="AtoCalc Transition BTN_"
              rel=""
              onClick={() => handleZoom()}
              title={"تكبير / تصغير"}
            >
              <i className="fas fa-search-plus"></i>
            </div>

            {unUsed && selectedList.length >= 1 && (
              <div
                className="AtoCalc Transition BTN_"
                style={{ color: "rgb(252 23 57 / 92%)" }}
                title="الحذف النهائى للمنتجات المحددة "
                onClick={() => handleRemoveProducts()}
              >
                <i className="fa fa-trash "></i>
              </div>
            )}
            <div
              className="AtoCalc Transition BTN_"
              title="فلترة الغير مُباع "
              onClick={() => dispatch(ShowUnUsed())}
            >
              <i className="fa fa-filter "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title="عرض المخفى"
              onClick={() => handleShowAllHide()}
            >
              <i className="fa fa-fill-drip "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title="اعادة ترتيب"
              onClick={() => handleVisible()}
            >
              <i className="fa fa-sort-amount-down "></i>
            </div>

            <Filter />
            <Categories />
            <div
              style={{ border: "1px dashed rgb(53 170 243)" }}
              className={`AtoCalc Transition BTN_ Transition Check-inOrder ${
                IsChecked ? "Checked-true" : ""
              }`}
              onClick={() => handleSelectAll()}
            ></div>
            <div
              className="AtoCalc Transition BTN_"
              style={{ color: "rgb(252 23 57 / 92%)" }}
              title="New trained"
              onClick={() => handleNewTrained()}
            >
              <i className="fas fa-spider"></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title="Edit Select products"
              onClick={() => dispatch(switchToEdit(!EditProductList))}
            >
              <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title="إخفاء المحدد"
              onClick={() => handleHideProducts(1)}
            >
              <i className="fa fa-eye-slash "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title="عرض المحدد"
              onClick={() => handleHideProducts(0)}
            >
              <i className="fa fa-eye "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(switchPrice())}
            >
              <i className="fa fa-ticket-alt "></i>
            </div>
            <QuantityButtons />

            <Orders scrollPosition={scrollPosition} />
            <Clint scrollPosition={scrollPosition} />
            <div
              className="AtoCalc Transition BTN_"
              id="TotalSelProducts"
              title="منتج"
            >
              {total}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              id="TotalSelProducts"
              title="منتج محدد "
            >
              {selectedList.length}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              id="TotalSelProducts"
              title="السلة"
            >
              {CartList.length}
            </div>
            {IsSelectOrder.Invoice_ID ? (
              <>
                <div className="AtoCalc Transition BTN_">
                  <Link
                    to={`/print_/${IsSelectOrder.Invoice_ID}`}
                    target="_blank"
                    style={{ color: "#23aba5" }}
                  >
                    <i className="fa fa-print "></i>
                  </Link>
                </div>
                <div
                  className="AtoCalc Transition BTN_"
                  title="ارسال الكتالوج"
                  onClick={() =>
                    dispatch(push_catalog_(IsSelectOrder.Invoice_ID))
                  }
                >
                  <i className="fas fa-paper-plane"></i>
                </div>
                <div
                  className="AtoCalc Transition BTN_"
                  title="ارسال الفاتورة"
                  onClick={() =>
                    dispatch(SendInvoice_(IsSelectOrder.Invoice_ID))
                  }
                >
                  <i className="far fa-paper-plane"></i>
                </div>
              </>
            ) : (
              <></>
            )}

            <FindProduct />
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(NewProduct())}
              title="منتج جديد"
            >
              <i className="fa fa-file "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(NewCampaign())}
              title="حملة جديدة"
            >
              <i className="fa fa-bullhorn "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(FilterBySupplier())}
              title="فلترة منتجات موردين"
            >
              <i className="fas fa-binoculars "></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HeaderToolBar;
