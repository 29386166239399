import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SYNC_Question,
  SelectedItem,
  SelectAll,
  removeQuestions,
  EditQuestion,
} from "../../../store/AQuestionSlice";

export default function ASQ_Quest(props) {
  const { QLang } = props;
  const { ItemIds, selectedQuestion, QuestionList, SelCount, isLoading } =
    useSelector((state) => state.Question);
  const [IsSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SYNC_Question());
  }, [dispatch]);

  useEffect(() => {
    // eslint-disable-next-line
    ItemIds.length == 0 ? setIsSelected(false) : setIsSelected(!IsSelected);
    // eslint-disable-next-line
  }, [ItemIds]);

  const handleSelectAll = () => {
    dispatch(SelectAll(IsSelected));
  };

  const handleRemoveAll = () => {
    dispatch(removeQuestions(ItemIds));
  };

  const List = QuestionList ? (
    QuestionList.map((Item, index) => (
      <div
        key={index}
        className={`Button_ ${
          selectedQuestion.Questions_Id === Item.Questions_Id ? "Active" : ""
        } `}
      >
        <div
          className="QuestValue"
          onClick={() => dispatch(EditQuestion(Item))}
        >
          <i className="icon far fa-comment"></i>{" "}
          <a href="#payload">
            {" "}
            <span
              className={`privPath ${
                selectedQuestion.Questions_Id === Item.Questions_Id
                  ? "pActive"
                  : ""
              } `}
            >
              #Text {Item.RepeatCount}
            </span>
          </a>{" "}
          {Item.Message}{" "}
        </div>
        <div className="QuestOption">
          <div
            className={`DLMsg_Selected Transition ${
              Item.checked ? "Selected" : ""
            }`}
            onClick={() => dispatch(SelectedItem(Item))}
          ></div>
          <div
            className="clearItem Transition"
            onClick={() => dispatch(removeQuestions([Item.Questions_Id]))}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="Notfound" style={{ height: "300px!important;" }}></div>
  );

  return (
    <div className="ASQ_Quest">
      <div className="toolbar">
        <div className="optionButton">
          <div
            className={`Button-custom Transition Check-select ${
              IsSelected ? "Selected" : ""
            }`}
            onClick={() => handleSelectAll()}
          ></div>
          <div className="Button-custom">{SelCount.length}</div>
          {ItemIds.length >= 1 && (
            <div
              className="Button-custom   Transition"
              onClick={() => handleRemoveAll()}
              title="Delete Selected"
            >
              <i className="fa  fa-trash"></i>
            </div>
          )}
        </div>
        <div className="SectionTitle">
          {" "}
          <h4>
            {" "}
            {` { ${QuestionList.length >= 1 ? QuestionList.length : 0} } `}{" "}
            {QLang.boxQuestion_title}{" "}
          </h4>{" "}
        </div>
      </div>
      <div className="answer" id="transparent-scroll">
        {isLoading ? <div className="IsLoading"></div> : List}
      </div>
    </div>
  );
}
