import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Save_product_, ONUpdate } from "../../../store/productsSlice";
import { SyncWall_Posts } from "../../../store/PageSlice";
import { unSelect } from "../../../store/CategoriesSlice";
import cache from "../../../lib/cache"; //local storage library
import { SwToast } from "../../../lib/SwAlert";

function ProductForm(props) {
  const { NewProduct, setNewProduct, PageID } = props;
  const {
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    DefaultImage,
  } = useSelector((state) => state.product);
  const { selectedCategory } = useSelector((state) => state.category);

  const Cash = cache.get("ProductItems");
  const [productName, setProductName] = useState(Cash ? Cash.productName : "");
  const [productDescription, setProductDescription] = useState(
    Cash ? Cash.productDescription : ""
  );
  const [productCode, setProductCode] = useState(Cash ? Cash.productCode : "");
  const [SellPrice, setSellPrice] = useState(Cash ? Cash.SellPrice : "");
  const [BuyPrice, setBuyPrice] = useState(Cash ? Cash.BuyPrice : "");
  const [Category, setCategory] = useState(Cash ? Cash.Category : "");
  const [IsSelectCategory, setIsSelectCategory] = useState(
    Cash ? Cash.IsSelectCategory : ""
  );
  const [SelSize, setSelected] = useState(Cash ? Cash.SelSize : []);
  const [SaveFormData, setSaveFormData] = useState(
    Cash ? Cash.SaveFormData : false
  );
  const dispatch = useDispatch();

  const SizNumber = [36, 38, 40, 42, 44, 46, 48, 50, 52];
  const SizXL = ["S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];
  useEffect(() => {
    if (SaveFormData) {
      let ProductItems = {
        PageID: PageID,
        productName,
        productDescription,
        BuyPrice,
        SellPrice,
        productCode,
        DefaultImage,
        Category,
        SelSize,
        selectedCategory,
        IsSelectCategory,
        SaveFormData,
      };
      cache.set("ProductItems", ProductItems); //save to local storage Product  item
    }
  }, [
    PageID,
    DefaultImage,
    SellPrice,
    BuyPrice,
    productName,
    productDescription,
    Category,
    SelSize,
    selectedCategory,
    productCode,
    SaveFormData,
    IsSelectCategory,
  ]);
  useEffect(() => {
    setIsSelectCategory(selectedCategory.map((Item, index) => Item._id));
  }, [selectedCategory]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", `❤️ ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      //  dispatch(        SyncWall_Posts( [selectedCategory.map((Item, index) => Item._id),24])      );
      dispatch(
        SyncWall_Posts([selectedCategory.map((Item, index) => Item._id)])
      );
      if (!SaveFormData) {
        cache.remove("ProductItems"); //save to local storage cart item
        setProductName("");
        setProductDescription(null);
        setProductCode("");
        setSellPrice("");
        setBuyPrice("");
        setCategory("");
        setIsSelectCategory([]);
        setSelected([]);
        dispatch(unSelect());
      }
      setProductCode("");
    }

    if (ErrorHandling === true) {
      if (ErrorHandlingMsg) {
        SwToast("error", `😡 ${ErrorHandlingMsg}`);
        dispatch(ONUpdate()); //  setShippingPolicy_No("");
      }
    }
  }, [
    ErrorHandling,
    selectedCategory,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
    SaveFormData,
  ]);

  const HashTag =
    selectedCategory.length > 0 ? (
      selectedCategory.map((Item, index) => (
        <div
          className={`Hash  ${Category === Item._id ? "custom-Hash" : ""} `}
          key={Item._id}
          onClick={(e) => setCategory(Item._id)}
        >
          #{Item.CatName.trim().replace(" ", "_")}
        </div>
      ))
    ) : (
      <></>
    );

  const Clean = (ar, TypeSize) => {
    if (TypeSize === "Num") {
      SizXL.map((Item) => {
        const index = ar.indexOf(Item);
        if (index > -1) {
          // only splice array when item is found
          ar.splice(index, 1); // 2nd parameter means remove one item only
        }
        return null; //
      });
    } else {
      SizNumber.map((Item) => {
        const index = ar.indexOf(Item);
        if (index > -1) {
          // only splice array when item is found
          ar.splice(index, 1); // 2nd parameter means remove one item only
        }
        return null; //
      });
    }
  };
  const handleSaveForm = () => {
    setSaveFormData(!SaveFormData);
  };

  const handleSave_product_ = () => {
    dispatch(
      Save_product_({
        PageID: PageID,
        productName,
        productDescription,
        BuyPrice,
        SellPrice,
        productCode,
        DefaultImage,
        Category,
        SelSize,
        IsSelectCategory,
      })
    );
  };

  const handleClick = (SizBy, TypeSize) => {
    let newSelected = [];
    const selectedIndex = SelSize.indexOf(SizBy);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(SelSize, SizBy);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(SelSize.slice(1));
    } else if (selectedIndex === SelSize.length - 1) {
      newSelected = newSelected.concat(SelSize.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        SelSize.slice(0, selectedIndex),
        SelSize.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    Clean(newSelected, TypeSize);
  };

  const isSelected = (SizBy) => SelSize.indexOf(SizBy) !== -1;

  const SizeNUM = SizNumber.map((Item, index) => (
    <div
      key={index}
      className={`S_NUM ${isSelected(Item) ? "Size-checked" : ""}`}
      onClick={(e) => handleClick(Item, "Num")}
    >
      {Item}
    </div>
  ));
  const SizByXL = SizXL.map((Item, index) => (
    <div
      key={index}
      className={`S_NUM ${isSelected(Item) ? "Size-checked" : ""}`}
      onClick={(e) => handleClick(Item, "letters")}
    >
      {Item}
    </div>
  ));
  // console.log(Cash.SaveFormData);

  return (
    <div
      className={`ProductForm  ${NewProduct === false ? "CustomForm" : ""} `}
    >
      {/* <h3>إضافة منتج جديد . </h3> */}
      <div>
        <input
          onClick={() => setNewProduct(true)}
          type="text"
          onChange={(e) => setProductName(e.target.value)}
          value={productName}
          className="productName"
          placeholder="اكتب اسم المنتج  .... "
          name="productName"
          autoComplete="off"
        />
        <input type="hidden" value={DefaultImage} name="DefaultImage" />
      </div>
      <div className="ProductPrice">
        <input
          type="text"
          onChange={(e) => setSellPrice(e.target.value)}
          value={SellPrice}
          title="سعر البيع "
          placeholder="بيع   .... "
        />
        <input
          type="text"
          onChange={(e) => setBuyPrice(e.target.value)}
          value={BuyPrice}
          title="شراء"
          placeholder="شراء  .... "
        />
        <input
          type="text"
          onChange={(e) => setProductCode(e.target.value)}
          value={productCode}
          title="كود المنتج"
          style={{ width: "30%", textAlign: "left", paddingLeft: "10px" }}
          placeholder="كود المنتج   .... "
        />
      </div>
      <div className="productSize">{SizeNUM}</div>
      <div className="productSize">{SizByXL}</div>
      <div className="product_description">
        <textarea
          placeholder="وصف مٌختصر للمنتج ... "
          maxLength={150}
          onChange={(e) => setProductDescription(e.target.value)}
          defaultValue={productDescription}
        />
      </div>
      {selectedCategory.length > 0 && (
        <div className="Hashtag">
          <div
            className="times-remov Transition"
            onClick={() => dispatch(unSelect())}
          >
            <i className="fa fa-times "></i>
          </div>
          {HashTag}
        </div>
      )}

      <div className="formAction">
        <div
          className={`savform  ${SaveFormData ? "Checked-true" : ""}`}
          onClick={() => handleSaveForm()}
        ></div>
        <button
          type="button"
          className="btn-success"
          onClick={() => handleSave_product_()}
        >
          نشر المنتج
        </button>
      </div>
    </div>
  );
}

export default ProductForm;
