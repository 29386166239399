import React  from "react";
 import config from "../../../lib/config";
 
function Pages(props) {
  const { handleGoHome ,PList} = props;
   

   
  return (
    <>
      {PList &&
        PList.map((item, index) => (
          <div
            key={index}
            className={`item Transition ${
              // eslint-disable-next-line
              item.checked == true ? "active" : ""
            }`}
            onClick={() => handleGoHome(item.PageUser, item, index)} >
            <div className="userIMG" style={{   backgroundImage: `url('${  config.get("URLs.Cover_Thumbnails") + item.Cover      }')`,       }} ></div>
            <div>
              <span> {item.PageUser}</span>
              <span> {item.pageTitle}</span>
            </div>
          </div>
        ))}
    </>
  );
}

export default Pages;
