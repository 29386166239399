import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncYearTopClintBuy_ } from "../../../store/InvoicesSlice";
import { words } from "../../../words";

function TopClint() {
  const { YearTopClintBuy } = useSelector((state) => state.Invoices);
  const LNG = words.lang.ar.home;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncYearTopClintBuy_());
  }, [dispatch]);

  const ClintINv =
    YearTopClintBuy.length >= 1 ? (
      YearTopClintBuy.map((Item, index) => (
        <div key={Item.Client_ID} className="TopClint" onClick={(e) => ""}>
          <div className={"userImg TRnsion"}></div>
          <span className="QV">{Item.total_order}</span>
          <span className={"City"}>{Item.Address.City}</span>
          <span>{Item.Client_Name}</span>
        </div>
      ))
    ) : (
      <> </>
    );

  return (
    <div className="card full-height MainTopClint ">
      <h3> {LNG.top_clint_title} </h3>
      <hr />
      <div className="ListClint">{ClintINv}</div>
    </div>
  );
}

export default TopClint;
