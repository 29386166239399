import { Obj } from "@mongez/reinforcements";
let configurationsList = {};
const config = {
  /**
   * set the given key/value in our configurations list
   * @param {string} key
   * @param {any} value
   * @returns void
   */
  set(key, value) {
    //case one  argument only is passed and is object
    if (arguments.length === 1) {
      let data = key;
      configurationsList = Object.assign(configurationsList, data);
    } else {
      configurationsList[key] = value;
    }
  },
  /**
   * Get the value for the given key, otherwise return  the given value
   * p.s data will be grabbed using dot notation
   * @param {string} key
   * @param {any} defaultValue
   * @returns any
   */
  get(key, defaultValue = null) {
    return Obj.get(configurationsList, key, defaultValue);
  },
};
export default config;
