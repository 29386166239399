import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncWallet_,SwitchDefaultWallet } from "../../../store/WalletSlice";
 function WalletList() {
  const { wallets } = useSelector((state) => state.Wallet);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(SyncWallet_());
  }, [dispatch]);

  
   const List =
    wallets.length>0?  wallets.map((Item, index) => (
          <option key={Item._id} value={Item.Wallet_ID}>
            {Item.Wallet_Name}
          </option>
           )) : (
      <></>
    );
  return (
    <>
      <select name="Wallet" className="round" title=" الفئة " defaultValue={0} onChange={(e) =>dispatch(SwitchDefaultWallet(e.target.value))}>
      <option     value="0"> حدد المحفظة </option>
        {List}
      </select>
    </>
  );
}
export default WalletList;
