import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push_catalog_, SendInvoice_ } from "../../../../store/OrderSlice";
import {
  setSelectedMonth,
  updateOrderWallet,
  setSelectAll,
  findOneClint,
  SyncInvoicesByMonth_,
  SyncInvoicesByDefDate_,
  findByClintName_,
  SwitchUnLockCase,
} from "../../../../store/InvoicesSlice";
import { AnalysisWallet_ } from "../../../../store/WalletSlice";
import WalletList from "../../components/WalletList";
import "../../../../assets/css/InvoiceOrders/components/ToolBar/HeaderToolBar.css";
import Swal from "sweetalert2";

function HeaderToolBar() {
  const Month_ = new Date().getMonth();
  var todayDate = new Date().toISOString().slice(0, 10);
  const { DefaultWallet } = useSelector((state) => state.Wallet);
  const {
    SelectedInv_,
    invoicesList,
    IsEdit,
    SelectAll,
    SelectedMonth,
    selectedInvoiceId,
  } = useSelector((state) => state.Invoices);
  const [start_date, setStart_date] = useState(todayDate);
  const [end_date, setEnd_date] = useState(todayDate);
  const [NamePhone, setNamePhone] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AnalysisWallet_());
  }, [dispatch, invoicesList]);

  const handleUpdate = () => {
    if (parseInt(DefaultWallet) !== 0) {
      if (SelectedInv_.length >= 1) {
        dispatch(updateOrderWallet([SelectedInv_, DefaultWallet]));
      } else {
        let timerInterval;
        Swal.fire({
          title: "تنبيه!",
          html: "حدد فاتورة او اكثر اولا  !",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            // console.log('I was closed by the timer')
          }
        });
      }
    } else {
      let timerInterval;
      Swal.fire({
        title: "تنبيه المحفظة",
        html: "حدد محفظة اولا  !",
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          // console.log('I was closed by the timer')
        }
      });
    }
  };
  const handleSelectAll = () => dispatch(setSelectAll());
  const handleFind = () => dispatch(findOneClint());

  const SetMonth = (e) => {
    dispatch(setSelectedMonth(e));
    dispatch(SyncInvoicesByMonth_(e));
  };

  const handleDefDate = () => {
    dispatch(SyncInvoicesByDefDate_([start_date, end_date]));
  };

  useEffect(() => {
    if (NamePhone.length >= 3) {
      const st_ = setTimeout(() => {
        dispatch(findByClintName_(NamePhone));
      }, 350);
      return () => {
        clearTimeout(st_);
      };
    } else {
      //  if( NamePhone.length<=1)dispatch(SyncClint_Data());
    }
  }, [NamePhone, dispatch]);

  return (
    <div className="NavControls ">
      <div className="Nav-options">
        <div
          style={{ border: "1px dashed rgb(53 170 243)" }}
          className={`AtoCalc Transition BTN_ Transition Check-inOrder ${
            SelectAll ? "Checked-true" : ""
          }`}
          onClick={() => handleSelectAll()}
        >
          {" "}
        </div>

        <input
          type="text"
          onChange={(e) => setNamePhone(e.target.value)}
          placeholder="بـحـث عن .. "
          className="SearchClintInvoices"
          value={NamePhone}
        />
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleDefDate()}
          style={{ color: "#ff5722" }}
          title=""
        >
          {" "}
          <i className="fa fa-search "></i>{" "}
        </div>
        <input
          className="dateCalender"
          type="date"
          defaultValue={start_date}
          onChange={(e) => setStart_date(e.target.value)}
        />
        <input
          className="dateCalender"
          type="date"
          defaultValue={end_date}
          onChange={(e) => setEnd_date(e.target.value)}
        />
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title="عدد الاوردر"
        >
          {invoicesList.length}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title=" محدد "
        >
          {" "}
          {IsEdit ? SelectedInv_.length : 0}
        </div>
        {selectedInvoiceId >= 1 ? (
          <>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(SwitchUnLockCase(selectedInvoiceId))}
            >
              {" "}
              <i className="fa fa-unlock "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(SendInvoice_(selectedInvoiceId))}
            >
              {" "}
              <i className="far fa-paper-plane "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              onClick={() => dispatch(push_catalog_(selectedInvoiceId))}
            >
              {" "}
              <i className="fas fa-paper-plane "></i>{" "}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="AtoCalc Transition BTN_">
          {" "}
          <Link to={"/print_/" + selectedInvoiceId} target="_blank">
            <i className="fa fa-print" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="AtoCalc Transition BTN_">
          {" "}
          <Link to={"/View_/" + selectedInvoiceId} target="_blank">
            <i className="fa fa-eye eyeView"></i>
          </Link>
        </div>

        {Month_ >= 0 || Month_ === 0 ? (
          <button
            type="button"
            onClick={() => SetMonth(1)}
            className={SelectedMonth === 1 ? `btn-red` : "btn-success"}
            value=""
          >
            1{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 1 || Month_ === 1 ? (
          <button
            type="button"
            onClick={() => SetMonth(2)}
            className={SelectedMonth === 2 ? `btn-red` : "btn-success"}
            value=""
          >
            2{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 2 || Month_ === 2 ? (
          <button
            type="button"
            onClick={() => SetMonth(3)}
            className={SelectedMonth === 3 ? `btn-red` : "btn-success"}
            value=""
          >
            3{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 3 || Month_ === 3 ? (
          <button
            type="button"
            onClick={() => SetMonth(4)}
            className={SelectedMonth === 4 ? `btn-red` : "btn-success"}
            value=""
          >
            4{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 4 || Month_ === 4 ? (
          <button
            type="button"
            onClick={() => SetMonth(5)}
            className={SelectedMonth === 5 ? `btn-red` : "btn-success"}
            value=""
          >
            5{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 5 || Month_ === 5 ? (
          <button
            type="button"
            onClick={() => SetMonth(6)}
            className={SelectedMonth === 6 ? `btn-red` : "btn-success"}
            value=""
          >
            6{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 6 || Month_ === 6 ? (
          <button
            type="button"
            onClick={() => SetMonth(7)}
            className={SelectedMonth === 7 ? `btn-red` : "btn-success"}
            value=""
          >
            7{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 7 || Month_ === 7 ? (
          <button
            type="button"
            onClick={() => SetMonth(8)}
            className={SelectedMonth === 8 ? `btn-red` : "btn-success"}
            value=""
          >
            8{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 8 || Month_ === 8 ? (
          <button
            type="button"
            onClick={() => SetMonth(9)}
            className={SelectedMonth === 9 ? `btn-red` : "btn-success"}
            value=""
          >
            9{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 9 || Month_ === 9 ? (
          <button
            type="button"
            onClick={() => SetMonth(10)}
            className={SelectedMonth === 10 ? `btn-red` : "btn-success"}
            value=""
          >
            10{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 10 || Month_ === 10 ? (
          <button
            type="button"
            onClick={() => SetMonth(11)}
            className={SelectedMonth === 11 ? `btn-red` : "btn-success"}
            value=""
          >
            11{" "}
          </button>
        ) : (
          <></>
        )}
        {Month_ >= 11 || Month_ === 11 ? (
          <button
            type="button"
            onClick={() => SetMonth(12)}
            className={SelectedMonth === 12 ? `btn-red` : "btn-success"}
            value=""
          >
            12{" "}
          </button>
        ) : (
          <></>
        )}

        <div
          className="AtoCalc Transition BTN_"
          onClick={() => dispatch(SyncInvoicesByMonth_())}
        >
          {" "}
          <i className="fa fa-sync "></i>{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => dispatch(handleFind(SelectedMonth))}
        >
          {" "}
          <i className="fa fa-microscope "></i>{" "}
        </div>

        <div
          className="AtoCalc Transition BTN_"
          title="تحديث المحفظة"
          onClick={(e) => handleUpdate()}
        >
          <i className="fa fa-wallet "></i>
        </div>

        <WalletList />
      </div>
    </div>
  );
}
export default HeaderToolBar;
