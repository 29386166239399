import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../../../lib/config";
import { SwToast } from "../../../../lib/SwAlert";
import BGup from "../../../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image
import "../../../../assets/css/Wall/components/Cover/Cover.css";
function Cover(props) {
  const AcceptFileType = ".gif,.jpg,.jpeg,.png";
  const {
    UserId,
    CoverLoading,
    PageInfo,
    CoverImgName,
    subscribePage_,
    Upload_Cover_Image,
    save_Cover_Image,
  } = props;
  const [ChangeImgCover, setChangeImgCover] = useState(false);
  const [selectedImg, setSelectedImg] = useState(
    CoverImgName.length ? CoverImgName : BGup
  );
  const [Subscribers, setSubscribers] = useState(null);
  const [IsSubscribe, setIsSubscribe] = useState(false);
  const [SelectedFile, setSelectedFile] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedImg(CoverImgName);
    setSelectedImg(config.get("URLs.Cover_Thumbnails") + CoverImgName);
    if (ChangeImgCover) {
      dispatch(save_Cover_Image({ CoverImgName }));
      SwToast("success", `❤️ Image  Save Cover Successfully .`);
      setChangeImgCover(false);
    }
    // eslint-disable-next-line
  }, [CoverImgName, dispatch]);

  useEffect(() => {
    if (PageInfo) {
      setSubscribers(PageInfo.SubscribeCount);
      setIsSubscribe(parseInt(PageInfo.IsSubscribe));
      setSelectedImg(
        PageInfo.Cover
          ? config.get("URLs.Cover_Thumbnails") + PageInfo.Cover
          : BGup
      );
    }
  }, [PageInfo]);

  const handelSubscribers = () => {
    dispatch(subscribePage_([IsSubscribe, PageInfo.PageID]));
    setIsSubscribe(!IsSubscribe);
    IsSubscribe
      ? setSubscribers(parseInt(Subscribers) - 1)
      : setSubscribers(parseInt(Subscribers) + 1);
  };

  const handelSave = () => {
    const ImgType = SelectedFile.type.split("/", 2)[1];
    if (AcceptFileType.includes(ImgType)) {
      const formData = new FormData();
      formData.append("File_data", SelectedFile, SelectedFile.name);
      dispatch(Upload_Cover_Image(formData));
    } else {
      SwToast("error", `😡 file type not include in accept `);
    }
  };

  const onFileSelect = (event) => {
    if (event.target.files[0]) {
      const ImgType = event.target.files[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        setChangeImgCover(true);
        setSelectedImg(URL.createObjectURL(event.target.files[0]));
        setSelectedFile(event.target.files[0]);
      } else {
        SwToast("error", `😡 file type not include in accept `);
      }
    }
  };

  const handelExit = () => {
    setChangeImgCover(false);
    setSelectedFile(null);
    setSelectedImg(
      PageInfo.Cover
        ? config.get("URLs.Cover_Thumbnails") + PageInfo.Cover
        : BGup
    );
  };
  return (
    <div
      className="HCover Transition"
      style={{ backgroundImage: `url('${selectedImg}')` }}
    >
      {!CoverLoading && (
        <div className="HCover__Content">
          <div className="counter custom">
            <div style={{ width: "80%" }}>
              <div className="SubscribeInfo">
                <div className="SubscribeCount">
                  <h1>
                    {Subscribers
                      ? String(Subscribers).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                      : ""}
                    <span>{Subscribers >= 1000 ? `K` : ""}</span>
                  </h1>
                </div>
                <div className="pageName">
                  <h2>{PageInfo ? PageInfo.pageTitle : ""}</h2>
                </div>
              </div>
              <div className="subscribe">
                <button
                  className={`sB Transition ${
                    IsSubscribe ? "IsSubscribe" : ""
                  }`}
                  onClick={() => handelSubscribers()}
                >
                  {" "}
                  <i className="fa fa-thumbs-up "></i> Subscribe
                </button>
                <div className={"fllowers"}>
                  <div className="fllower Transition"></div>
                  <div className="fllower Transition"></div>
                  <div className="fllower Transition"></div>
                  <div className="fllower Transition"></div>
                </div>
              </div>
            </div>

            <div className="upload CoverUploaded">
              {
                // eslint-disable-next-line
                UserId == PageInfo.UserId && (
                  <>
                    {ChangeImgCover && (
                      <>
                        <button
                          className={`sB Transition`}
                          onClick={() => handelExit()}
                        >
                          Exit
                        </button>
                        <button
                          className={`sB Transition`}
                          onClick={() => handelSave()}
                        >
                          Save
                        </button>
                      </>
                    )}
                    <div className="btn Transition">
                      <input
                        type="file"
                        onChange={(event) => onFileSelect(event)}
                        title=""
                        accept={AcceptFileType}
                      />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Cover;
