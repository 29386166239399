import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import config from "../../../lib/config";
import "../../../assets/css/PointCashier/Products/Products.css";

function ProductModal(props) {
  const {   ProductModal, PriceInfo } = useSelector((state) => state.product);
  const { SwToast,  SwAlert,    IsSelectOrder,    AddItemToCart,closeModal, setDefaultImg, RemoveImg_, dispatch } = props;
  const [DefaultImage, setDefaultImage] = useState(ProductModal.imgUrl);

  const handelRemoveImg = (e, Item) => {
    e.stopPropagation();
    if (Item.DefaultImage !== 1) {
      dispatch(RemoveImg_([ProductModal.id, Item]));
    } else {
      SwToast("warning", `can't delete Default  image   `);
    }
  };

  const handelDefaultImg = (Item) => {
    dispatch(setDefaultImg([ProductModal.id, Item.imgId]));
    setDefaultImage(Item.photo);
  };
  const handleAddItem = (e => {
    e.stopPropagation();
    IsSelectOrder && IsSelectOrder.Invoice_ID >= 1
      ? dispatch(AddItemToCart(ProductModal))
      : SwAlert("Select order ", "Select order first ! ");
  })

  const ImgItem =
    ProductModal &&
    ProductModal.media.map((Item, index) => (
      <div
        className={`Item Transition   ${
          Item.DefaultImage === 1 ? "checked" : ""
        }`}
        key={index}
        onClick={() => handelDefaultImg(Item)}
        style={{
          backgroundImage: `url('${
            Item ? config.get("URLs.product_ImgThumbnails") + Item.photo : ""
          }')`,
        }}
      >
        <div
          className="RemoveImg Transition"
          onClick={(e) => handelRemoveImg(e, Item)}
        >
          <i className="fa fa-times"></i>
        </div>
      </div>
    ));

  return (
    <Modal
      isOpen={!!ProductModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <span className="CloseModal" onClick={closeModal}>
        &times;
      </span>

      <div className="modal-content productModal">
        <div
          className="productImgModal Transition"
          style={{
            backgroundImage: `url('${
              config.get("URLs.product_ImgThumbnails") +
              `${DefaultImage ? DefaultImage : ProductModal.imgUrl}`
            }')`,
          }}
        >

      <div className="optionModal">
      <div className="optionItem">
              <div className="MultipleIMG_bar Transition">{ImgItem}</div>
        </div>
      <div className="optionAction">
      <div className="buyAction Transition" onClick={(e) =>handleAddItem(e)}>
      <div className="ADtoCrt ">
      <i className="fa  fa-cart-plus"></i>
      </div>
      </div>
        </div>
      </div>




 
        
        </div>
        <div className="descInfo">
          <h2>{ProductModal.title}</h2>
          <div className="pric_sec">
            {PriceInfo && (
              <> السعر <h1 className="price">{ProductModal.price} ج.م </h1>{" "}
              </>
            )}
          </div>
          <div style={{ direction: "rtl" }}>
            الكود <span className="price">#{ProductModal.productCode}</span>
          </div>
          <span>{ProductModal.Description}</span>
        </div>
      </div>
    </Modal>
  );
}

export default ProductModal;
