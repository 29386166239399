import React, { useState , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, switchActive } from "./../../../store/authSlice";
import { ActiveClintModal } from "./../../../store/clintSlice";
import { SyncMyPages } from "../../../store/PageSlice";

import { useNavigate } from "react-router-dom";
import { words } from "../../../words";
import Pages from "./Pages";
import "../../../assets/css/Layout/Header/Header.css";
import { getProducts } from "../../../store/productsSlice";
import config from "../../../lib/config";
import NClintBoxModal from "../../../components/Audience/NClintBoxModal";
export default function Header() {
  const { isLoggedIn, UserInfo, ActivePage } = useSelector(  (state) => state.auth  );
  const { PageList } = useSelector((state) => state.page);

  const [openList, setOpenList] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

   dispatch(SyncMyPages());
  }, [ dispatch]);

  // const location = useLocation();
   const handleLink= (link) => {
    navigate(`/${link}`);
  }
  const Lng=words.lang.ar.LeftSidebar;
  const handleGoHome = (path, i = "", key = "") => {
    // console.log(location.pathname);
    dispatch(getProducts(24));
    i && dispatch(switchActive([i, key]));
    i && setOpenList(!openList);
    navigate("/" + path);
  };
  const closeModal = () => {
    dispatch(ActiveClintModal(false));
  };
  const openModal = () => {
    dispatch(ActiveClintModal(true));
  };
  return (<>
    <header>
      <div className="main-header">
        <div className="logo">          <h1 onClick={() => handleGoHome("home")}>{words.headerTitle} </h1>        </div>

        <div className="userInfo">
          <div className="ActivePage">
            {isLoggedIn && !ActivePage ? (
              <>
                <div
                  className="userIMG"
                  style={{
                    backgroundImage: `url('${
                      config.get("URLs.userIMG") + UserInfo.UserImage
                    }')`,
                  }}
                ></div>
                <div className="info">
                  <span>{UserInfo.userName} </span>
                  <span className="GroupName">{UserInfo.userName} </span>
                </div>
              </>
            ) : (
              <>
                {ActivePage && UserInfo && (
                  <>
                    <div
                      className="userIMG"
                      style={{
                        backgroundImage: `url('${
                          config.get("URLs.Cover_Thumbnails") + ActivePage.Cover
                        }')`,
                      }}
                    ></div>
                    <div className="info">
                      <span onClick={() => handleGoHome(ActivePage.PageUser)}>
                        {ActivePage.pageTitle}
                      </span>
                      <span className="GroupName">{UserInfo.userName} </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>{PageList&& PageList.length > 0 ? (<>
          <div onClick={() => setOpenList(!openList)} style={{ cursor: "pointer" }} >      <i className="fas fa-chevron-down"> </i></div>
          <div className="myPages " id="transparent-scroll" style={{ display: `${openList ? "flex" : "none"}` }}>
            <Pages handleGoHome={handleGoHome} PList={PageList} />

            {PageList&& PageList.length !==3 &&( <><div className="btn-NewPge" onClick={() => handleGoHome("home")} >
              <i className="fas fa-flag">                </i>
              New Page </div></>) } 

          </div>  </>):(
            <>
                        <div className="btn-NewPge" >New Page </div>
</>

          )
          
          }
        </div>
<div className="HeadToolBar">
  
  
<ul>
{/* <li className="TRnsion" title={`${Lng.NotInOrders}`}  onClick={() => handleLink("NotInOrders")} ><i className="fas fa-truck-loading">           </i></li>             */}
<li className="TRnsion" title={`${Lng.cashier}`}  onClick={() => handleLink("cashier")} >      <i className="fas fa-cash-register">             </i></li>          
 <li className="TRnsion" title={`${Lng.Robot}`}  onClick={() => handleLink("questions")} >      <i className="fas fa-brain">                     </i></li>  
<li className="TRnsion" title={`${Lng.debt}`}  onClick={() => openModal()} >      <i className="fas fa-user">                </i></li>            
 </ul>

  
  
  
   </div>
        <div className="sysOptions">
          <div className="singout" onClick={() => dispatch(logout())}>         <i className="fas fa-sign-out-alt">                     </i>          </div>
        </div>
      </div>
    </header>
          <NClintBoxModal   closeModal={closeModal} />



</>
  );
}
