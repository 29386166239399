import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncAudienceLabel,
  removeLabel,
  SelectToEditLabel,
  SelectLabels,
  SetFlowerToLabelAudience,
  SyncAudienceByLabel,
} from "../../../../store/audienceSlice";

function ItemLabel() {
  const { Labels, LabelsSelect, ItemIds, isEditLabel } = useSelector(
    (state) => state.audience
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncAudienceLabel());
  }, [dispatch]);
  const handleEdit = (item) => {
    dispatch(SelectToEditLabel(item));
    dispatch(SyncAudienceByLabel(item.LID));
  };
  const LList = Labels ? (
    Labels.map((item, index) => (
      <div key={index} className="label-list-style Transition">
        <div className="option">
          <div className="AcType">
            <i
              style={{ color: item.labelColor }}
              className="fas fa-circle"
              aria-hidden="true"
            ></i>
          </div>
          <div
            className={`Aud_labels  ${item.checked ? "Checked-true" : ""}`}
            onClick={() => dispatch(SelectLabels(item))}
            rel="uncheck"
            id="9"
          ></div>
          <span onClick={() => dispatch(SyncAudienceByLabel(item.LID))}>
            {item.labelName}
          </span>
        </div>
        <div className="control">
          <div onClick={() => handleEdit(item)}>
            <i className="fa fa-pencil-alt EditPencil" aria-hidden="true"></i>
          </div>
          <div onClick={() => dispatch(removeLabel(item))}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    ))
  ) : (
    <></>
  );
  const handleAddFlow = () => {
    dispatch(SetFlowerToLabelAudience({ LabelsSelect, ItemIds }));
  };
  return (
    <div className="list-label">
      {LList}

      {ItemIds.length >= 1 && LabelsSelect.length >= 1 && !isEditLabel && (
        <button
          type="button"
          onClick={() => handleAddFlow()}
          className="btn-success btnOpenOrder"
          value="1"
        >
          {" "}
          حفظ واضافة الجمهور{" "}
        </button>
      )}
    </div>
  );
}

export default ItemLabel;
