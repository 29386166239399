import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedItem,
  DeleteSelectedItem,
  SyncCampaignItem,
  updateCampaignItem_,
  Share_to_,
  SelectAll,
  Share_Cover_to_,
  CopyAllSelect_,
  PastCopy_,
  Retweet,
  AddToCampaign,
  RemoveItem,
  RetweetItem,
  SortCampaignItem_,
  SyncObjectComping,
  checkedOBject,
  ONUpdate,
} from "../../../store/PublishCampaignSlice";
import { ClearSelectedItem, NewCampaign } from "../../../store/productsSlice";
import "../../../assets/css/PointCashier/PublishCampaign/ItemCampaign.css";
import config from "../../../lib/config";
import { ClearSel } from "../../../store/selectedSlice";
import { SwToast } from "../../../lib/SwAlert";

function ItemCampaign() {
  const {
    isLoading,
    List_Item_Campaign,
    List_Item_Copy,
    ItemIds,
    ItemObjIDs,
    Active_Campaign,
    isEdit,
    update,
    ItemObj,
  } = useSelector((state) => state.publish_campaign);
  const [IsSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncCampaignItem(Active_Campaign.Id));
  }, [Active_Campaign, dispatch]);

  const handleAddToCampaign = () => {
    dispatch(AddToCampaign());
    dispatch(ClearSelectedItem());
    dispatch(ClearSel());
  };
  useEffect(() => {
    if (!isEdit && true === update && Active_Campaign.Id) {
      const st_ = setTimeout(() => {
        //if not editing update and if add set new state
        dispatch(updateCampaignItem_(List_Item_Campaign));
        SwToast("success", `❤️ success update Item Campaign `);
        setIsSelected(false);
      }, 600);
      return () => {
        clearTimeout(st_);
      };
    }
  }, [List_Item_Campaign, dispatch, isEdit, update, Active_Campaign]);

  useEffect(() => {
    dispatch(SyncObjectComping(Active_Campaign.Id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Active_Campaign]);
  const handelDeleteItem = () => {
    if (isEdit) {
      dispatch(DeleteSelectedItem());
    } else {
      SwToast("error", `حدد منتج للحذف  😓 `);
    }
  };

  const handelRetweetItem = () =>
    isEdit
      ? dispatch(RetweetItem())
      : SwToast("error", `حدد منتج لاعادة نشرة 😓 `);

  const handelRetweetOneItem = (e, index) => {
    e.stopPropagation();
    dispatch(Retweet(index));
  };

  const handleShareOneItems = (e, item) => {
    e.stopPropagation();
    let ItemIds = [item.id];
    if (ItemObjIDs.length >= 1) {
      dispatch(Share_to_({ ItemObjIDs, ItemIds }));
    } else {
      SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
    }
  };

  const handleShareCoverOneItems = (e, item) => {
    e.stopPropagation();
    let ItemIds = [item.id];
    if (ItemObjIDs.length >= 1) {
      dispatch(Share_Cover_to_({ ItemObjIDs, ItemIds }));
    } else {
      SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
    }
  };

  const handleShareItems = () => {
    if (ItemIds.length >= 1) {
      if (ItemObjIDs.length >= 1) {
        dispatch(Share_to_({ ItemObjIDs, ItemIds }));
      } else {
        SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
      }
    } else {
      SwToast("error", `حدد منتج للنشر 😓 `);
    }
  };

  const handleShareCoverItems = () => {
    if (ItemIds.length >= 1) {
      if (ItemObjIDs.length >= 1) {
        dispatch(Share_Cover_to_({ ItemObjIDs, ItemIds }));
      } else {
        SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
      }
    } else {
      SwToast("error", `حدد منتج للنشر 😓 `);
    }
  };

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };

  const handelCopy_ = () => {
    if (isEdit) {
      dispatch(CopyAllSelect_());
    } else {
      SwToast("error", `حدد منتج للنسخ 😓 `);
    }
  };

  const handelPast_ = () => {
    dispatch(PastCopy_());
  };
  const handelSortCampaignItem = () => {
    dispatch(SortCampaignItem_());
  };
  const isPublished = () => {
    return List_Item_Campaign.filter((i) => i.Published === true).length;
  };

  /* const isChecked =()=>{
return ItemIds.filter((i)=>i.checked ===true ).length;
} */
  const handelGoBack = () => {
    dispatch(ONUpdate());
    dispatch(NewCampaign());
  };
  // list object comping
  const ItemList =
    ItemObj.length >= 1 &&
    ItemObj.map((Item, index) => (
      <div className="item-obj" key={index} value={Item.Au_Id}>
        <div
          className={`check Transition ${Item.checked ? "checked" : ""}`}
          onClick={() => dispatch(checkedOBject(Item))}
        ></div>
        <div className="title"> {Item.first_name} </div>
        <div className="op">
          <span>
            {" "}
            {
              // eslint-disable-next-line
              Item.Block_Type == 1 ? "قالب كامل" : "غلاف"
            }{" "}
          </span>

          <div
            className={`showPrice ${
              Item.show_Price >= 1 ? "active" : "UnActive"
            }`}
          ></div>
        </div>
      </div>
    ));

  const Item_Campaign =
    List_Item_Campaign.length >= 1 &&
    List_Item_Campaign.map((Item, Index) => (
      <div
        key={Index}
        className={`item  ${Item.Published ? "Published" : ""} Transition ${
          Item.checked ? "selected" : ""
        } Transition`}
        onClick={() => dispatch(SelectedItem(Item))}
      >
        {/* <div className="productImg" style={{ backgroundImage: `url('')`, }} ></div> */}
        <div
          className="productImg"
          style={{
            backgroundImage: `url('${
              config.get("URLs.product_ImgSmThumbnails") + Item.imgUrl
            }')`,
          }}
        ></div>
        <div className="item-description">
          {" "}
          <div className="title">{Item.title}</div>
          <div>
            {" "}
            <span className="span-price">
              {" "}
              <strong> $</strong> {Item.price}{" "}
            </span>{" "}
            <strong>#</strong> <span>{Item.productCode}</span>{" "}
          </div>
        </div>
        <div
          className="shearItem Transition"
          onClick={(e) => handelRetweetOneItem(e, Index)}
        >
          {" "}
          <i className="fa  fa-retweet"></i>{" "}
        </div>
        <div
          className="shearItem Transition"
          onClick={(e) => handleShareOneItems(e, Item)}
        >
          {" "}
          <i className="fa  fa-share"></i>{" "}
        </div>
        <div
          className="shearItem Transition"
          onClick={(e) => handleShareCoverOneItems(e, Item)}
        >
          {" "}
          <i className="fa  fa-share-square"></i>{" "}
        </div>
        <div
          className="clearItem Transition"
          onClick={() => dispatch(RemoveItem(Item))}
        >
          {" "}
          <i className="fa fa-times"></i>{" "}
        </div>
      </div>
    ));

  return (
    <>
      <div className="ItemCampaign" id="transparent-scroll">
        <div className="style16">
          <i className="fas fa-bullhorn" aria-hidden="true"></i>
          <h2> إدارة محتوى الحملة </h2>
          <div className="GoBack" onClick={() => handelGoBack()}>
            <i className="fas fa-arrow-left"></i>{" "}
          </div>
        </div>
        <div className="options-container style16">
          <div
            className="Button-custom edit Transition"
            onClick={() => handleShareCoverItems()}
            title="نشر غلاف المنتج "
          >
            {" "}
            <i className="fa  fa-share-square"></i>{" "}
          </div>
          <div
            className="Button-custom edit Transition"
            onClick={() => handleShareItems()}
            title="نشر المنتج كامل"
          >
            {" "}
            <i className="fa  fa-share"></i>{" "}
          </div>
          <div
            className="Button-custom edit Transition"
            onClick={() => handelRetweetItem()}
            title="Re share select"
          >
            {" "}
            <i className="fa  fa-retweet"></i>{" "}
          </div>
          <div
            className="Button-custom Transition"
            style={List_Item_Copy.length >= 1 ? { color: "#ffc513" } : {}}
            onClick={() => handelPast_()}
            title="Paste Select "
          >
            <i className="fa  fa-paste"></i>
          </div>
          <div
            className="Button-custom Transition"
            onClick={() => handelCopy_()}
            title="Copy Select "
          >
            {" "}
            <i className="fa  fa-clipboard"></i>{" "}
          </div>
          <div
            className="Button-custom Transition"
            onClick={() => handelDeleteItem()}
            title="Delete Selected"
          >
            {" "}
            <i className="fa  fa-trash"></i>{" "}
          </div>
          <div
            className={`Button-custom Transition Check-inOrder ${
              IsSelected ? "Checked-true" : ""
            }`}
            onClick={() => handleSelectAll()}
          >
            {" "}
          </div>
          <div
            className="Button-custom Transition"
            onClick={() => dispatch(SyncCampaignItem(Active_Campaign.Id))}
            title=" Sync Campaign "
          >
            <i className="fas fa-sync-alt"></i>{" "}
          </div>
          <div
            className="Button-custom Transition"
            onClick={() => handelSortCampaignItem()}
            title=" Sync Campaign "
          >
            {" "}
            <i className="fas fa-sort"></i>{" "}
          </div>
          <div
            className="Button-custom Transition"
            onClick={() => handleAddToCampaign()}
            title="Add"
          >
            <i className="fa fa-cart-plus"></i>{" "}
          </div>
        </div>
        <div className="OBjCmp">
          <div className="title">اهداف الحملة الاعلانية </div>
          <i className="fas fa-folder-minus"></i>
        </div>
        <div className="listOBJ">
          {ItemObj.length >= 1 && (
            <>
              <div className="HeadComp">
                <div className="TitleComp"> الهــدف </div>
                <div className="op">
                  <span> القالب </span>
                  <span> السعر </span>
                </div>
              </div>
              {ItemList}
            </>
          )}
        </div>
        <div className="style16 titleCmp">
          {` ${Active_Campaign.Title}   , `} -{" "}
          {` هدف ${Active_Campaign.Objectives} -  عدد منشور: ${List_Item_Campaign.length}`}{" "}
          - تم نشر {isPublished()}{" "}
          <i className="fas fa-folder-minus" style={{ marginLeft: "5px" }}></i>
        </div>{" "}
        <div className="listItem" id="transparent-scroll">
          {isLoading ? <div className="IsLoading"></div> : Item_Campaign}
        </div>
      </div>
    </>
  );
}

export default ItemCampaign;
