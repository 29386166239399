import React from 'react'
import LayoutPages from './LayoutPages'
import AudienceIndex from './../components/Audience/AudienceIndex';
function Audience() {
  return ( 
    <LayoutPages>
     <AudienceIndex/>
    </LayoutPages>
  
    )
}

export default Audience