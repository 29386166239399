import React from "react";
import FormLabel from "./FormLabel";
import ItemLabel from "./ItemLabel";

function Labels() {
  return (
    <div className="labels">
      <div className="title">
        <h2>
          <i className="fas fa-tags" aria-hidden="true"></i>
          التسميات
        </h2>
      </div>
      <hr className="style16" />

      <FormLabel />
        <ItemLabel/>
      <div className="label_desc">
        تستخدم التسميات فى ادارة وتنظيم فئاة الجمهور لتساعد فى الوصول الى
        العملاء المحتملين بسرعة اكبر
      </div>
    </div>
  );
}

export default Labels;
