import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncDebt,
  SyncClint_,
  SaveDebt_,
  ONUpdate,
} from "../../store/debtSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../lib/SwAlert";

export default function NewDebtForm() {
  const {
    ClintList,
    selectedEdit,
    isEdit,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.debt);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncClint_());
  }, [dispatch]);

  const List =
    ClintList.length > 0 ? (
      ClintList.map((Item, index) => (
        <option key={Item.ClientID} value={Item.ClientID}>
          {Item.Client_Name}
        </option>
      ))
    ) : (
      <></>
    );

  const validationSchema = Yup.object({
    ClientID: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    Debt_v: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    Debt_id: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
  });
  const formik = useFormik({
    initialValues: { ClientID: 0, Debt_v: 0, Debt_id: 0 },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit) {
        dispatch(SaveDebt_(JSON.stringify(values, null, 2)));
      } else {
        dispatch(SaveDebt_(JSON.stringify(values, null, 2)));
      }
    },
  });

  useEffect(() => {
    if (isEdit && selectedEdit) {
      formik.setFieldValue("ClientID", selectedEdit.ClientID);
      formik.setFieldValue("Debt_v", selectedEdit.Debt_v);
      formik.setFieldValue("Debt_id", selectedEdit.Debt_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedEdit]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.ClientID) SwToast("warning", `Debt Client  `);
      if (FMKEr.Debt_v) SwToast("warning", `Debt value  ${FMKEr.Debt_v}`);
      if (FMKEr.Debt_id) SwToast("warning", `Marketer   ${FMKEr.Debt_id}`);
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
      dispatch(SyncDebt());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  const resetForm = () => {
    formik.setFieldValue("Debt_id", "");
    formik.setFieldValue("Debt_v", "");
  };

  return (
    <div className="NewClint card full-height">
      <h2>دين جديد</h2>
      <div className="box-body ">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group col-12 col-md-6 col-lg-12">
            <label>العميل</label>
            <select
              name="ClientID"
              type="text"
              onClick={() => resetForm()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.ClientID}
              className={`list TRnsion  ${
                FMKEr.ClientID ? "inputError" : "inputSuccess"
              }`}
            >
              <option value={0}> اختر اسم العميل </option>
              {List}
            </select>
          </div>

          <div className="Rowcc">
            <div className="">
              <label>قيمة الدين : </label>
              <input
                autoComplete="off"
                name="Debt_v"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.Debt_v}
                className={`Gallery_title TRnsion  ${
                  FMKEr.Debt_v ? "inputError" : "inputSuccess"
                }`}
                placeholder="القيمة"
                maxLength="100"
              />
            </div>
          </div>

          <div className="box-footer sButon  ">
            {/* <input type="reset" className="btn-success" value="إلغاء" /> */}
            <input
              className="btn-success"
              type="submit"
              name="SaveClient_"
              value="حفظ / تحديث"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
