import React  from "react";
import "../../../assets/css/PointCashier/PublishCampaign/PublishCampaign.css";
import ListCampaign from "./ListCampaign";
import FormCampaign from "./FormCampaign";

function PublishCampaign() {
  return (
    <>
      <FormCampaign />
      <ListCampaign />
    </>
  );
}

export default PublishCampaign;
