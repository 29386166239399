import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LikePost_ } from "../../../../store/productsSlice";

function Like(props) {
  const { ProductId, LikeCount, IsLike } = props;
  const dispatch = useDispatch();
  const [Likes, setLikes] = useState(LikeCount);
  const [IsLike_, setIsLike] = useState(IsLike);
  const handelLike = () => {
    if (IsLike_ === true) {
      setLikes(parseInt(Likes) - 1);
    } else {
      setLikes(parseInt(Likes) + 1);
    }
    setIsLike(!IsLike_);
    dispatch(LikePost_([IsLike_, ProductId]));
  };
  return (
    <div className="PostActionLikesButton">
      {Likes >= 1 && (
        <span>{String(Likes).replace(/(.)(?=(\d{3})+$)/g, "$1,")}</span>
      )}
      <span
        className={`Like Transition ${IsLike_ ? "islike" : ""}`}
        onClick={() => handelLike()}
      >
        <i className={`fas fa-heart`}></i>
      </span>
      <span> Like </span>
    </div>
  );
}

export default Like;
