import React from 'react'

function SelectSize(props) {
    const {sizes} = props;
    const SIZE_ =sizes.length ? sizes.map((Item, index) => (
        <div          key={index}          className={`S_NUM   Size-checked `}>          {Item}        </div>
      )) :(<>
          <div          key={0}          className={`S_NUM   Size-checked `}>     بدون مقاس  </div>
          <div          key={1}          className={`S_NUM   Size-checked `}>     فري سايز  </div>
          <div          key={2}          className={`S_NUM   Size-checked `}>          مقاس حر        </div>
       </>);
  return (
<>{SIZE_}</>
    )
}

export default SelectSize