import React from 'react'
import LayoutPages from './LayoutPages'
import InvoiceOrdersIndex from '../components/InvoiceOrders/InvoiceOrdersIndex';
 function InvoiceOrders() {
  return ( 
    <LayoutPages>
     <InvoiceOrdersIndex/>
    </LayoutPages>
  
    )
}

export default InvoiceOrders