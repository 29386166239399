import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import user from "../lib/user";
import cache from "../lib/cache";

export const checkUserName_ = createAsyncThunk(
  "auth/checkUserName_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post("auth/field_check_", JSON.stringify({ FieldValue: _ }))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CheckEmail_ = createAsyncThunk(
  "auth/CheckEmail_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post(
          "auth/field_check_",
          JSON.stringify({ FieldName: "Email", FieldValue: _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CheckPageUser_ = createAsyncThunk(
  "auth/CheckPageUser_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post("auth/field_check_", JSON.stringify({ FieldValue: _ }))
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CheckPhoneUser_ = createAsyncThunk(
  "auth/CheckPhoneUser_",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const LabelList_ = endpoint
        .post(
          "auth/field_check_",
          JSON.stringify({ FieldName: "PhoneNumber", FieldValue: _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return LabelList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Signup_ = createAsyncThunk(
  "auth/Signup_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
      const upload_img = endpoint
        .post("auth/signup_", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return upload_img;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const switchActive = createAsyncThunk(
  "auth/switchActive",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("page/set_page_active_",{_:_[0],"UserId":parseInt(getState().auth.UserId)})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// user.isLoggedIn()
const initState = {
  isLoggedIn: user.isLoggedIn(),
  UserInfo: user.isLoggedIn() ? user.userData : [],
  UserId: user.isLoggedIn() ? user.userData.UserId :  cache.get("user"),
   ActivePage: user.isLoggedIn()&& cache.get('ActivePage')?  cache.get('ActivePage') : null,
  UserFound: false,
  EmailFound: false,
  PageFound: false,
  PhoneFound: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  // Page:user.isLoggedIn() ?user.userData.User.Page:false,
};
///auth/Signup_
const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    SyncUserData: (state, action) => {
      state.isLoggedIn = user.isLoggedIn();
      state.UserInfo = user.isLoggedIn()  ? (state.UserInfo = user.userData)     : [];
      state.UserId = user.isLoggedIn() ? user.userData.UserId : null;
      state.ActivePage = user.isLoggedIn()   ? user.userData.DefaultPage   : null;
      cache.set('ActivePage', user.userData.DefaultPage);//save to local storage cart item

    },
    logout: (state) => {
      user.logout();
      cache.remove('ActivePage')
      state.isLoggedIn = false;
      state.UserInfo = null;
      // console.log(state.isLoggedIn);
    },
    switchLogIn: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
    ONUpdate: (state) => {
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },
  },
  extraReducers: {
    [switchActive.pending]: (state, action) => {
      state.isLoading = true;
    },
    [switchActive.fulfilled]: (state, action) => {
      state.ActivePage=action.payload[0];
        cache.set('ActivePage', action.payload[0]);//save to local storage cart item     
    },
    [switchActive.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [Signup_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [Signup_.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
        user.login(action.payload.success._auth);
        // console.log(action.payload.success._auth);
      }
    },
    [Signup_.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [checkUserName_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [checkUserName_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.UserFound = action.payload;
    },
    [checkUserName_.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [CheckEmail_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [CheckEmail_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.EmailFound = action.payload;
    },
    [CheckEmail_.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [CheckPageUser_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [CheckPageUser_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.PageFound = action.payload;
    },
    [CheckPageUser_.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [CheckPhoneUser_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [CheckPhoneUser_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.PhoneFound = action.payload;
    },
    [CheckPhoneUser_.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { SyncUserData, logout, switchLogIn, ONUpdate } =
  authSlice.actions;
export default authSlice.reducer;
