import React from "react";
import {
  SelectToEditChanel,
  DeleteChGroup_,
   
} from "../../store/TelegramSlice";
import Table from "../table/Table";
import "../../assets/css/Audience/Audience.css";
import Swal from "sweetalert2";

const GroupListTable = (props) => {
  const { isLoading,Htitle, BotList,  dispatch } = props;
  const customerTableHead = [
    "##",
    "Bot",
    "Group Name",
    "##",
  ];
 
  const renderHead = (item, index) => <th key={index}>{item}</th>;

  
  const edit = (i) => {
    dispatch(SelectToEditChanel(i));
  };
  const RemoveBots = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف المجموعة !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteChGroup_(Item));
        Swal.fire("Deleted!", "تم حذف المجموعة نهائياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف المجموعة ", "", "info");
      }
    });
  };
 
  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{item.botName}</td>
      <td>{item.first_name}</td>
 
      <td align="center" className="editTool">
        <div onClick={() => edit(item)}>
          <i className="fa  fa-pencil-alt EditPencil"></i>{" "}
        </div> - 
        <div onClick={() => RemoveBots(item)}>
          <i className="fa  fa-trash"></i>{" "}
        </div>
      </td>
    </tr>
  );
  return (
    <div className="card LastClint">
      <div className="headClint">
        <h2 className="page-header"> {Htitle}   </h2>
       </div>

      <div className="card__body">
        {isLoading ? (
          <div className="IsLoading"></div>
        ) : (
          <>
            <Table
              limit="20"
              headData={customerTableHead}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={BotList}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GroupListTable;
