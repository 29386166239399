import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Sync_NotInOrders,
  SelectedItem,SubmitINorderCheck
} from "../../../store/NotInOrdersSlice";
import config from "../../../lib/config";
const Item_ = (props) => {
  const { Zoom } = props;
  const { PriceInfo } = useSelector((state) => state.product);
  const { ItemProduct, isLoading } = useSelector((state) => state.NotInOrders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Sync_NotInOrders());
  }, [dispatch]);

  const handleSelected = (Item) => {
    dispatch(SelectedItem(Item));
  };

  const handelChecked = (e,Item) => {
    e.stopPropagation();
    dispatch(SubmitINorderCheck(Item));
  };



  const List =
    ItemProduct.length >= 1 ? (
      ItemProduct.map((Item, index) => (
        <div
          key={Item.id}
          onClick={() => handleSelected(Item)}
          className={`product Transition ${Item.INorder ? "Selected" : ""} ${
            Item.checked ? "item-checked" : ""
          } ${Item.Active === "1" ? "Hide" : ""}${Zoom ? " Zoom" : ""}`}
          style={{
            backgroundImage: `url('${
              config.get("URLs.product_ImgThumbnails") + Item.imgUrl
            }')`,
          }}
        >
          <div className="product-info">
            <div className="QUnV_"> {Item.T_} </div>
            <div className="_code_ Transition">
              <span>
                #{Item.Product_Code} {PriceInfo && <p><strong>{Item.price}</strong></p>}
              </span>
            </div>
            <div onClick={(e) => handelChecked(e,{Product_ID:Item.Product_ID,INorder:Item.INorder})} className={`checkAction Transition ${
            Item.INorder ? "checked" : ""
          }`}></div>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "600px", backgroundSize: "50%" }}
      ></div>
    );
  return (
    <>
      <div className="products-wrapper">
        {isLoading ? <div className="IsLoading"></div> : <>{List}</>}{" "}
      </div>
    </>
  );
};

export default Item_;
