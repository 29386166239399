import React  from "react";
import {  SelectToEditClint,  DeleteDebt_ } from "../../store/debtSlice";
import Table from "../table/Table";
import "../../assets/css/Audience/Audience.css";
import Swal from "sweetalert2";

const DebtListTable = (props) => {
  const {isLoading, DebtList,dispatch} = props;
  const customerTableHead = [  "#",  "اسم العميل	",  "الدين الحالى","تنزيل -رفع","سابق",  "هاتف",  "مُنذ يوم ",  "اخر تعديل",  "###",];
  const renderHead = (item, index) => <th key={index}>{item}</th>;
  const edit = (i) => {    dispatch(SelectToEditClint(i));  };
  const RemoveDEbt = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف الدين !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteDebt_(Item));
        Swal.fire("Deleted!", "تم حذف الدين نهائياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف الدين", "", "info");
      }
    });
  };

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{item.Client_Name}</td>
      <td>{item.Debt_v}</td>
      <td>{item.LastDebt_value>=1?item.LastDebt_value-item.Debt_v:""}</td>
      <td>{item.LastDebt_value}</td>
      <td>{item.Phone}</td>
      <td>{item.NumberOfDays}</td>
      <td>{item.Debt_date}</td>
      <td align="center" className="editTool">
        <div onClick={() => edit(item)}>
          <i className="fa  fa-pencil-alt EditPencil"></i>{" "}
        </div> - 
        <div onClick={() => RemoveDEbt(item)}>
          <i className="fa  fa-trash"></i> 
        </div>
      </td>
    </tr>
  );
  return (
 <div className="card LastClint">
        <div className="headClint">
          <h2 className="page-header"> قائـمة ديــون بالخارج </h2>
         
        </div>

        <div className="card__body">
          {isLoading ? (
            <div className="IsLoading"></div>
          ) : (
            <>
              <Table
                limit="20"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={DebtList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </>
          )}
        </div>
      </div>
  );
};

export default DebtListTable;
