import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import endpoint, { setHttpConfigurations } from "@mongez/http";
import config from "../../lib/config";
import user from "../../lib/user";
import { SyncUserData } from "../../store/authSlice";
import Is from "@flk/supportive-is";
import "./../../assets/css/Auth/AuthLogin.css";

function AuthLogin() {
  const [userName, setUserName] = useState("");
  const [ErrorUserName, setErrorUserName] = useState("");
  const [UserPassword, setUserPassword] = useState("");
  const [ErrorUserPassword, setErrorUserPassword] = useState("");
  const [errorhandler, setErrorhandler] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn === true) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, isLoggedIn]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!errorhandler) {
      const formElement = event.target;
      const formData = new FormData(formElement);
      setHttpConfigurations({
        baseUrl: config.get("endpoint.baseUrl"),
        setAuthorizationHeader: () => `key ${config.get("endpoint.apiKey")}`
      });
      endpoint
        .post("auth/login", formData)
        .then((result) => {
          if (result.data.UserId) {
            user.login(result.data);
             dispatch(SyncUserData());
              navigate("/home");
          }
        })
        .catch((error) => {
          // some error in the response
        });
    }
  };

  useEffect(() => {
    const str_ = setTimeout(() => {
      setErrorUserName(null);
      setErrorUserPassword(null);
      setErrorhandler(false);
      if (Is.empty(userName)) {
        setErrorUserName("User name is Empty ! ");
        setErrorhandler(true);
      }
      if (Is.empty(UserPassword)) {
        setErrorUserPassword("Password is Empty ! ");
        setErrorhandler(true);
      }
    }, 100);
    return () => {
      clearTimeout(str_);
    };
  }, [userName, UserPassword]);

  useEffect(() => {
    const str_ = setInterval(() => {
      if (!Is.empty(userName) || !Is.empty(UserPassword)) {
        setErrorhandler(false);
      }
    }, 100);
    return () => {
      clearTimeout(str_);
    };
  }, [UserPassword, userName]);

  const goSignup = () => navigate("/signup");

  return (
    <div className="main">
      <section className={"sign-in"}>
        <div className="container">
          <div className="login-content">
            <div className="login-image">
               
                
            </div>

            <div className="login-form">
              <h2 className="form-title">تسجيل الدخول</h2>

              <form onSubmit={(e) => handleSubmit(e)} className="register-form">
                <div className="form-group">
                  <label htmlFor="Username">
                    <i className="fas fa-user"></i>
                  </label>
                  <input
                    type="text"
                    name="Username"
                    id="Username"
                    placeholder="User Name & Email ..."
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                  <div className="input-message">
                    {errorhandler && ErrorUserName !== null && (
                      <span>{ErrorUserName}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Password"><i className="fas fa-lock"></i></label>
                  <input
                    type="password"
                    name="password"
                    id="Password"
                    placeholder="Password"
                    onChange={(e) => setUserPassword(e.target.value)}
                    value={UserPassword}
                  />
                  <div className="input-message">
                    <span>
                    {errorhandler && ErrorUserPassword !== null && (
                    <span>{ErrorUserPassword}</span>
                    )}
                    </span>
                  </div>
                </div>

                <div className="row"> <input
                    type="submit"
                    name="login"
                    id="login"
                    className="form-submit"
                    value="دخول "
                  />
                  <input
                    onClick={() => goSignup()}
                    type="button"
                    name="Signup"
                    id="Signup"
                    className="form-submit btn2"
                    value="إنشاء حساب جديد "
                  />
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuthLogin;
