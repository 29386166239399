import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import LeftSidebar from "../components/Layout/LeftSidebar/LeftSidebar";

const LayoutPages = (props) => {
   const { isLoggedIn } = useSelector((state) => state.auth);
   const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
    return (
    <>
      <div className="Layout">
      {props.HeaderActive ?  <></> :<Header />}
      {props.LeftSidebarHide ?  <></> :<LeftSidebar />}
      {props.MainBodyHide ? <> {props.children}</>  : <main>{props.children}</main>}  
      {props.FooterHide ?  <></> :<Footer />}
       </div>
    </>
  );
};

export default LayoutPages;
