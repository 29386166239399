import React from "react";
import config from "../../../lib/config";
import "../../../assets/css/analytics/components/LastProducts.css";
function LastProducts(props) {
  let { ItemProduct, isLoading } = props;
  const List =
    ItemProduct.length >= 1 &&
    ItemProduct.slice(0, 18).map((Item, index) => (
      <div className="Item" key={Item._id}>
        <div className="product_m">
          <div
            className="_P_"
            style={{
              backgroundImage: `url('${
                config.get("URLs.product_ImgSmThumbnails") + Item.imgUrl
              }')`,
            }}
          >
            <div className="_code_">
              <span>#{Item.productCode}</span>
            </div>
          </div>
        </div>
      </div>
    ));
  return (
    <div className="container_last_products">
      {isLoading ? <div className="IsLoading"  ></div> : <>{List}</>}
    </div>
  );
}

export default LastProducts;
