import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../store/CategoriesSlice";
import { categoryProducts } from "../../../store/productsSlice";
function Categories() {
  const { categories } = useSelector((state) => state.category);
  const [IsCatSelect, setIsCatSelect] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories("active"));
  }, [dispatch]);
  const List = categories ? (
    categories.map((Item, index) => (
      <option key={Item._id} value={Item.Categories_ID}>
        {Item.CatName}
      </option>
    ))
  ) : (
    <></>
  );

  const SynCat = (Cat) => {
    setIsCatSelect(Cat);
    dispatch(categoryProducts(Cat));
  };

  const SynSelectCat = () => {
    dispatch(categoryProducts(IsCatSelect));
  };
  return (
    <>
      <div
        className="AtoCalc Transition BTN_"
        title="مزامنة الفئة "
        onClick={(e) => SynSelectCat()}
      >
        <i className="fa fa-sync "></i>
      </div>
      <select
        name="Categories"
        className="round"
        title=" الفئة "
        defaultValue={0}
        onChange={(e) => SynCat(e.target.value)}
      >
        <option value="0"> حدد الفئة </option>
        {List}
      </select>
    </>
  );
}
export default Categories;
