import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import { push_catalog_ } from "./OrderSlice";

const lodash = require("lodash");

export const SyncOrder_Item = createAsyncThunk(
  "catalog/SyncOrder_Item",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const OrderDetailers_ = endpoint
        .get(`orders/OrderDetailes_/${_}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error --- > ${error} ----`);
        });
      return OrderDetailers_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrderItem_ = createAsyncThunk(
  "catalog/updateOrderItem_",
  async (post, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const invoiceId = getState().catalog.invoiceId;
    const result = post.map((e) => {
      const { title, Product_Code, imgUrl, description, sizes, ...Res } = e; //remove this key from list items
      return Res;
    });

    try {
      const NewOrder = endpoint
        .post(
          "orders/update_cart_",
          JSON.stringify({ invoiceId: invoiceId, Items: result })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {});
      return NewOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Paste = createAsyncThunk(
  "clipboard/Paste",
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;
    return getState().clipboard.Clip;
  }
);

export const SyncInvoice_Item = createAsyncThunk(
  "catalog/SyncInvoice_Item",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const OrderDetailers_ = endpoint
        .get(`/invoices/inv_info_/${_}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {});
      return OrderDetailers_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SubmitEditItems = createAsyncThunk(
  "cart/SubmitEditItems",
  async (Items, thunkAPI) => {
    const { getState } = thunkAPI;
    //get Quantity value to replace last Quantity
    return getState().Quantity.QuantityVal;
  }
);

export const SubmitEditAddToItems = createAsyncThunk(
  "cart/SubmitEditAddToItems",
  async (Items, thunkAPI) => {
    const { getState } = thunkAPI;
    //get Quantity value and add to last Quantity +
    return getState().Quantity.QuantityVal;
  }
);

const initState = {
  invoiceId: null,
  PriceInfo: true,
  isLoading: false,
  isEdit: false,
  update_catalog: false,
  FilterBy: "ALL",
  OrderBy: "latest",
  ProductCatalog: [],
  CloneProductCatalog: [],
  inv_info: [],
  ItemIds: [],
  isSelect: 0,
  total: 0,
  TotalSelect: 0,
  error: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
};

const CatalogSlice = createSlice({
  name: "catalog",
  initialState: initState,
  reducers: {
    FindItemByCode: (state, action) => {
      state.isLoading = true;
      state.ProductCatalog = state.CloneProductCatalog;
      const Res = state.ProductCatalog.filter((result) => {
        return result.Product_Code.includes(action.payload);
      });
      Res.length !== 0
        ? (state.ProductCatalog = Res)
        : (state.ProductCatalog = state.CloneProductCatalog);
      state.isLoading = false;
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.ProductCatalog.map((Item) => (Item.checked = !action.payload));
      let isSelect = state.ProductCatalog.filter((o) => o.checked === true);
      state.isSelect = isSelect.length;
      isSelect ? (state.isEdit = true) : (state.isEdit = false);
      state.ItemIds = [];
      isSelect.forEach((element) => {
        state.ItemIds.push(element._id);
      });
    },
    SetInvoiceId: (state, action) => {
      state.invoiceId = action.payload;
    },
    CountTotal: (state) => {
      state.total = lodash.sum(
        state.ProductCatalog.map((item) => parseInt(item.Quantity))
      );
      let isSelect = state.ProductCatalog.filter((o) => o.checked === true);
      state.TotalSelect = lodash.sum(
        isSelect.map((item) => parseInt(item.Quantity) * item.price)
      )
    },
    FilterOrder: (state, action) => {
      state.isLoading = false;
      state.OrderBy = action.payload;

      let orderFilter = state.ProductCatalog.sort(function (a, b) {
        if (action.payload === "lowest") {
          return a.price - b.price;
        } else if (action.payload === "highest") {
          return b.price - a.price;
        } else {
          return a.id < b.id ? 1 : -1;
        }
      });
      state.ProductCatalog = orderFilter;
    },
    switchPrice: (state) => {
      state.PriceInfo = !state.PriceInfo;
    },
    ClearCart: (state) => {
      //remove the item from the cart list
      state.ProductCatalog = [];
    },
    RemoveItem: (state, action) => {
      //remove the item from the cart list
      state.ProductCatalog = state.ProductCatalog.filter(
        (el) => el.id !== action.payload.id
      );
      state.update_catalog = true;
    },
    FilterCartItem: (state) => {
      //filter items in order by INorder true
      state.ProductCatalog.sort(function (x, y) {
        return x.INorder === y.INorder ? 0 : x ? -1 : 1;
      });
    },
    DeleteSelectedItem: (state) => {
      //delete multiple items from cart list
      let obj = state.ProductCatalog.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        state.ProductCatalog = state.ProductCatalog.filter(
          (el) => el.id !== Item.id
        );
        return null;
      });
      state.isEdit = false;
      state.update_catalog = true;
    },

    SelectedItem: (state, action) => {
      //selected item in cart list
      state.ItemIds = [];
      let obj = state.ProductCatalog.find((o) => o.id === action.payload.id);
      if (obj) {
        obj.checked = !obj.checked; /* state.isEdit = !state.isEdit; */
      }
      let isSelect = state.ProductCatalog.filter((o) => o.checked === true);
      state.isSelect = isSelect.length;
      isSelect ? (state.isEdit = true) : (state.isEdit = false);

      isSelect.forEach((element) => {
        state.ItemIds.push(element._id);
      });
    },
    SubmitINorder: (state) => {
      //check multiple selected item INorder true
      let obj = state.ProductCatalog.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        Item.INorder = !Item.INorder;
        return null;
      });
      state.isEdit = false;
      state.update_catalog = true;
      state.isSelect = 0;
    },
    unChecked: (state, action) => {
      //remove the item from the cart list
      let obj = state.ProductCatalog.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        return null;
      });
    },
    ONUpdate: (state) => {
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },
  },
  extraReducers: {
    [push_catalog_.pending]: (state, action) => {},
    [push_catalog_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [push_catalog_.rejected]: (state, action) => {},
    [SyncInvoice_Item.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncInvoice_Item.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.inv_info = action.payload;
    },
    [SyncInvoice_Item.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Paste.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [Paste.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      action.payload.map((Item) => {
        let FindIn = state.ProductCatalog.find((o) => o.id === Item.id);
        if (FindIn) FindIn.checked = false;
        if (!FindIn) {
          state.ProductCatalog.push({ ...Item, checked: false });
          state.update_catalog = true;
          state.isEdit = false;
          state.isSelect = 0;
        }
        return null;
      });
    },
    [Paste.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateOrderItem_.pending]: (state) => {
      state.error = null;
    },
    [updateOrderItem_.fulfilled]: (state) => {
      state.error = null;
      state.update_catalog = false;
      state.isEdit = false;
    },
    [updateOrderItem_.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncOrder_Item.pending]: (state, action) => {
      state.error = null;
    },
    [SyncOrder_Item.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ProductCatalog = null;
      state.error = null;
      state.ProductCatalog = action.payload;
      state.CloneProductCatalog = action.payload;
      state.update_catalog = false;
    },
    [SyncOrder_Item.rejected]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [SubmitEditItems.fulfilled]: (state, action) => {
      let obj = state.ProductCatalog.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        Item.Quantity = action.payload;
        return null;
      });
      state.isEdit = false;
      state.update_catalog = true;
      state.isSelect = 0;
    },
    [SubmitEditAddToItems.fulfilled]: (state, action) => {
      let obj = state.ProductCatalog.filter((o) => o.checked === true);
      obj.map((Item) => {
        Item.checked = false;
        Item.Quantity += action.payload;
        return null;
      });
      state.isEdit = false;
      state.update_catalog = true;
      state.isSelect = 0;
    },
  },
});
export const {
  SetInvoiceId,FindItemByCode,
  unChecked,
  CountTotal,
  FilterCartItem,
  SelectedItem,
  FilterOrder,
  switchPrice,
  RemoveItem,
  SelectAll,
  DeleteSelectedItem,
  SubmitINorder,
  ONUpdate
} = CatalogSlice.actions;
export default CatalogSlice.reducer;
