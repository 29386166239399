import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import "./../assets/css/NotFound.css";
function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 4000);
  });
  return (
    <div className="NotFound-main">
      <section className={"sign-in"}>
        <div className="container">
          <img src="/images/Accesdenied.jpg" alt="" />
          <h1> عفواً ! ﻻ تمتلك صلاحية الدخول </h1>
          عفواً ! ﻻ تمتلك صلاحية الدخول ليس مصرح لك بالدخول الى تلك الصفحة راجع
          اﻹدارة
          <p>
            اذا كنت تعتقد انه تم الغاء صلاحيتك بالدخول يمكنك العودة إلى الخلف أو
            محاولة استخدام نموذج البحث
          </p>
        </div>
      </section>
    </div>
  );
}

export default NotFound;
