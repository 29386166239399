import React, { useEffect } from "react";
import CashierIndex from "../components/PointCashier/CashierIndex";
import LayoutPages from "./LayoutPages";
function Cashier() {
  useEffect(() => {
    setTimeout(() => {
      document.title = `إدارة الفواتير`;
    }, 100);
  });

  return (
    <LayoutPages LeftSidebarActive={false} HeaderActive={false}>
      <CashierIndex />
    </LayoutPages>
  );
}

export default Cashier;
