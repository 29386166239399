import React, { useEffect } from "react";
import ASQuestionsIndex from "../components/ASQuestions/ASQuestionsIndex";
import LayoutPages from "./LayoutPages";
import { words } from "../words";
const QLang=words.lang.ar.Questions;
function ASQuestions() {
  useEffect(() => {
    setTimeout(() => {
      document.title = `${QLang.title}`;
    }, 100);
  });

  return (
    <LayoutPages>
      <ASQuestionsIndex />
    </LayoutPages>
  );
}

export default ASQuestions;